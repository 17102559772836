<template>
  <div class="title-banner">
    <h2>ADICIONAR AGENTE</h2>
  </div>

  <main class="add-agente-grid">
    <div class="item grid-span-3">
      <label>Selecione um dos restantes agentes do template:</label>
      <select :disabled="isCustomAgente" ref="agenteDpdownRef">
        <option selected disabled hidden v-if="!agentes">
          {{ dpdwnLoadingString }}
        </option>
        <option v-for="{ id, title } in agentes" :key="id" v-else>
          {{ title }}
        </option>
        <option v-if="!agentes?.length">
          Não tem nenhum agente do template por adicionar
        </option>
      </select>
    </div>

    <div class="form-check item-row grid-span-3">
      <input
        class="form-check-input pointer"
        type="checkbox"
        id="flexCheckDefault"
        ref="flexCheckDefaultRef"
        @click="toggleisCustomAgente"
      />
      <div class="item">
        <label
          class="form-check-label pointer"
          for="flexCheckDefault"
          style="color: var(--main-color)"
        >
          Agente Personalizado:
        </label>
        <p>
          Utilize esta opção para adicionar um agente caso não esteja presente
          na listagem em cima
        </p>
      </div>
    </div>

    <input
      type="text"
      @change="customAgente = $event.target.value.trim()"
      class="grid-span-3"
      :class="isCustomAgente ? 'show' : 'hidden'"
      placeholder="Adicione um título para o seu agente personalizado"
    />

    <div class="grid-span-3 flex-row">
      <div
        class="my-btn-primary"
        :class="[formEdited ? 'my-btn-primary' : 'my-btn-hidden']"
        @click="handleClick"
      >
        <h5 class="prim-btn grid-span-2">Adicionar</h5>
      </div>
      <div class="my-btn-cancel" @click="closeModal">
        <h5 class="cancel-btn grid-span-1">Cancelar</h5>
      </div>
    </div>
  </main>
</template>

<script>
import {
  getAgentes,
  postAgente,
  postCustomAgent
} from '../../../../services/lib/agentes'
import { setNotification } from '../../../../services/filters/dreamCatcher'
import { nextTick } from 'vue'
export default {
  emits: ['closeModal'],
  data() {
    return {
      agentes: null,
      isCustomAgente: false,
      customAgente: '',
      formEdited: true,
      //MISC
      dpdwnLoadingString: 'A Processar...',
      //REFS
      agenteDpdownRef: 'agenteDpdownRef',
      flexCheckDefaultRef: 'flexCheckDefaultRef'
    }
  },
  async created() {
    const formData = {
      project_id: this.$store.state.project.id,
      stage_template_id: parseInt(this.$store.state.stageIndex) + 1
    }
    const { data, status } = await getAgentes(formData)

    //console.log('AGENTES', data)
    if (status !== 200) return

    this.agentes = data

    if (!this.agentes.length) {
      //click on the checkbox with the ref flexCheckDefaultRef
      await this.$nextTick()
      this.$refs[this.flexCheckDefaultRef].click()
      this.$refs[this.flexCheckDefaultRef].disabled = true
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    toggleisCustomAgente() {
      this.isCustomAgente = !this.isCustomAgente
    },
    async handleClick() {
      if (!this.isCustomAgente) {
        const { selectedIndex, value } = this.$refs[this.agenteDpdownRef]
        if (value === this.dpdwnLoadingString) return

        const newAgente = this.agentes[selectedIndex].id

        //console.log('NEW AGENTE: ', newAgente)
        const data = {
          project_id: this.$store.state.project.id,
          job_id: newAgente,
          stage_template_id: parseInt(this.$store.state.stageIndex) + 1
        }
        await this.addAgente(data)
      } else {
        if (!this.customAgente.length) return

        const data = {
          project_id: this.$store.state.project.id,
          title: this.customAgente,
          stage_template_id: parseInt(this.$store.state.stageIndex) + 1
        }

        await this.addCustomAgent(data)
      }
    },
    async addAgente(formData) {
      const { data, status } = await postAgente(formData)

      if (status !== 200) {
        setNotification('Ocorreu um erro...', 'error')
        return
      }
      //console.log('RES', data)
      this.$emit('closeModal', data)
      setNotification('Adicionado com sucesso!', 'success')
    },
    async addCustomAgent(formData) {
      const { data, status } = await postCustomAgent(formData)

      if (status !== 200) {
        setNotification('Ocorreu um erro...', 'error')
        return
      }
      //console.log('RES', data)
      this.$emit('closeModal', data)
      setNotification('Adicionado com sucesso!', 'success')
    }
  }
}
</script>

<style scoped>
.add-agente-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding-top: 0.5rem;
}
.grid-span-3 {
  grid-column: span 3;
  margin-inline: 1rem;
}
.grid-span-2 {
  grid-column: span 2;
  text-align: center;
  justify-content: end;
}
.grid-span-1 {
  grid-column: span 1;
  text-align: center;
}
.item {
  display: flex;
  flex-direction: column;
}
.item-row {
  display: flex;
  gap: 0.5rem;
}
.flex-row {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
select,
input {
  border: 1px solid lightgrey;
  border-radius: 0.25rem;
  outline: none;
}
input[type='text'] {
  padding: 0.5rem;
}
label {
  margin-bottom: 0.25rem;
}
p {
  padding: 0;
  margin: 0;
  line-height: 100%;
}

/*EFFECTS*/
.hidden {
  display: none;
}
.show {
  display: block;
}
select:disabled {
  background-color: var(--bg-color);
  cursor: default;
}
</style>
