<template>
  <section class="title-banner">
    <h2>ATUALIZAR AGENTE</h2>
  </section>

  <main class="add-task-grid">
    <section class="modal-content">
      <div class="flex-column">
        <p>
          O agente
          <span style="font-weight: 600">
            {{ item.job.title }}
          </span>
          irá ser atualizado
        </p>
      </div>

      <div class="flex-column">
        <label>Selecione um Utilizador:</label>
        <select @change="setUser" ref="userDpDownRef">
          <option selected disabled hidden v-if="!users">A Processar...</option>
          <option selected disabled hidden v-else>{{ getUser }}</option>
          <option v-for="{ name, id } in users" :key="id">{{ name }}</option>
        </select>
      </div>
    </section>

    <section class="grid-span-6">
      <div class="btns-wrapper">
        <div
          :class="[updatedAgentID ? 'my-btn-primary' : 'my-btn-hidden']"
          @click="handleClick"
        >
          <h5 class="prim-btn grid-span-5">Guardar</h5>
        </div>
        <div class="my-btn-cancel" @click="closeModal">
          <h5 class="cancel-btn grid-span-1">Cancelar</h5>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { setNotification } from '../../../../services/filters/dreamCatcher'
import { showUsers, updateAgent } from '../../../../services/lib/agentes'
export default {
  emits: ['closeModal'],
  props: ['item'],
  data() {
    return {
      users: null,
      updatedAgentID: null,
      formEdited: false,
      agentes: null,
      //MISC
      dpdwnLoadingString: 'A Processar...',
      userDpDownRef: 'userDpDownRef'
    }
  },
  async created() {
    const { data, status } = await showUsers()

    if (status !== 200) {
      setNotification('Ocorreu um erro...', 'error')
      return
    }

    this.users = data
    //console.log('DPDOWN USERS: ', this.users)
  },
  methods: {
    async handleClick() {
      try {
        const formData = {
          id: this.item.id,
          user_id: this.updatedAgentID,
          job_id: this.item.job_id,
          project_id: this.item.project_id,
          stage_template_id: this.item.stage_template_id
        }
        //console.log('FORM DATA: ', formData)
        const { data, status } = await updateAgent(formData)

        if (status !== 200) {
          setNotification('Ocorreu um erro...', 'error')
          return
        }

        this.$store.dispatch('updateAgent', data[0])
        setNotification('Agente atualizado com sucesso!', 'success')
        this.$emit('closeModal')
      } catch (error) {
        setNotification('Ocorreu um erro...', 'error')
        //console.log("AAAAAAAAAAAa")
        //console.log(error)
      }
    },
    closeModal() {
      this.$emit('closeModal')
    },
    setUser() {
      const { value } = this.$refs[this.userDpDownRef]
      this.updatedAgentID = this.users.find((user) => user.name === value).id
    }
  },
  computed: {
    getUser() {
      return this.item.user ? this.item.user.name : 'Escolha o responsável'
    }
  }
}
</script>

<style scoped>
.add-task-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  padding: 0.5rem;
}

.grid-span-6 {
  grid-column: span 6;
  margin-inline: 1rem;
}

.grid-span-5 {
  margin-inline: 1rem;
  grid-column: span 5;
}

.grid-span-1 {
  margin-inline: 1rem;
  grid-column: span 1;
  text-align: center;
}

select,
option,
input,
textarea {
  border: 1px solid lightgrey;
  border-radius: 0.25rem;
  outline: none;
}

select,
option,
input,
textarea {
  padding: 0.5rem;
}

textarea {
  height: 100px;
  resize: none;
}

label {
  margin-bottom: 0.25rem;
}

p {
  padding: 0;
  margin: 0;
  line-height: 100%;
}

select {
  cursor: pointer;
}

.flex-column {
  display: flex;
  flex-flow: column;
}

.modal-content {
  grid-column: span 6;
  gap: 1rem;
}

.btns-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
</style>
