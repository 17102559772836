import apiClient from '../../apiClient'
import store from '../../../store/store'

export async function getStageDetails(id) {
  try {
    const rawres = await apiClient.get(`/stage_details/${id}`)
    const res = rawres.data
    // //console.log(rawres)
    return res
  } catch (error) {
    //console.log(error)
    return res
  }
}

export async function updateStage(project_id, newStage_id) {
  try {
    const data = {
      stage_id: newStage_id
    }
    const endpoint = `/update_current_stage_id/${project_id}`
    const res = await apiClient.post(endpoint, data)

    return res
  } catch (error) {
    //console.log(error)
  }
}
export async function updateStageDetails(data) {
  try {
    const { id: project_id } = store.state.project
    ////console.log('DATA ON UPDATESTAGE DETAILS: ', data)

    const endpoint = `/update_stage_details/${project_id}`
    const { status } = await apiClient.post(endpoint, data)

    //console.log('STATUS FROM STAGE DETAILS: ', status)
    return { status }
  } catch (error) {
    return { status: '405' }
  }
}
export async function importStageDetails(data, pid) {
  try {
    const endpoint = `/update_stage_details/${pid}`
    const { status } = await apiClient.post(endpoint, data)

    //console.log('STATUS FROM STAGE DETAILS: ', status)
    return { status }
  } catch (error) {
    return { status: '405' }
  }
}
