<template>
  <div class="title-banner">
    <h2>EDITAR TAREFA</h2>
  </div>

  <main class="add-task-grid">
    <div class="grid-span-6 item">
      <label>Nome da Tarefa:</label>
      <input
        type="text"
        v-model="updatedTask.task.name"
        placeholder="Nome da tarefa a atribuir..."
      />
    </div>

    <div class="grid-span-3 item">
      <label>Responsabilidade:</label>
      <select
        :disabled="
          !(
            canAssociateUserToTask ||
            (canAssociateUserToCreatedTask &&
              getTaskCreatorId == getCurrentUserId)
          )
        "
        @change="setJob"
        ref="agentesDpdownRef"
      >
        <option selected disabled hidden v-if="!agentes">
          {{ dpdwnLoadingString }}
        </option>
        <option selected disabled hidden v-else>
          {{ getAgent }} {{ getRelatedUser(updatedTask.task_user) }}
        </option>
        <option v-for="{ id, job, user } in agentes" :key="id">
          {{ getJobTitle(job) }} {{ getRelatedUser(user) }}
        </option>
      </select>
    </div>

    <section class="grid-span-3 item">
      <label>Estado:</label>
      <select
        :disabled="
          !(
            canEditCreatedOrInvitedAssociatedTaskState ||
            canEditTaskState ||
            canEditAssociatedTaskState
          )
        "
        ref="stateDpdownRef"
        @change="setState"
      >
        <option selected disabled hidden>
          {{ updatedTask.state.state }}
        </option>
        <option class="custom-select-option">Em Execução</option>
        <option class="custom-select-option">Para Verificação</option>
        <option
          :disabled="canEditAssociatedTaskState"
          class="custom-select-option"
        >
          Concluído
        </option>
      </select>
    </section>

    <section class="grid-span-3 item">
      <label style="margin-top: 0">Data de Entrega:</label>
      <input
        :disabled="
          !(
            canEditCreatedOrInvitedAssociatedTaskDelivDate ||
            canEditTaskDelivDate
          )
        "
        type="date"
        class="date"
        v-model="updatedTask.task_deliv_date"
      />
    </section>

    <div class="grid-span-6 item">
      <label>Requisitos de Informação:</label>
      <textarea
        type="text"
        placeholder="Ex: Propostas de Projeto"
        maxlength="255"
        v-model="updatedTask.task_requirement"
      />
    </div>

    <div
      v-if="
        canAddTaskNote ||
        (canAddAssociatedAndInvitedAssociatedTaskNote &&
          (invitedByCurrentUser || getTaskUserId == getCurrentUserId)) ||
        (canAddAssociatedTaskNote && getTaskUserId == getCurrentUserId)
      "
      class="grid-span-6 item"
    >
      <label>Notas:</label>
      <textarea
        type="text"
        placeholder="Deixe uma nota..."
        maxlength="255"
        v-model="updatedTask.task_notes"
      />
    </div>

    <section class="grid-span-6">
      <div class="flex-row">
        <div
          :class="[formEdited ? 'my-btn-primary' : 'my-btn-hidden']"
          @click="handleClick"
        >
          <h5 class="prim-btn grid-span-5">Guardar</h5>
        </div>
        <div class="my-btn-cancel" @click="closeModal">
          <h5 class="cancel-btn grid-span-1">Cancelar</h5>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { getAgentsByStage } from '../../../../services/lib/agentes'
import { updateTask } from '../../../../services/lib/tasks'
import { setNotification } from '../../../../services/filters/dreamCatcher'
import { mapGetters } from 'vuex'
import { hasPermission } from '../../../../services/lib/rolesHelper'

export default {
  emits: ['closeModal'],
  props: ['item'],
  data() {
    return {
      updatedTask: null,
      formEdited: false,
      agentes: null,
      //MISC
      dpdwnLoadingString: 'A Processar...',
      noUserMsg: 'Sem utilizador associado',
      //REFS
      agentesDpdownRef: 'agentesDpdownRef',
      usersDpdownRef: 'usersDpdownRef',
      stateDpdownRef: 'stateDpdownRef'
    }
  },
  async created() {
    this.updatedTask = JSON.parse(JSON.stringify(this.item))
    //console.log('TASK TO UPDATE: ', this.updatedTask)

    try {
      const { data } = await getAgentsByStage()
      this.agentes = data
      //console.log(this.agentes)
    } catch (error) {
      setNotification('Ocorreu um erro...', 'error')
      //console.log(error)
    }
  },
  computed: {
    ...mapGetters(['user']),
    canAddTaskNote() {
      return hasPermission(this.user.role, 'add task note')
    },
    canAddAssociatedAndInvitedAssociatedTaskNote() {
      return hasPermission(
        this.user.role,
        'add associated and invited associated task note'
      )
    },
    canAddAssociatedTaskNote() {
      return hasPermission(this.user.role, 'add associated task note')
    },
    canAssociateUserToTask() {
      return hasPermission(this.user.role, 'associate user to task')
    },
    canAssociateUserToCreatedTask() {
      return hasPermission(this.user.role, 'associate user to created task')
    },
    canEditTaskState() {
      return hasPermission(this.user.role, 'edit task state') ? true : false
    },
    canEditCreatedOrInvitedAssociatedTaskState() {
      return hasPermission(
        this.user.role,
        'edit created or invited associated task state'
      ) &&
        (this.updatedTask.creator_id == this.user.id ||
          this.updatedTask.task_user?.invited_by_current_user)
        ? true
        : false
    },
    canEditAssociatedTaskState() {
      return hasPermission(this.user.role, 'edit associated task state') &&
        this.updatedTask.task_user?.id == this.user.id
        ? true
        : false
    },
    canEditTaskDelivDate() {
      return hasPermission(this.user.role, 'edit task deliv date')
        ? true
        : false
    },
    canEditCreatedOrInvitedAssociatedTaskDelivDate() {
      return hasPermission(
        this.user.role,
        'edit created or invited associated task deliv date'
      ) &&
        (this.updatedTask.creator_id == this.user.id ||
          this.updatedTask.task_user?.invited_by_current_user)
        ? true
        : false
    },
    getTaskUserId() {
      return this.updatedTask.task_user_id
    },
    getAgent() {
      return this.updatedTask.job?.title || 'Por Delegar'
    },
    getCurrentUserId() {
      return this.user.id
    },
    invitedByCurrentUser() {
      return this.updatedTask.task_user !== null
        ? this.updatedTask.task_user.invited_by_current_user
        : false
    },
    getTaskCreatorId() {
      return this.updatedTask.creator_id
    }
  },
  methods: {
    async handleClick() {
      if (!this.updatedTask.task.name.length)
        return setNotification('Campo obrigatório em falta', 'warn')

      this.formEdited = false

      try {
        const formData = {
          task_id: this.updatedTask.task_id,
          stage_template_id: this.updatedTask.stage_template_id,
          task_pers_id: this.updatedTask.id,
          name: this.updatedTask.task.name,
          job_id: this.updatedTask.job_id,
          task_requirement: this.updatedTask.task_requirement || '',
          task_notes: this.updatedTask.task_notes || '',
          task_deliv_date: this.updatedTask.task_deliv_date,
          deliv_state_id: this.updatedTask.state.id,
          task_user_id: this.updatedTask.task_user_id
        }

        //console.log('FORM DATA', formData)

        const { data, status } = await updateTask(formData)

        if (status !== 200) throw new Error('Error updating task')

        this.updatedTask.task.is_custom
          ? this.$store.dispatch('updateTask', data[0])
          : this.$store.dispatch(
              'updateTemplateTask',
              this.getUpdatedTemplateTask(data[0])
            )

        setNotification('Editado com sucesso!', 'success')
        this.$emit('closeModal')
      } catch (error) {
        this.formEdited = true
        setNotification('Ocorreu um erro...', 'error')
        //console.log(error)
      }
    },
    getJobTitle(job) {
      if (!job) return ''
      return `${job.title}`
    },
    getRelatedUser(user) {
      if (!user) return ''
      return `- ${user.name}`
    },
    getUpdatedTemplateTask(newTask) {
      return {
        ...newTask,
        old_id: this.updatedTask.id
      }
    },
    async setJob() {
      const selectedIndex = this.$refs[this.agentesDpdownRef].selectedIndex - 1

      this.updatedTask.job_id = this.agentes[selectedIndex].job_id
      this.updatedTask.task_user_id =
        this.agentes[selectedIndex].user?.id || null

      //console.log('JOB ID: ', this.updatedTask.job_id)
      //console.log('USER ID: ', this.updatedTask.task_user_id)
    },
    getStateID() {
      //console.log(
      //   'STATE INDEX: ',
      //   this.$refs[this.stateDpdownRef].selectedIndex
      // )
      return this.$refs[this.stateDpdownRef].selectedIndex
    },
    setState() {
      if (this.getStateID() === 0) return
      this.updatedTask.state.id = this.getStateID()
    },
    closeModal() {
      this.$emit('closeModal')
    }
  },
  watch: {
    updatedTask: {
      handler(newData) {
        JSON.stringify(newData) != JSON.stringify(this.item)
          ? (this.formEdited = true)
          : (this.formEdited = false)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.add-task-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  padding-top: 0.5rem;
}

.grid-span-6 {
  grid-column: span 6;
  margin-inline: 1rem;
}

.grid-span-5 {
  margin-inline: 1rem;
  grid-column: span 5;
}

.grid-span-3 {
  grid-column: span 3;
  margin-inline: 1rem;
}

.grid-span-4 {
  grid-column: span 4;
  margin-inline: 1rem;
}

.grid-span-2 {
  grid-column: span 2;
  margin-inline: 1rem;
}

.grid-span-1 {
  margin-inline: 1rem;
  grid-column: span 1;
  text-align: center;
}

.item {
  display: flex;
  flex-direction: column;
}

.item-row {
  display: flex;
  gap: 0.5rem;
}

.flex-row {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

select,
option,
input,
textarea {
  border: 1px solid lightgrey;
  border-radius: 0.25rem;
  outline: none;
}

select,
option,
input,
textarea {
  padding: 0.5rem;
}

textarea {
  height: 100px;
  resize: none;
}

label {
  margin-bottom: 0.25rem;
}

p {
  padding: 0;
  margin: 0;
  line-height: 100%;
}

select {
  cursor: pointer;
}
</style>
