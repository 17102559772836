<template>
  <section
    :class="[
      canEditDetails
        ? 'stage-details-content'
        : 'stage-details-content-cant-edit'
    ]"
  >
    <div class="stage-details-title">
      <h2 class="prim-title">&nbsp;DETALHES DAS FASES</h2>

      <div
        v-if="canEditDetails"
        :class="{ 'show-btn': formEdited }"
        class="my-btn-primary"
        @click="handleClick"
      >
        <h5>Guardar</h5>
      </div>
    </div>

    <section class="createdAt">
      <h5>Empreendimento criado a {{ createdAt }}</h5>
    </section>

    <article v-if="showLoading">
      <ShowLoading />
    </article>
    <article v-else class="stageList">
      <main class="stage-details-container">
        <ul class="flex-stage-header">
          <li class="icon-header"></li>
          <li class="stage-col">
            <h5>Fase</h5>
          </li>
          <li class="start-col">
            <h5>Início</h5>
          </li>
          <li class="end-col">
            <h5>Fim</h5>
          </li>
          <li class="budget-col">
            <h5>Orçamento de Obra</h5>
          </li>
          <li class="right-side-table">
            <h5>Notas</h5>
          </li>
        </ul>

        <!-- LISTAGEM -->
        <ul
          v-for="projectDetail in projectDetails"
          :key="projectDetail"
          class="flex-stage-content"
        >
          <li class="stage-icon">
            <div
              class="icon-select"
              :class="{
                active:
                  projectDetail.stage_value == project.current_stage.stage_value
              }"
              @click="setStage(projectDetail)"
            ></div>
          </li>
          <li class="stage-col">
            <p class="stage-val">
              {{ projectDetail.stage_value }}. {{ projectDetail.stage_name }}
            </p>
          </li>
          <li v-if="projectDetail.start_stage" class="inner-wrapper start-col">
            <input
              type="date"
              class="date"
              v-model="projectDetail.start_stage"
            />
          </li>
          <li v-else class="inner-wrapper start-col">
            <input
              type="text"
              class="date"
              placeholder="Definir data"
              onfocus="(this.type='date')"
              title="Clique para ativar a edição"
              v-model="projectDetail.start_stage"
            />
          </li>
          <li v-if="projectDetail.end_stage" class="inner-wrapper end-col">
            <input type="date" class="date" v-model="projectDetail.end_stage" />
          </li>
          <li v-else class="inner-wrapper end-col">
            <input
              type="text"
              class="date"
              placeholder="Definir data"
              onfocus="(this.type='date')"
              title="Clique para ativar a edição"
              v-model="projectDetail.end_stage"
            />
          </li>

          <li
            style="display: flex; align-items: center"
            class="inner-wrapper budget-col"
          >
            <input
              type="number"
              class="budget form-control"
              v-model="projectDetail.stage_budget"
              placeholder="ex: 20000"
            />
            <span style="position: relative; right: 1.5rem">€</span>
          </li>
          <li class="right-side-table">
            <div class="icons-container">
              <i
                class="icon-annotation"
                style="cursor: pointer"
                @click="showNotes(projectDetail)"
                title="Abrir notas"
              ></i>
            </div>
          </li>
        </ul>
      </main>
    </article>
  </section>

  <section v-if="setModal">
    <Modal
      :setModal="setModal"
      :message_title="message_title"
      :notes="notes"
      :message_body="message_body"
      :scope="scope"
      @closeModal="closeModal"
      @stageNotes="stageNotes"
      @updateStage="updateStage"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { setNotification } from '../../services/filters/dreamCatcher'
import {
  getStageDetails,
  updateStage,
  updateStageDetails
} from '../../services/lib/project-details/stageDetails'
import Modal from '../../views/Modal.vue'
import ShowLoading from '../ShowLoading.vue'
import { hasPermission } from '../../services/lib/rolesHelper'
export default {
  components: { Modal, ShowLoading },
  props: ['project'],
  data() {
    return {
      projectDetails: [],
      initDetailsState: [],
      stageDetails: Object,
      setModal: null,
      currentStageName: null,
      message_title: 'Prentende confirmar a transição de fase?',
      message_body: null,
      newStage: null,
      notes: '',
      scope: '',
      stageDetailId: null,
      formEdited: false,
      showLoading: true
    }
  },

  async created() {
    this.stageDetails = await getStageDetails(this.$store.state.project.id)
    //console.log('STAGEDETAILS: ', this.stageDetails)
    //para aceder aos dois objetos no template criar um novo objeto
    for (const key in this.stageDetails) {
      let newObj = {
        ...this.stageDetails[key],
        ...this.$store.state.stages[key]
      }
      this.projectDetails.push(newObj)
    }
    //para comparar a informação inicial com as mudanças
    this.initDetailsState = JSON.parse(JSON.stringify(this.projectDetails))
    // //console.log(this.projectDetails)
    this.showLoading = !this.showLoading
  },

  methods: {
    setStage(selectedProj) {
      if (
        selectedProj.stage_value !==
        this.$store.state.project.current_stage.stage_value
      ) {
        //getting current stage value and name
        let oldStageInfo

        this.$store.state.stages.forEach((stage) => {
          if (this.project.current_stage.stage_value == stage.stage_value) {
            oldStageInfo = `${stage.stage_value}. ${stage.stage_name}`

            return
          }
        })

        const newStageInfo = `${selectedProj.stage_value}. ${selectedProj.stage_name}`

        this.message_body = `A fase ${oldStageInfo} irá ser substituida pela nova fase: ${newStageInfo}`
        this.newStage = {
          stage_id: selectedProj.stage_id,
          stage_value: selectedProj.stage_value
        }
        this.scope = 'confirm'
        this.setModal = 'setConfirmChanges'
      }
    },
    showNotes(projectDetail) {
      this.stageDetailId = projectDetail.id
      this.notes = projectDetail.stage_notes
      this.setModal = 'showNotes'
    },
    stageNotes(notes) {
      this.setModal = null

      this.projectDetails.forEach((detail) => {
        if (detail.id === this.stageDetailId) {
          detail.stage_notes = notes
        }
      })
    },
    async handleClick() {
      const data = this.projectDetails.filter((detail, index) => {
        return (
          JSON.stringify(detail) !==
          JSON.stringify(this.initDetailsState[index])
        )
      })
      //console.log('STAGE DETAILS DATA: ', data)

      //console.log(data)

      const { status } = await updateStageDetails(data)

      if (status !== 200) {
        setNotification('Ocorreu um erro...', 'error')
        return
      }

      this.formEdited = false
      setNotification('Guardado com sucesso!', 'success')
    },

    async updateStage() {
      const res = await updateStage(
        this.$store.state.project.id,
        this.newStage.stage_id
      )

      try {
        if (res.status == 200) {
          const project = JSON.parse(JSON.stringify(this.$store.state.project))
          project.current_stage.stage_value = this.newStage.stage_value
          this.$store.dispatch('project', project)

          setNotification('Fase alterada com sucesso!', 'success')
        } else {
          setNotification('Ocorreu um erro...', 'error')
          //console.log('Erro na comunicação com o servidor')
        }
      } catch (error) {}

      this.setModal = null
    },
    closeModal() {
      this.setModal = null
    }
  },
  computed: {
    ...mapGetters(['stages', 'user']),
    createdAt() {
      //sem o trycatch dá erro a ir dos detalhes para a landing page
      try {
        const created = this.$store.state.project.created_at.slice(0, 10)
        return created
      } catch (error) {}
    },
    canViewLifecycleDetails() {
      return hasPermission(this.user.role, 'view project lifecycle details')
    },
    canEditDetails() {
      return hasPermission(this.user.role, 'edit project details')
    }
  },
  watch: {
    projectDetails: {
      handler(newData) {
        if (JSON.stringify(newData) !== JSON.stringify(this.initDetailsState)) {
          this.formEdited = true
        } else {
          this.formEdited = false
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.prim-title {
  margin-top: 1rem;
  padding-top: 0.25rem;
  border-left: 0.25rem solid var(--main-color);
  position: relative;
}

.stageList {
  position: absolute;
  width: 95%;
  height: clamp(100px, 85%, 85%);
  bottom: 0;
  margin-bottom: 0.75rem;
}

.stage-details-content {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
}

.stage-details-content-cant-edit {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  pointer-events: none;
}

.stage-details-title {
  position: absolute;
  left: 0;
  top: 0;
}

.createdAt {
  position: relative;
  top: 11%;
  text-align: right;
  width: 95%;
}

.stage-details-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.flex-stage-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--title-color);
}

.flex-stage-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  border-bottom: 1px solid #ddd;
}

.flex-stage-header,
.flex-stage-content {
  list-style-type: none;
}

.flex-stage-header h5 {
  color: var(--text-color);
  padding-block: 0.2rem;
  margin: 0;
}

/* .flex-stage-content li {
  border-bottom: 1px solid #ddd;
} */
/* .flex-stage-content p,
.flex-stage-content input {
  margin: 0;
  padding: 0;
  padding-bottom: 0.5rem;
} */

ul {
  padding-inline-start: 0;
  margin: 0;
  width: 100%;
}

li {
  width: 100%;
}

.right-side-table {
  width: 10%;
  padding-right: 1rem;
}

.icon-header,
.stage-icon {
  width: 0.9rem;
  height: 100%;
  margin: 0;
  border: 1px solid var(--text-color) !important;
  background: var(--text-color) !important;
  position: relative;
  display: flex;
  align-items: center;
}

::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.inner-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date {
  text-align: center;
  border-radius: 0.2rem;
}

.budget {
  border: none;
  outline: none;
  text-align: right;
  letter-spacing: 0.1rem;
}

input[type='text'] {
  width: 85%;
  cursor: pointer;
  padding-inline: 4px;
}

input[type='number'] {
  padding-inline: 1.85rem;
}

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;
}

.form-control {
  width: 85%;
}

.icon-select {
  background: #ddd !important;
  border-radius: 50%;
  width: 0.9rem !important;
  height: 0.9rem !important;
  cursor: pointer;
}

.active {
  background: var(--highlight-color) !important;
  cursor: default;
}

.unicons {
  cursor: pointer;
}

.icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.icon-annotation {
  background: var(--sec-color) !important;
  position: relative;
}

.icon-annotation:hover {
  background: var(--main-color) !important;
}

/**REPEATED BUTTON  */
.date:hover,
.budget:hover {
  background: rgba(0, 0, 0, 0.05);
}

.stage-details-title {
  width: 95%;
  display: flex;
  justify-content: space-between;
}

.my-btn-primary {
  justify-self: flex-end;
  align-self: flex-end;
  margin: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.1);
  transition: 0.5s ease;
}

.my-btn-primary h5 {
  margin: 0;
  color: var(--text-color);
  cursor: pointer;
}

.show-btn {
  background-color: var(--main-color);
  pointer-events: auto;
  cursor: pointer;
}

p {
  margin: 0;
}

/* cols */
.stage-col {
  min-width: 28% !important;
  max-width: 28% !important;
}

.start-col {
  min-width: 16% !important;
  max-width: 16% !important;
}

.end-col {
  min-width: 16% !important;
  max-width: 16% !important;
}

.budget-col {
  min-width: 30% !important;
  max-width: 30% !important;
}

.stage-val {
  text-align: left;
  padding-left: 1rem;
}
</style>
