<template>
  <main class="chart-container">
    <div class="chart-header"><h2>Tarefas</h2></div>

    <div class="chart-content-wrapper">
      <apexchart
        type="donut"
        height="180%"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </main>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts
  },
  props: ['tasksSeries'],
  data() {
    return {
      series: this.getTasksSeries(),
      chartOptions: {
        chart: {
          type: 'donut'
        },
        colors: ['#1ab7ea', '#39539E', '#26e7a6'],
        labels: this.getTasksLabels(),
        legend: {
          position: 'bottom'
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 'auto'
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      }
    }
  },
  methods: {
    getTasksSeries() {
      return this.tasksSeries.map(({ data }) => data)
    },
    getTasksLabels() {
      return this.tasksSeries.map(({ name }) => name)
    }
  }
}
</script>

<style scoepd>
.chart-content-wrapper {
  position: relative;
}
</style>
