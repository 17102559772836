<template>
  <!-- <div class="temp-container">
    <h1>Para mais informações e credenciais de acesso, por favor contacte:</h1>
    <a class="pointer link" href="mailto:digi4construction@bimms.net">
      <h1 class="pointer link">digi4construction@bimms.net</h1>
    </a>
  </div> -->
  <div class="flex-container" ref="container">
    <div class="flex-item">
      <SignupForm @loadOut="loadOut" @toggleTerms="toggleTerms" />
    </div>
    <div class="bg"></div>
  </div>

  <section class="terms-wrapper" v-show="showTerms">
    <div class="terms-content-wrapper">
      <div class="terms-item">
        <p class="p-header">Política de Privacidade</p>
        <p class="p-body">
          A sua privacidade é importante para nós. É política do
          Digi4Construction respeitar a sua privacidade em relação a qualquer
          informação sua que possamos coletar no site Digi4Construction e em
          outros sites que possuímos e operamos.
        </p>

        <p class="p-body">
          Solicitamos informações pessoais apenas quando realmente precisamos
          delas para fornecer-lhe um serviço. Fazemo-lo de maneira justa e
          legal, com o seu conhecimento e consentimento. Também informamos o
          motivo da coleta e como essas informações serão utilizadas.
        </p>

        <p class="p-body">
          Retemos apenas as informações coletadas pelo tempo necessário para
          fornecer o serviço solicitado. Quando armazenamos dados, protegemo-los
          por meios comercialmente aceitáveis para evitar perdas, roubos,
          acesso, divulgação, cópia, uso ou modificação não autorizados.
        </p>

        <p class="p-body">
          Não compartilhamos publicamente informações de identificação pessoal
          ou com terceiros, exceto quando exigido por lei.
        </p>

        <p class="p-body">
          O nosso site pode conter links para sites externos que não são
          operados por nós. Esteja ciente de que não temos controle sobre o
          conteúdo e as práticas desses sites, e não podemos aceitar
          responsabilidade por suas políticas de privacidade respectivas.
        </p>

        <p class="p-body">
          Você é livre para recusar a nossa solicitação de informações pessoais,
          compreendendo que não possamos fornecer os serviços desejados.
        </p>

        <p class="p-body">
          Ao continuar a utilizar o nosso site, consideraremos que você aceita
          as nossas práticas de privacidade e tratamento de informações
          pessoais. Se tiver alguma dúvida sobre como lidamos com os dados do
          utilizador e informações pessoais, entre em contacto connosco.
        </p>
      </div>
      <div class="terms-item">
        <p class="p-header">Compromisso do Utilizador</p>
        <p class="p-body">
          O utilizador compromete-se a fazer uso adequado dos conteúdos e da
          informação que o Digi4Construction oferece no site, nomeadamente:
        </p>
        <ul>
          <li>
            - Não se envolver em atividades ilegais ou contrárias à boa fé e à
            ordem pública;
          </li>
          <li>
            - Não difundir propaganda ou conteúdo de natureza racista, xenófoba,
            ou qualquer tipo de apologia ao terrorismo ou contra os direitos
            humanos;
          </li>
          <li>
            - Não causar danos aos sistemas físicos (hardware) e lógicos
            (software) do Digi4Construction, de seus fornecedores ou de
            terceiros, nem introduzir ou disseminar vírus informáticos ou
            quaisquer outros sistemas de hardware ou software capazes de causar
            os danos anteriormente mencionados.
          </li>
        </ul>
        <br />
        <p class="p-body" style="font-weight: 600">Mais Informações</p>
        <p class="p-body">
          Esperamos que esteja esclarecido e, como mencionado anteriormente,
          caso haja alguma dúvida sobre a necessidade de fornecer determinadas
          informações, geralmente é mais seguro deixar os cookies ativados caso
          interaja com um dos recursos que utiliza em nosso site.
        </p>
        <br />
        <p class="p-body" style="align-self: center; font-weight: 600">
          Esta política entra em vigor em 24 de maio de 2023.
        </p>
      </div>
      <div class="close-terms-btn" @click="toggleTerms"><p>Voltar</p></div>
    </div>
  </section>
</template>

<script>
import SignupForm from '../components/forms/auth/SignupForm.vue'
export default {
  components: { SignupForm },
  data() {
    return {
      showTerms: false
    }
  },
  methods: {
    loadOut() {
      this.$refs.container.classList.add('loadOut')
    },
    toggleTerms() {
      this.showTerms = !this.showTerms
    }
  }
}
</script>

<style scoped>
* {
  color: var(--text-color);
  /* --bg: url('../assets/registerbg.png'); */
  --bg: url('https://images.unsplash.com/photo-1535732759880-bbd5c7265e3f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1964&q=80');
}
/*** */
.temp-container {
  position: absolute;
  width: 60%;
  height: 50%;
  top: 25%;
  left: 20%;
  background-color: var(--topnav-color);
  border-radius: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
h1,
a {
  color: #ddd;
  transition: 0.5s;
}

/*** */
.flex-container {
  /* border: 1px solid red; */
  display: flex;
  flex: 1;
  width: 70vw;
  /* min-width: 950px; */
  height: 80vh;
  min-height: 540px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  position: relative;
  animation: load-in 600ms ease-in-out;
  animation-fill-mode: forwards;
}
.loadOut {
  animation: load-out 400ms ease-in-out;
  animation-fill-mode: forwards;
}
.flex-item {
  width: 70%;
  height: 100%;
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: rgb(73, 76, 82);
  background: linear-gradient(
    180deg,
    rgb(113, 158, 172) -25%,
    #232728 70% /*
    #006699 0%,
    rgba(41, 40, 40, 1) 100%
    */
  );
  border-bottom-left-radius: 1rem;
  position: relative;
  box-shadow: 5px 0px 5px -1px rgba(0, 0, 0, 0.7),
    -5px 0 5px -5px rgba(0, 0, 0, 0);
}
.bg {
  width: 30%;
  height: 100%;
  background-image: var(--bg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px inset,
    rgba(17, 17, 26, 0.1) 0px 8px 24px inset,
    rgba(17, 17, 26, 0.1) 0px 16px 48px inset;
  border-bottom-right-radius: 1rem;
}

.terms-wrapper {
  position: fixed;
  inset: 0;
  z-index: 100;
  isolation: isolate;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  overflow: hidden;
}

.terms-wrapper * {
  padding: 0;
  margin: 0;
}
.terms-content-wrapper {
  margin-top: 8vh;
  background: #222;
  background: linear-gradient(
    180deg,
    rgb(79, 127, 141) -25%,
    #232728 70% /* #006699 0%, rgba(41, 40, 40, 1) 100% */
  );
  width: 40%;
  max-height: 80%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding-block: 4px;
  padding-inline: 20px;
  border-radius: 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: auto;
}
.terms-item {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}

.terms-item > p,
.terms-item li {
  /* color: #333; */
  text-indent: 2em;
}
.p-header {
  font-family: 'Betatron';
  font-size: 24px;
  letter-spacing: 1px;
  padding-block: 0.5rem;
}
.p-body,
li {
  text-align: justify;
  color: #eee;
}

li {
  margin-left: 1rem;
  list-style-type: none;
}
.close-terms-btn {
  margin-top: 0.5rem;
  padding-inline: 12px;
  padding-block: 4px;
  width: max-content;
  align-self: flex-end;
  border-radius: 4px;
  background: var(--main-color);
}

.close-terms-btn:hover {
  background: var(--highlight-color);
}
.close-terms-btn > p {
  font-family: 'Betatron';
  font-size: 16px;
  cursor: pointer;

  letter-spacing: 2px;
}

@media (width <= 1200px) {
  .flex-container {
    width: 80vw;
  }
  .terms-content-wrapper {
    width: 60%;
  }
}
@media (width <= 1023px) {
  .flex-container {
    width: 90vw;
  }
}
@media (width <= 768px) {
  .flex-container {
    width: 98vw;
    height: 84vh;
  }
  .flex-item {
    width: 100%;
  }
  .bg {
    display: none;
  }
  .terms-content-wrapper {
    width: 80%;
  }
}
@media (width <= 480px) {
  .terms-content-wrapper {
    width: 100%;
  }
}

@keyframes load-in {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes load-out {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-120%);
  }
}
</style>
