<template>
  <main class="register-grid-container" v-show="!regRequest">
    <div class="grid-span-4 title"><h1>Registar</h1></div>
    <div class="grid-span-2 first-name">
      <p ref="nameP">
        Primeiro Nome
        <span style="color: var(--error-color)" title="Campo Obrigatório">
          *
        </span>
      </p>
      <input
        type="text"
        ref="firstName"
        @focus="animateFocus('nameP')"
        @blur="blur('nameP', 'firstName')"
        v-model="myFormData.firstName"
      />
      <label class="errMsg" ref="firstNameErr">Nome não é válido!</label>
    </div>
    <div class="grid-span-2 last-name">
      <p ref="surnameP">Último Nome</p>
      <input
        type="text"
        ref="lastName"
        @focus="animateFocus('surnameP')"
        @blur="blur('surnameP', 'lastName')"
        v-model="myFormData.lastName"
      />
    </div>
    <div class="grid-span-4 form-content-4">
      <p ref="emailP">
        Email
        <span style="color: var(--error-color)" title="Campo Obrigatório">
          *
        </span>
      </p>
      <input
        type="email"
        ref="email"
        @focus="animateFocus('emailP')"
        @blur="blur('emailP', 'email')"
        v-model="myFormData.email"
      />
      <label class="errMsg" ref="emailErr">Email não é válido!</label>
    </div>
    <div class="grid-span-4 form-content-4">
      <p ref="passP">
        Password
        <span style="color: var(--error-color)" title="Campo Obrigatório">
          *
        </span>
      </p>
      <input
        type="password"
        ref="password"
        @focus="animateFocus('passP')"
        @blur="blur('passP', 'password')"
        v-model="myFormData.password"
      />
      <label class="errMsg" ref="passwordErr">
        As passwords devem ser iguais e exceder 8 caracteres!
      </label>
    </div>
    <div class="grid-span-4 form-content-4">
      <p ref="confpassP">
        Confirmar Password
        <span style="color: var(--error-color)" title="Campo Obrigatório">
          *
        </span>
      </p>
      <input
        type="password"
        ref="confirmPass"
        @focus="animateFocus('confpassP')"
        @blur="blur('confpassP', 'confirmPass')"
        v-model="myFormData.confirmPassword"
      />
      <label class="errMsg" ref="confirmPasswordErr">
        As passwords devem ser iguais e exceder 8 caracteres!
      </label>
    </div>
    <div class="terms-container">
      <label>
        Concordo com os
        <span class="read-terms" @click="toggleTerms">termos e condições</span>
      </label>
      <span class="terms" ref="checkbox" @click="check">
        <i class="fas fa-check"></i>
      </span>
    </div>
    <div class="grid-span-4">
      <label class="errMsg" ref="termsErr">
        Para prosseguir, deve concordar com os nossos termos e condições.
      </label>
    </div>

    <div class="register-btn" @click="handleClick">
      <h4>Registar</h4>
    </div>

    <div class="grid-span-4">
      <label style="user-select: none; color: #ddd">
        Já tem conta registada? Faça login
        <span
          class="pointer link"
          style="text-decoration: underline"
          @click="toLogin"
        >
          aqui
        </span>
        <!-- <router-link :to="{ name: 'Login' }" class="highlight">
          aqui
        </router-link> -->
      </label>
    </div>

    <div class="bottom-highlight"></div>
  </main>

  <ShowLoading v-if="showLoading" />

  <main class="confirm-grid-container" v-if="regRequest" ref="confirmReg">
    <div v-if="regResponse" class="grid-span-4">
      <div class="title">
        <h1>Registar</h1>
      </div>
      <div>
        <!-- <h5>Deve confirmar a sua conta.</h5>
        <h5>
          Por favor verifique o seu email para encontrar o link de confirmação:
        </h5> -->
        <h5>
          Obrigado por efetuar o registo no Digi4Construction. Por favor
          verifique a caixa de correio do seu e-mail.
        </h5>
        <br />
        <h4>
          {{ myFormData.email }}
        </h4>
        <i
          class="fas fa-check fa-4x"
          style="padding-top: 2rem; color: #cdff9e"
        ></i>
      </div>
    </div>

    <div class="bottom-highlight" id="highlight"></div>
  </main>

  <section v-if="regResponse" class="toLogin" @click="toLogin()">
    <i class="fas fa-arrow-left fa-xs"></i>
    Voltar para o login
  </section>
</template>

<script>
import auth from '../../../services/lib/auth'
import { setNotification } from '../../../services/filters/dreamCatcher'
import ShowLoading from '../../ShowLoadingLogin.vue'
export default {
  emits: ['loadOut', 'toggleTerms'],
  components: { ShowLoading },
  data() {
    return {
      myFormData: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
      },

      termsChecked: false,
      showLoading: false,
      regRequest: false,
      regResponse: false
    }
  },
  methods: {
    async handleClick() {
      const validation = this.validate()
      if (validation === true) {
        const data = {
          name: `${this.myFormData.firstName.trim()} ${this.myFormData.lastName.trim()}`,
          email: this.myFormData.email.trim(),
          password: this.myFormData.password,
          password_confirmation: this.myFormData.confirmPassword
        }
        // //console.log(data)

        try {
          this.regRequest = !this.regRequest
          setTimeout(() => {
            this.showLoading = !this.showLoading
          }, 250)

          const res = await auth.register(data)
          //console.log(res)
          switch (res.status) {
            case 422:
              this.regRequest = !this.regRequest
              this.showLoading = !this.showLoading
              setNotification('Email já está registado!', 'error')
              this.myFormData.email = ''
              break
            case 200:
              setTimeout(() => {
                this.showLoading = !this.showLoading
                this.$refs.confirmReg.classList.add('requestSent')

                this.regResponse = !this.regResponse

                document.getElementById('highlight').style.borderBottomColor =
                  '#cdff9e'
              }, 1000)
              break
            default:
              break
          }
        } catch (error) {
          //console.log('ocorreu um erro em handleClick')
        }
      }

      // this.resetForm()
    },
    check() {
      this.termsChecked = !this.termsChecked
      this.termsChecked
        ? this.$refs.checkbox.classList.add('checked')
        : this.$refs.checkbox.classList.remove('checked')
    },
    toggleTerms() {
      this.$emit('toggleTerms')
    },
    animateFocus(pRef) {
      this.$refs[pRef].classList.add('active')
    },
    blur(pRef, inputRef) {
      if (this.$refs[inputRef].value == '') {
        this.$refs[pRef].classList.remove('active')
      }
    },
    async toLogin() {
      this.$emit('loadOut')

      setTimeout(() => {
        this.$router.push({ name: 'Login' })
      }, 600)
    },
    resetForm() {
      this.myFormData.firstName = ''
      this.myFormData.lastName = ''
      this.myFormData.email = ''
      this.myFormData.password = ''
      this.myFormData.confirmPassword = ''
      this.termsChecked = false

      const activeCss = document.querySelectorAll('.active')
      activeCss.forEach((item) => {
        item.classList.remove('active')
      })
    },
    validate() {
      let validatedCounter = 0
      const fieldsToValidate = 4

      if (this.myFormData.firstName == '') {
        this.$refs.firstNameErr.classList.add('errMsgActive')
      } else if (this.myFormData.firstName.length) {
        this.$refs.firstNameErr.classList.remove('errMsgActive')
        validatedCounter++
      }

      if (this.myFormData.email == '' || !this.myFormData.email.includes('@')) {
        this.$refs.emailErr.classList.add('errMsgActive')
      } else if (this.myFormData.email.length) {
        this.$refs.emailErr.classList.remove('errMsgActive')
        validatedCounter++
      }

      if (
        this.myFormData.password != this.myFormData.confirmPassword ||
        this.myFormData.password == '' ||
        this.myFormData.password.length < 8
      ) {
        this.$refs.passwordErr.classList.add('errMsgActive')
        this.$refs.confirmPasswordErr.classList.add('errMsgActive')
      } else if (this.myFormData.password.length) {
        this.$refs.passwordErr.classList.remove('errMsgActive')
        this.$refs.confirmPasswordErr.classList.remove('errMsgActive')
        validatedCounter++
      }

      if (!this.termsChecked) {
        this.$refs.termsErr.classList.add('errMsgActive')
      } else {
        this.$refs.termsErr.classList.remove('errMsgActive')
        validatedCounter++
      }

      if (validatedCounter === fieldsToValidate) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.title {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.register-grid-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  padding-inline: 10rem;
  padding-block: 1rem;
  overflow: hidden;
  height: 0%;
  animation: load-in 750ms ease;
  animation-fill-mode: forwards;
}
.bottom-highlight {
  border-bottom: 0.33rem solid var(--main-color);
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  height: 100%;
  width: 65%;
  left: 17.5%;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  transition: 0.5s ease;
}
.form-content-4 {
  text-align: left;
  left: 15%;
}
.grid-span-4 {
  grid-column: span 4;
  position: relative;
  z-index: 1;
}
.grid-span-3 {
  grid-column: span 3;
  position: relative;
  z-index: 1;
}
.grid-span-2 {
  grid-column: span 2;
  position: relative;
  z-index: 1;
  text-align: left;
}
.grid-span-1 {
  grid-column: span 1;
  position: relative;
  z-index: 1;
}

.first-name {
  position: relative;
  left: 30%;
  display: flex;
  flex-direction: column;
}
.form-content-4 {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}
.errMsg {
  color: var(--error-color);
  display: inline;
  visibility: hidden;
}
.errMsgActive {
  visibility: visible !important;
}
.terms-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-column: 2 / span 3;
}

h1,
h4,
h5 {
  color: var(--text-color);
  position: relative;
}
input {
  width: 70%;
  padding: 0.4rem;
  background: transparent;
  border: none;
  border-bottom: 1px solid #777;
  position: relative;
  z-index: 1;
  color: var(--text-color);
}

input:focus {
  outline: none;
  border-bottom: 1px solid var(--text-color);
  color: var(--text-color);
}
.highlight {
  color: var(--text-color);
}
.highlight:hover {
  color: var(--highlight-color);
  cursor: pointer;
}
h4 {
  position: relative;
  user-select: none;
}
.terms {
  background: var(--text-color);
  position: relative;
  width: max(15px, 1rem);
  height: max(15px, 1rem);
  margin-left: 1rem;
  cursor: pointer;
  border-radius: 0.1rem;
  z-index: 2;
  transition: 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}
.read-terms {
  cursor: pointer;
  position: relative;
  text-decoration: underline;
  color: #ddd;
  z-index: 1;
}
.read-terms:hover {
  color: var(--highlight-color);
}
.checked {
  background: var(--highlight-color);
}
.register-btn {
  background: var(--highlight-color);
  text-align: center;
  transition: 0.25s ease-in-out;
  cursor: pointer;
  border-radius: 1rem;
  grid-column: 2 / span 2;
  z-index: 10;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: max(35px, 2.25rem);
}
.register-btn:hover {
  background: var(--main-color);
}
.register-btn h4 {
  user-select: none;
  margin: 0;
  /* padding-block: 0.33rem; */
  font-family: 'SulphurPoint-Bold';
}
.fas {
  width: max(15px, 1rem);
  height: max(15px, 1rem);
}
p {
  padding: 0;
  margin: 0;
  transform: translate(0, 12px);
  user-select: none;
  transition: 0.2s ease;
  width: 50%;
  position: relative;
  z-index: 0;
  color: #eee;
}
.active {
  transform: translate(0, 0);
  color: #ccc;
}
input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--text-color);
  font-family: 'SulphurPoint';
}

/* PART 2 */
.confirm-grid-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  padding-inline: 10rem;
  padding-block: 1rem;
  overflow: hidden;
  height: 0%;
  animation: load-out 350ms ease;
  animation-fill-mode: forwards;
}
.requestSent {
  animation: res-load-in 350ms ease;
  animation-fill-mode: forwards;
}
.toLogin {
  display: inline;
  text-align: left;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  padding: 0.5rem;
  transition: 150ms ease;
  color: #ccc;
  user-select: none;
}
.toLogin:hover {
  color: var(--text-color);
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.2rem;
}

/* ANIMATIONS */

@keyframes load-in {
  0% {
    height: 0%;
  }
  100% {
    height: 97.5%;
  }
}
@keyframes load-out {
  0% {
    height: 97.5%;
  }
  100% {
    height: 0%;
  }
}
@keyframes res-load-in {
  0% {
    height: 0%;
  }
  100% {
    height: 50%;
  }
}
</style>
