import axios from 'axios'
import store from '../store/store'
import router from '../router'

axios.defaults.baseURL = `${process.env.VUE_APP_BASE_URL}/api/v1`

const apiClient = axios.create({
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

apiClient.interceptors.request.use((config) => {
  if (store.getters.token) {
    config.headers['Authorization'] = 'Bearer ' + store.getters.token
  }
  return config
})

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    switch (error.response.status) {
      case 401:
        //unauthorized
        return { data: error.response.data, status: error.response.status }
      case 403:
        //forbidden
        return { data: error.response.data, status: error.response.status }
      case 409:
        return { data: error.response.data, status: error.response.status }
      case 422:
        //unprocessable
        return { data: error.response.data, status: error.response.status }
      case 500:
        //internal server error
        router.push('/server-error')
        break
      // add more cases for other error status codes if needed
      default:
        break
    }
  }
)

export default apiClient
