<template>
  <section v-if="showResMsg" class="reqNotification">
    <p>
      Se houver uma conta associada a
      <span class="bold">{{ email }},</span>
      irá receber um email com um link para repor a sua palavra-passe.
    </p>
  </section>

  <main class="sub-container">
    <h1 class="title">Recuperar Password</h1>
    <div class="recover-container">
      <p>
        Por favor insira o seu email para receber um link de reposição da sua
        palavra-passe.
      </p>
      <input type="text" class="recover-input" ref="input" v-model="email" />
    </div>

    <div
      ref="btn"
      :class="[email.includes('@') ? 'recover-btn' : 'btn-deactivated']"
      @click="handleClick"
    >
      <div class="loading-wrapper" v-if="requestSent && !showResMsg">
        <ShowLoadingLogin />
      </div>
      <h5 v-else>{{ getBtnText }}</h5>
    </div>
    <section class="toLogin" @click="toLogin">
      <i class="fas fa-arrow-left fa-xs"></i>
      Voltar para o login
    </section>
  </main>
</template>

<script>
import { setNotification } from '../../../services/filters/dreamCatcher'
import { forgot_password } from '../../../services/lib/auth'
import ShowLoadingLogin from '../../ShowLoadingLogin.vue'
export default {
  components: {
    ShowLoadingLogin
  },
  emits: ['loadOut'],
  data() {
    return {
      email: '',
      requestSent: false,
      showResMsg: false
    }
  },
  computed: {
    getBtnText() {
      return !this.showResMsg ? 'Repôr Password' : 'Enviado'
    }
  },
  methods: {
    async toLogin() {
      this.$emit('loadOut')
    },
    async handleClick() {
      if (!this.email || !this.email.includes('@')) return

      this.$refs.btn.classList.add('btn-deactivated')

      try {
        this.requestSent = !this.requestSent
        const formData = { email: this.email.trim() }

        const { status } = await forgot_password(formData)

        if (status !== 200) throw new Error('Ocorreu um erro...')

        this.showResMsg = true
        this.$refs.input.style.pointerEvents = 'none'
        this.$refs.input.style.userSelect = 'none'
        this.$refs.btn.classList.add('btn-deactivated')
        this.$refs.input.classList.add('recover-sent')
      } catch (error) {
        setNotification('Ocorreu um erro...', 'error')
        this.$refs.btn.classList.remove('btn-deactivated')
        this.requestSent = !this.requestSent
      }
    }
  },
  watch: {
    email: function () {
      if (this.email.includes('@')) {
        this.$refs.input.classList.add('recover-ok')
      }
      if (!this.email.includes('@')) {
        this.$refs.input.classList.remove('recover-ok')
      }
    }
  }
}
</script>

<style scoped>
.sub-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-inline: 3rem;
  padding-block: 1rem;
  background: rgb(73, 76, 82);
  background: linear-gradient(118deg, rgb(0, 162, 255) -25%, #232728f5 70%);
}
.title {
  text-align: left;
  grid-column: span 3;
  padding-top: 1rem;
}
.recover-container {
  grid-column: span 4;
  text-align: left;
}
.recover-container p {
  width: 60%;
}
.recover-input {
  padding: 0.5rem;
  width: 60%;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.2rem;
  color: var(--text-color);
  background: rgba(0, 0, 0, 0.2);
  transition: 0.5s ease;
}

.recover-input:focus {
  box-shadow: 0 0 0 2px white;
}
.recover-ok {
  border: 1px solid rgb(190, 255, 190);
}
.recover-sent {
  border: 1px solid var(--highlight-color);
}
.recover-input:focus {
  outline: none;
  color: var(--text-color);
}
.recover-btn {
  background: var(--highlight-color);
  text-align: center;
  transition: 0.25s ease-in-out;
  cursor: pointer;
  border-radius: 0.2rem;
  grid-column: 4 / span 1;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  height: max(35px, 2.25rem);
}
.recover-btn h5 {
  margin: 0;
  padding: 0;
  user-select: none;
}
.btn-deactivated {
  background: rgba(49, 102, 180, 0.295);
  text-align: center;
  transition: 0.25s ease-in-out;
  border-radius: 0.2rem;
  grid-column: 4 / span 1;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  height: max(35px, 2.25rem);
  user-select: none;
  pointer-events: none;
}
.btn-deactivated h5 {
  margin: 0;
  padding: 0;
  user-select: none;
  color: #ccc;
}
.toLogin {
  display: inline;
  text-align: left;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  padding: 0.5rem;
  transition: 150ms ease;
  color: #ccc;
  user-select: none;
  grid-column: span 4;
  text-align: right;
  font-size: 0.9rem;
}
.toLogin:hover {
  color: var(--text-color);
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.2rem;
}
.reqNotification {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 1;
  background: rgba(189, 255, 189, 0.904);
  width: 100%;
}
.reqNotification p {
  margin: 0;
  padding: 0;
  padding-block: 0.25rem;
  color: #333;
}

h1,
p,
h5 {
  color: var(--text-color);
}
.bold {
  font-weight: 600;
}
.loading-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.loading-wrapper * {
  scale: 0.4;
  inset: 0;
  top: -32%;
}
</style>
