<template>
  <main class="content-wrapper">
    <div class="title-wrapper">
      <h1 class="content-title">TAREFAS NA FASE {{ stageIndex }}</h1>
    </div>

    <section class="subtool">
      <div class="btn-container">
        <SearchBar
          :results="`${tasks.length} de ${tasksLength}`"
          @inputValueChange="filterTasks"
        />
        <div
          v-if="canAddTask || canAddAssociatedTask"
          class="subtool-btn"
          @click="setModal = 'addTask'"
        >
          <h5>Adicionar Tarefa</h5>
        </div>
      </div>
    </section>

    <div v-if="showLoading">
      <ShowLoading />
    </div>
    <div v-else class="list-container">
      <ListTasks :tasks="computedTasks" />
    </div>
  </main>

  <section v-if="setModal">
    <Modal :setModal="setModal" @closeModal="closeModal" />
  </section>
</template>

<script>
import ListTasks from '../../components/ListTasks.vue'
import tasks from '../../services/lib/tasks'
import ShowLoading from '../../components/ShowLoading.vue'
import SearchBar from '../../components/SearchBar.vue'
import Modal from '../Modal.vue'
import { mapGetters } from 'vuex'
import { hasPermission } from '../../services/lib/rolesHelper'

export default {
  name: 'Tarefas',
  components: { ListTasks, ShowLoading, Modal, SearchBar },
  data() {
    return {
      setModal: null,
      debounce: null,
      tasks: [],
      tasksLength: 0,
      searchedString: ''
    }
  },
  async created() {
    if (this.$store.state.stageIndex != null) {
      try {
        await tasks.getTasks()
      } catch (error) {
        //console.log(error)
      }
    }
  },
  methods: {
    closeModal() {
      this.setModal = null
    },
    filterTasks(searched) {
      clearTimeout(this.debounce)

      this.debounce = setTimeout(() => {
        this.searchedString = searched
        //console.log('SEACHED STRING: ', this.searchedString)
      }, 350)
    }
  },
  computed: {
    ...mapGetters(['stageIndex', 'showLoading', 'user']),
    computedTasks() {
      return this.tasks.filter((task) => {
        const title = task.job?.title || 'Por Delegar'
        return (
          task.task.name
            .toLowerCase()
            .match(this.searchedString.toLowerCase()) ||
          task.task.ref
            .toLowerCase()
            .match(this.searchedString.toLowerCase()) ||
          task.state.state
            .toLowerCase()
            .match(this.searchedString.toLowerCase()) ||
          title.toLowerCase().match(this.searchedString.toLowerCase()) ||
          task.state.state
            .toLowerCase()
            .match(this.searchedString.toLowerCase())
        )
      })
    },
    canAddTask() {
      return hasPermission(this.user.role, 'add task')
    },
    canAddAssociatedTask() {
      return hasPermission(this.user.role, 'add associated task')
    }
  },
  watch: {
    '$store.state.tasks': function () {
      if (!this.$store.state.tasks) return //need this to avoid state conflict
      this.tasks = this.$store.state.tasks
      this.tasksLength = this.tasks.length

      this.$store.dispatch('showLoading', false)
    },

    'tasks.length': function () {
      if (!tasks) return //need this to avoid state conflict
      this.tasksLength = this.tasks.length
    }
  },
  unmounted() {
    this.$store.dispatch('cleanView', 'tasks')
  }
}
</script>
