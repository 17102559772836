import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

const ls = new SecureLS({ isCompression: false })

const store = createStore({
  state: {
    user: null,
    verifier: null,
    token: null,
    project: null,
    projectsLength: null,
    stages: null,
    stageIndex: null,
    tasks: null,
    agentes: null,
    users: null,
    roles: null,
    systems: null,
    entregs: null,
    showLoading: null,
    notificationMessage: null,
    notificationStatus: null,
    version: null
  },
  mutations: {
    reset(state) {
      state.user = null
      state.verifier = null
      state.token = null
      state.project = null
      state.projectsLength = null
      state.stages = null
      state.stageIndex = null
      state.tasks = null
      state.agentes = null
      state.users = null
      state.roles = null
      state.systems = null
      state.entregs = null
      state.showLoading = null
      state.notificationMessage = null
      state.notificationStatus = null
    },
    user(state, user) {
      state.user = user
    },
    updateUserAvatar(state, avatar) {
      state.user.avatar = avatar
    },
    updateUserDetails(state, data) {
      const { company_name, phone_number } = data[0]

      state.user.company_name = company_name
      state.user.phone_number = phone_number
    },
    addRoleToCurrentUser(state, role) {
      state.user.role = role
    },
    verifier(state, verifier) {
      state.verifier = verifier
    },
    token(state, token) {
      state.token = token
    },
    project(state, project) {
      state.project = project
    },
    projectsLength(state, projectsLength) {
      state.projectsLength = projectsLength
    },
    stages(state, stages) {
      state.stages = stages
    },
    stageIndex(state, stageIndex) {
      state.stageIndex = stageIndex
    },
    //LOADING
    showLoading(state, showLoading) {
      state.showLoading = showLoading
    },
    //CONTENT
    tasks(state, tasks) {
      state.tasks = tasks
    },
    addTask(state, data) {
      state.tasks.unshift(data)
    },
    updateTask(state, data) {
      //updates the state.tasks array with the new data on the index of the updated task id
      const index = state.tasks.findIndex((task) => task.id === data.id)
      state.tasks[index] = data
    },
    updateTemplateTask(state, data) {
      //updates state.tasks array with the new data on the index of the old_id passed as argument
      const index = state.tasks.findIndex((task) => task.id === data.old_id)
      state.tasks[index] = data
    },
    deleteTask(state, id) {
      //remove from the array, the task with the id passed as argument
      const index = state.tasks.findIndex((task) => task.id === id)
      state.tasks.splice(index, 1)
    },
    agentes(state, agentes) {
      state.agentes = agentes
    },
    addAgente(state, data) {
      state.agentes.unshift(data)
    },
    updateAgent(state, data) {
      //updates the state.agentes array with the new data on the index of the updated agent id
      const index = state.agentes.findIndex((agente) => agente.id === data.id)
      state.agentes[index] = data
    },
    deleteAgent(state, id) {
      //remove from the array, the agent with the id passed as argument
      const index = state.agentes.findIndex((agente) => agente.id === id)
      state.agentes.splice(index, 1)
    },
    users(state, users) {
      state.users = users
    },
    roles(state, roles) {
      state.roles = roles
    },
    systems(state, systems) {
      state.systems = systems
    },
    addSystem(state, data) {
      state.systems.unshift(data)
    },
    updateSystem(state, data) {
      const index = state.systems.findIndex((system) => system.id === data.id)

      state.systems[index] = data
    },
    deleteSystem(state, id) {
      const index = state.systems.findIndex((system) => system.id === id)

      state.systems.splice(index, 1)
    },
    specifySystem(state, data) {
      const { parent_id, children } = data

      const index = state.systems.findIndex((system) => system.id === parent_id)

      //replace the system in the current index with the children
      state.systems.splice(index, 1, ...children)
    },
    entregs(state, entregs) {
      state.entregs = entregs
    },
    addEntregavel(state, data) {
      state.entregs.unshift(data)
    },
    updateEntregavel(state, data) {
      //updates the state.entregs array with the new data on the index of the updated entregavel id
      const index = state.entregs.findIndex(
        (entregavel) => entregavel.id === data.id
      )
      state.entregs[index] = data
    },
    deleteEntregavel(state, id) {
      //remove from the array, the entregavel with the id passed as argument
      const index = state.entregs.findIndex(
        (entregavel) => entregavel.id === id
      )
      state.entregs.splice(index, 1)
    },
    unshiftSystem(state, system) {
      state.systems.unshift(system)
    },
    //MISC
    notificationMessage(state, notificationMessage) {
      state.notificationMessage = notificationMessage
    },
    notificationStatus(state, notificationStatus) {
      state.notificationStatus = notificationStatus
    },
    cleanView(state, view) {
      state[view] = null
    },
    version(state, version) {
      state.version = version
    }
  },
  actions: {
    reset(context) {
      context.commit('reset')
    },
    user(context, user) {
      context.commit('user', user)
    },
    updateUserAvatar(context, avatar) {
      context.commit('updateUserAvatar', avatar)
    },
    updateUserDetails(context, data) {
      context.commit('updateUserDetails', data)
    },
    addRoleToCurrentUser(context, role) {
      context.commit('addRoleToCurrentUser', role)
    },
    verifier(context, verifier) {
      context.commit('verifier', verifier)
    },
    token(context, token) {
      context.commit('token', token)
    },
    project(context, project) {
      context.commit('project', project)
    },
    projectsLength(context, projectsLength) {
      context.commit('projectsLength', projectsLength)
    },
    stages(context, stages) {
      context.commit('stages', stages)
    },
    stageIndex(context, stageIndex) {
      context.commit('stageIndex', stageIndex)
    },
    //LOADING
    showLoading(context, showLoading) {
      context.commit('showLoading', showLoading)
    },
    //CONTENT
    tasks(context, tasks) {
      context.commit('tasks', tasks)
    },
    addTask(context, data) {
      context.commit('addTask', data)
    },
    updateTask(context, data) {
      context.commit('updateTask', data)
    },
    updateTemplateTask(context, data) {
      context.commit('updateTemplateTask', data)
    },
    deleteTask(context, data) {
      context.commit('deleteTask', data)
    },
    agentes(context, agentes) {
      context.commit('agentes', agentes)
    },
    addAgente(context, data) {
      context.commit('addAgente', data)
    },
    updateAgent(context, data) {
      context.commit('updateAgent', data)
    },
    deleteAgent(context, data) {
      context.commit('deleteAgent', data)
    },
    users(context, users) {
      context.commit('users', users)
    },
    roles(context, roles) {
      context.commit('roles', roles)
    },
    systems(context, systems) {
      context.commit('systems', systems)
    },
    addSystem(context, data) {
      context.commit('addSystem', data)
    },
    updateSystem(context, data) {
      context.commit('updateSystem', data)
    },
    specifySystem(context, data) {
      context.commit('specifySystem', data)
    },
    deleteSystem(context, id) {
      context.commit('deleteSystem', id)
    },
    entregs(context, entregs) {
      context.commit('entregs', entregs)
    },
    addEntregavel(context, data) {
      context.commit('addEntregavel', data)
    },
    updateEntregavel(context, data) {
      context.commit('updateEntregavel', data)
    },
    deleteEntregavel(context, id) {
      context.commit('deleteEntregavel', id)
    },
    unshiftSystem(context, unshiftSystem) {
      context.commit('unshiftSystem', unshiftSystem)
    },
    //MISC
    notificationMessage(context, notificationMessage) {
      context.commit('notificationMessage', notificationMessage)
    },
    notificationStatus(context, notificationStatus) {
      context.commit('notificationStatus', notificationStatus)
    },
    cleanView(context, view) {
      context.commit('cleanView', view)
    },
    version(context, version) {
      context - this.commit('version', version)
    }
  },
  getters: {
    user: (state) => {
      return state.user
    },
    verifier: (state) => {
      return state.verifier
    },
    token: (state) => {
      return state.token
    },
    project: (state) => {
      return state.project
    },
    projectsLength: (state) => {
      return state.projectsLength
    },
    stages: (state) => {
      return state.stages
    },
    stageIndex: (state) => {
      return state.stageIndex
    },
    //LOADING
    showLoading: (state) => {
      return state.showLoading
    },
    //CONTENT
    tasks: (state) => {
      return state.tasks
    },
    agentes: (state) => {
      return state.agentes
    },
    systems: (state) => {
      return state.systems
    },
    entregs: (state) => {
      return state.entregs
    },
    //MISC
    notificationMessage: (state) => {
      return state.notificationMessage
    },
    notificationStatus: (state) => {
      return state.notificationStatus
    },
    version: (state) => {
      return state.version
    }
  },
  // plugins: [createPersistedState()]
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })
  ]
})

export default store
