import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/store'
import Dashboard from '../views/content/Dashboard.vue'
import ProjectDetails from '../views/content/ProjectDetails.vue'
import Agentes from '../views/content/Agentes.vue'
import Sistemas from '../views/content/Sistemas.vue'
import Entregaveis from '../views/content/Entregaveis.vue'
import Tarefas from '../views/content/Tarefas.vue'
import Visualizador from '../views/content/Visualizador.vue'
import PronicWrapper from '../views/content/PronicWrapper.vue'
import Welcome from '../views/Welcome.vue'
import NotFound from '../views/NotFound.vue'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import RecoverPass from '../views/RecoverPass.vue'
import ResetPass from '../views/ResetPass.vue'
import Projetos from '../views/Projetos.vue'
import ServerError from '../views/ServerError.vue'
import UserData from '../views/UserData.vue'
import Conteudos from '../views/Conteudos.vue'
import DocumentationPage from '../views/DocumentationPage.vue'
import Notificacoes from '../components/notifications/UserNotifications.vue'

const clearStore = () => {
  store.dispatch('user', null)
  store.dispatch('token', null)
  store.dispatch('project', null)
  store.dispatch('stageIndex', null)
}
//auth guards
const requireAuth = (to, from, next) => {
  const { user, token, project } = store.state
  const requiredRoutes = [
    'Dashboard',
    'ProjectDetails',
    'Agentes',
    'Tarefas',
    'Sistemas',
    'Entregaveis',
    'Detalhes',
    'Visualizador',
    'PronicWrapper'
  ]

  if (to.name === 'Welcome') {
    if (project) {
      store.dispatch('project', null)
      store.dispatch('stageIndex', null)
    }
    next()
    return
  }

  if (!user || !token) {
    //console.log('Invalid login info')
    clearStore()
    next({ name: 'Welcome' })
    return
  }

  if (!project && requiredRoutes.includes(to.name)) {
    next({ name: 'Projetos' })
    return
  }

  if (project && requiredRoutes.includes(to.name)) {
    store.dispatch('showLoading', true)
  } else if (project) {
    store.dispatch('project', null)
    store.dispatch('stageIndex', null)
  }

  next()
}

const authFilter = (to, from, next) => {
  try {
    const user = store.state.user.name
    const token = store.state.token

    if (user && token) {
      next({ name: 'Projetos' })
    }
  } catch (error) {
    next()
    // //console.log('Error on index = ' + error)
  }
}

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
    beforeEnter: requireAuth
  },
  {
    path: '/projetos',
    name: 'Projetos',
    component: Projetos,
    beforeEnter: requireAuth
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: requireAuth
  },
  {
    path: '/detalhes',
    name: 'ProjectDetails',
    component: ProjectDetails,
    beforeEnter: requireAuth
  },
  {
    path: '/agentes',
    name: 'Agentes',
    component: Agentes,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: authFilter
  },
  {
    path: '/registar',
    name: 'Signup',
    component: Signup,
    beforeEnter: authFilter
  },
  {
    path: '/recover-password',
    name: 'RecoverPass',
    component: RecoverPass,
    beforeEnter: authFilter
  },
  {
    path: '/reset-password/:email/:token',
    name: 'ResetPass',
    component: ResetPass,
    beforeEnter: authFilter
  },
  {
    path: '/sistemas',
    name: 'Sistemas',
    component: Sistemas,
    beforeEnter: requireAuth
  },
  {
    path: '/entregaveis',
    name: 'Entregaveis',
    component: Entregaveis,
    beforeEnter: requireAuth
  },
  {
    path: '/tarefas',
    name: 'Tarefas',
    component: Tarefas,
    beforeEnter: requireAuth
  },
  {
    path: '/visualizador',
    name: 'Visualizador',
    component: Visualizador,
    beforeEnter: requireAuth
  },
  {
    path: '/pronic',
    name: 'PronicWrapper',
    component: PronicWrapper,
    beforeEnter: requireAuth
  },
  {
    path: '/perfil',
    name: 'UserData',
    component: UserData,
    beforeEnter: requireAuth
  },
  {
    path: '/conteudos',
    name: 'Conteudos',
    component: Conteudos,
    beforeEnter: requireAuth
  },
  {
    path: '/documentacao',
    name: 'DocumentationPage',
    component: DocumentationPage,
    beforeEnter: requireAuth
  },
  {
    path: '/notifications',
    name: 'Notificacoes',
    component: Notificacoes,
    beforeEnter: requireAuth
  },
  {
    path: '/server-error',
    name: 'ServerError',
    component: ServerError
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach(() => {
//   //console.log(store.state.user)
// })
export default router
