<template>
  <main class="profile-content">
    <UserProfileContent />
  </main>
</template>

<script>
import UserProfileContent from '../components/user/UserProfileContent.vue'
export default {
  components: {
    UserProfileContent
  }
}
</script>

<style scoped>
.profile-content {
  position: absolute;
  top: 0;
  left: 5%;
  margin-top: 10vh;
  width: 90vw;
  height: max-content;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
@media screen and (max-width: 1023px) {
  .profile-content {
    margin-top: 5rem;
  }
}
@media screen and (max-width: 767px) {
  .profile-content {
    margin-top: 8rem;
  }
}
</style>
