<template>
  <main class="profile-content">
    <section class="content-wrapper">
      <div class="row" style="margin-bottom: 1.5rem">
        <div class="title-wrapper">
          <h1 class="content-title">CONTEÚDOS</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="card mb-4 h-100 bg-video">
            <video autoplay loop muted loading="lazy">
              <source
                src="https://revconstruction.pt/wp-content/uploads/2021/11/rev@.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <div class="card-body text-center digi-card">
              <h2 @click="redirect" class="logo" title="Abrir REV@CONSTRUCTION">
                Digi
                <span>4</span>
                Construction
              </h2>
            </div>
            <p class="pointer">
              <router-link
                :to="{ name: 'DocumentationPage' }"
                class="nav-link active docs-link"
              >
                Ver Documentação para Importar Empreendimento Aqui
              </router-link>
            </p>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="row">
            <div class="flex-column">
              <div class="card mb-4 first-text-wrapper">
                <div class="card-body">
                  <p class="text-justify-custom">
                    No âmbito do PPS1 do Projeto Mobilizador
                    <span class="custom-text">Rev@Construction</span>
                    entidades do Sistema Científico e Tecnológico e membros da
                    indústria nacional da Construção unem esforços para a
                    investigação e desenvolvimento de bases tipificadas e
                    transversais para a uniformização dos processos de Gestão de
                    Projeto e da informação que se gera e é gerida ao longo do
                    ciclo de vida de um Empreendimento. Este esforço é
                    alicerçado por uma visão conjunta de uma transição digital
                    fluida e eficiente, equipando o setor AEC nacional para ser
                    mais competitivo e reconhecido além fronteiras. Estes
                    fundamentos essenciais para homogeneizar a comunicação e
                    colaboração entre Agentes participantes estão produzidos via
                    Relatórios ou Fóruns e o seu conteúdo está em grande parte
                    operacionalizado em ferramentas digitais, acessíveis no
                    portal integrado
                    <span class="custom-text">Digi4Construction</span>
                    , tal como nas próprias funcionalidades que constituem esta
                    plataforma.
                  </p>
                  <p class="text-justify-custom">
                    Listados em seguida podem consultar alguns destes recursos
                    de informação e acessos às múltiplas ferramentas que compõem
                    o ambiente integrado do
                    <span class="custom-text">PPS1. Digi4Construction</span>
                  </p>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <p
                        class="mb-4 custom-text"
                        style="font-size: 1rem; font-weight: bold"
                      >
                        Recursos
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <p class="mb-1" style="font-size: 0.77rem">
                        <i class="bi bi-dot custom-dot"></i>
                        TECHinfo 4.0 - Tipificação de Modelos de Obra e
                        Normalização da Informação
                      </p>
                    </div>
                    <div class="col-md-6">
                      <p class="mb-1" style="font-size: 0.77rem">
                        <i class="bi bi-dot custom-dot"></i>
                        COSTmanage 4.0 - Base de Dados de Custos
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <p class="mb-1" style="font-size: 0.77rem">
                        <i class="bi bi-dot custom-dot"></i>
                        ProNIC - Protocolo para a Normalização da Informação
                        Técnica na Construção
                      </p>
                    </div>
                    <div class="col-md-6">
                      <p class="mb-1" style="font-size: 0.77rem">
                        <i class="bi bi-dot custom-dot"></i>
                        Plan4Digital - Framework para implementação tecnológica
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <p class="mb-1" style="font-size: 0.77rem">
                        <i class="bi bi-dot custom-dot"></i>
                        idBIM 4.0 - Biblioteca de Objetos BIM Nacional
                      </p>
                    </div>
                    <div class="col-md-6">
                      <p class="mb-1" style="font-size: 0.77rem">
                        <i class="bi bi-dot custom-dot"></i>
                        BIM4Bridges - Entrega digital de modelos BIM de Obras de
                        Arte
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  methods: {
    redirect() {
      window.open('https://revconstruction.pt/en', '_blank')
    }
  }
}
</script>

<style scoped>
.profile-content {
  position: absolute;
  inset: 0;
  width: 100%;
  height: max-content;
  margin-top: 5vh;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  overflow: auto;
}
.content-wrapper {
  width: 90%;
  margin-top: 2rem;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding-block: 1rem;
  padding-inline: 4rem;
}
.flex-item {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 0.6rem;
}
.flex-column {
  display: flex !important;
  flex-flow: column !important;
  justify-content: space-between !important;
}
.custom-dot {
  color: #5bc2e7;
  font-size: 1.2rem;
}

.custom-text {
  color: #5bc2e7;
  font-weight: bold;
}

.text-justify-custom {
  text-align: justify;
  text-justify: inter-word;
  font-size: 0.87rem;
  font-weight: var(--e-global-typography-text-font-weight);
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-text-font-family), Sans-serif;
}

h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  font-family: 'SulphurPoint-Bold';
  transition: 200ms;
}

p {
  text-align: left;
}

.nav-link {
  transition: 100ms;
}

.card-body:hover {
  cursor: default;
  background: none;
}

.bg-video {
  position: relative;
  isolation: isolate;
  border-radius: 0.5rem;
  overflow: hidden;
}
.bg-video::before {
  content: '';
  position: absolute;
  inset: 0;
  /* background: rgba(0, 0, 0, 0.32); */
  background: linear-gradient(
    120deg,
    rgb(5, 159, 197),
    #00485a,
    var(--main-color),
    #222
  );
  opacity: 0.2;
  z-index: 1;
  border-radius: inherit;
}

video {
  position: absolute;
  isolation: isolate;
  z-index: 0;
  inset: 0;
  border-radius: inherit;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: hue-rotate(180deg) saturate(2);
  opacity: 0.8;
  transition: 200ms;
}

.bg-video:hover > video {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}
.bg-video:hover .docs-link {
  transform: translateY(-2rem);
}

.docs-link {
  background: rgba(0, 0, 0, 0.4) !important;
  color: white;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  transition: 320ms ease-in-out;
}
.docs-link:hover {
  background: var(--highlight-color) !important;
  color: white;
  opacity: 1;
}
.digi-card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  color: white;
}

.digi-card > h2 {
  margin: 0;
  padding: 0;
}
.logo {
  font-family: 'Betatron';
  scale: 1.2;
  text-shadow: 0 0 0.2rem var(--highlight-color);
}

h2:hover {
  cursor: pointer;
  color: rgb(112, 186, 230);
}
h2 span {
  font-size: inherit !important;
  transition: 200ms;
}
h2 span {
  color: rgb(112, 186, 230) !important;
}
h2:hover span {
  color: white !important;
}
p,
span {
  font-size: 16px !important;
}

@media screen and (max-width: 992px) {
  .bg-video {
    min-height: 25vh !important;
  }
  .first-text-wrapper {
    margin-top: 2rem;
  }
}
</style>
