<template>
  <teleport to="body">
    <main class="cmodal">
      <div class="modal-window" :class="scope">
        <component
          :is="getComponent.component"
          v-bind="getComponent.props"
          v-on="getComponent.events"
        />
      </div>
    </main>
  </teleport>
</template>

<script>
import CreateProject from '../components/forms/modals/CreateProject.vue'
import InfoPermissions from '../components/forms/modals/agentes/InfoPermissions.vue'
import AddAgente from '../components/forms/modals/agentes/AddAgente.vue'
import UpdateAgente from '../components/forms/modals/agentes/UpdateAgente.vue'
import InviteUser from '../components/forms/modals/agentes/InviteUser.vue'
import AddTask from '../components/forms/modals/tasks/AddTask.vue'
import UpdateTask from '../components/forms/modals/tasks/UpdateTask.vue'
import AddSystem from '../components/forms/modals/systems/AddSystem.vue'
import UpdateSystem from '../components/forms/modals/systems/UpdateSystem.vue'
import SpecifySystem from '../components/forms/modals/systems/SpecifySystem.vue'
import AddEntregavel from '../components/forms/modals/entregaveis/AddEntregavel.vue'
import UpdateEntregavel from '../components/forms/modals/entregaveis/UpdateEntregavel.vue'
import ConfirmChanges from '../components/forms/modals/ConfirmChanges.vue'
import ShowNotes from '../components/forms/modals/project_details/ShowNotes.vue'
import UploadFile from '../components/forms/modals/UploadFile.vue'
import DownloadPdf from '../components/forms/modals/DownloadPdf.vue'
import Import from '../components/forms/modals/Import.vue'
import { markRaw } from 'vue'

export default {
  components: {
    CreateProject,
    InfoPermissions,
    AddAgente,
    UpdateAgente,
    InviteUser,
    AddTask,
    AddSystem,
    UpdateSystem,
    ConfirmChanges,
    SpecifySystem,
    AddEntregavel,
    UpdateEntregavel,
    ShowNotes,
    UpdateTask,
    UploadFile,
    DownloadPdf,
    Import
  },
  emits: [
    'closeModal',
    'stageNotes',
    'updateStage',
    'resetFiles',
    'deleteSuccess'
  ],
  props: [
    'setModal',
    'message_title',
    'message_body',
    'notes',
    'file',
    'item',
    'scope'
  ],
  data() {
    return {
      modalMap: markRaw({
        //marRaw sets the object as a non-reactive object
        createProj: {
          component: CreateProject,
          events: { closeModal: this.closeModal }
        },
        infoPermi: {
          component: InfoPermissions,
          events: { closeModal: this.closeModal }
        },
        addAgente: {
          component: AddAgente,
          events: { closeModal: this.closeModal }
        },
        updateAgent: {
          component: UpdateAgente,
          props: { item: this.item },
          events: { closeModal: this.closeModal }
        },
        inviteUser: {
          component: InviteUser,
          events: { closeModal: this.closeModal }
        },
        addTask: {
          component: AddTask,
          events: { closeModal: this.closeModal }
        },
        updateTask: {
          component: UpdateTask,
          props: { item: this.item },
          events: { closeModal: this.closeModal }
        },
        addSystem: {
          component: AddSystem,
          events: { closeModal: this.closeModal }
        },
        updateSystem: {
          component: UpdateSystem,
          props: { item: this.item },
          events: { closeModal: this.closeModal }
        },
        specifySystem: {
          component: SpecifySystem,
          props: { item: this.item },
          events: { closeModal: this.closeModal }
        },
        addEntregavel: {
          component: AddEntregavel,
          events: { closeModal: this.closeModal }
        },
        updateEntregavel: {
          component: UpdateEntregavel,
          props: { item: this.item },
          events: { closeModal: this.closeModal }
        },
        setConfirmChanges: {
          component: ConfirmChanges,
          props: {
            message_title: this.message_title,
            message_body: this.message_body,
            scope: this.scope,
            item: this.item
          },
          events: {
            closeModal: this.closeModal,
            updateStage: this.updateStage,
            deleteSuccess: this.deleteSuccess
          }
        },
        showNotes: {
          component: ShowNotes,
          props: { notes: this.notes },
          events: { closeModal: this.closeModal, stageNotes: this.stageNotes }
        },
        setUploadFile: {
          component: UploadFile,
          props: {
            file: this.file,
            scope: this.scope,
            message_title: this.message_title
          },
          events: {
            closeModal: this.closeModal,
            resetFiles: this.resetFiles
          }
        },
        downloadPdf: {
          component: DownloadPdf,
          events: { closeModal: this.closeModal }
        },
        import: {
          component: Import,
          events: { closeModal: this.closeModal }
        }
      })
    }
  },
  computed: {
    getComponent() {
      return this.modalMap[this.setModal]
    }
  },
  methods: {
    stageNotes(notes) {
      this.$emit('stageNotes', notes)
    },
    deleteSuccess() {
      this.$emit('deleteSuccess')
    },
    updateStage() {
      this.$emit('updateStage')
    },
    resetFiles() {
      this.$emit('resetFiles')
    },
    closeModal(data) {
      this.$emit('closeModal', data)
    }
  }
}
</script>

<style scoped>
.cmodal {
  backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  animation: blur 0.5s;
}
.modal-window {
  background-color: #fff;
  max-height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  width: auto;
  min-width: 245px;
  max-width: 65%;
  border-radius: 1rem;
  position: relative;
  z-index: 101;
}
.danger {
  background-image: url('../assets/danger_modal.svg');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
}
.confirm {
  background-image: url('../assets/ok_modal.svg');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-height: 700px) {
  .content {
    resize: both;
    overflow: auto;
  }
}

@keyframes blur {
  0% {
    backdrop-filter: blur(0);
  }
  100% {
    backdrop-filter: blur(2px);
  }
}
</style>
