<template>
  <div class="container">
    <div class="row">
      <div class="title-wrapper mb-4">
        <h1 class="content-title">PERFIL DE UTILIZADOR</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div class="card mb-4 bg-transparent">
          <div class="card-body text-center p-flex">
            <div class="avatar-wrapper" @click="handleUpload">
              <img
                :src="getUserAvatar"
                :alt="`${user.name} avatar`"
                class="avatar"
              />

              <input
                type="file"
                class="upload-avatar"
                accept="image/*"
                ref="avatarUploaderRef"
                @change="handleFileSelect"
              />
            </div>

            <h2 class="my-2">{{ user.name }}</h2>
            <p class="mb-2">
              Envolvido em
              <span class="my-bold">{{ projects?.length }}</span>
              {{ get_projects_msg }}
            </p>

            <section
              class="width100 d-flex justify-content-between align-items-end"
              style="margin-bottom: 2px"
            >
              <p class="text-muted mb-0">Membro desde: {{ getCreatedDate }}</p>
              <div
                class="d-flex justify-content-center gap-2"
                style="margin-top: 1rem"
              >
                <i
                  title="Editar"
                  class="icon-edit"
                  v-show="!editing"
                  @click="editEvents('edit')"
                ></i>
                <i
                  title="Voltar"
                  class="icon-close-sm"
                  v-show="editing"
                  @click="editEvents('cancel')"
                ></i>
                <i
                  title="Remover Avatar"
                  class="icon-remove-avatar"
                  v-show="editing && validateAvatar"
                  @click="removeAvatar('avatar')"
                ></i>
                <i
                  title="Guardar"
                  class="icon-save"
                  v-show="editing"
                  @click="handleClick('save')"
                ></i>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card mb-4 bg-transparent">
          <div class="card-body">
            <div class="flex-item">
              <label>Nome Completo</label>
              <input
                type="text"
                :value="user.name"
                ref="nome"
                class="form-control"
                disabled
              />
            </div>
            <div class="flex-item">
              <label>Email</label>
              <input
                type="text"
                :value="user.email"
                ref="email"
                class="form-control"
                disabled
              />
            </div>
            <div class="flex-item">
              <label>Contacto Pessoal</label>
              <input
                type="text"
                :value="getUserContact"
                ref="userContactRef"
                class="form-control"
                :disabled="!editing"
              />
            </div>
            <div class="flex-item">
              <label>Nome da Empresa</label>
              <input
                type="text"
                :value="getUserCompany"
                ref="userCompanyRef"
                class="form-control"
                :disabled="!editing"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="projects?.length">
        <div class="title-wrapper mb-4">
          <h1 class="content-title">OS MEUS PROJETOS</h1>
        </div>
      </div>
      <section class="col-lg-12">
        <section class="loading-wrapper" v-if="!projects">
          <ShowLoading />
        </section>
        <section class="mb-4 bg-transparent p-2 projects-container" v-else>
          <section
            class="project-wrapper"
            v-for="{
              id,
              ref,
              project_name,
              project_image,
              current_stage,
              detail,
              proj_creator,
              created_at,
              descrip,
              updated_at
            } in projects"
            :key="id"
          >
            <div class="project-img-wrapper">
              <img :src="getProjectImg(project_image)" :alt="project_name" />
              <div class="project-desc-wrapper">
                <h5
                  style="font-family: 'SulphurPoint-Bold'; margin-bottom: 1rem"
                >
                  Descrição do Projecto
                </h5>
                <p>{{ descrip }}</p>
              </div>
            </div>
            <div class="project-content-wrapper">
              <div class="main-info-wrapper">
                <h4 style="font-family: 'SulphurPoint-Bold'" title="Referência">
                  {{ ref }}
                </h4>
                <h4
                  style="font-family: 'SulphurPoint-Bold'"
                  title="Nome do Projeto"
                >
                  {{ project_name }}
                </h4>
              </div>
              <h5 class="stage-info">
                <span style="font-family: 'SulphurPoint-Bold'">
                  Fase Atual: {{ current_stage.stage_value }}
                </span>
              </h5>
              <div class="location-wrapper">
                <i class="icon-home"></i>
                <p>{{ detail.location }}</p>
              </div>
              <p>
                Criado por:
                <span style="font-family: 'SulphurPoint-Bold'">
                  {{ proj_creator.name }}
                </span>
              </p>

              <div class="dates-wrapper">
                <p>Criado a: {{ getCreationDate(created_at) }}</p>
                <p>Atualizado a: {{ getUpdatedDate(created_at) }}</p>
              </div>
            </div>
          </section>
        </section>
      </section>
    </div>
  </div>

  <section v-if="setModal">
    <Modal
      :setModal="setModal"
      :scope="'profile'"
      :message_title="'CONFIRMA A ALTERAÇÃO DE IMAGEM?'"
      :file="selectedFile"
      @closeModal="closeModal"
      @resetFiles="resetFiles"
    />
  </section>
</template>

<script>
import { setNotification } from '../../services/filters/dreamCatcher'
import { get_projects } from '../../services/lib/projects'
import {
  delete_user_avatar,
  update_user_details
} from '../../services/lib/auth'
import Modal from '../../views/Modal.vue'
import ShowLoading from '../ShowLoading.vue'
export default {
  name: 'Perfil',
  components: {
    Modal,
    ShowLoading
  },
  data() {
    return {
      editing: false,
      user: null,
      projects: null,
      loading: true,
      //REFS
      userContactRef: 'userContactRef',
      userCompanyRef: 'userCompanyRef',
      avatarUploaderRef: 'avatarUploaderRef',
      //Img upload
      selectedFile: null,
      setModal: null
    }
  },
  async created() {
    this.user = this.$store.state.user
    //console.log('logged in user!', this.user)

    const { data, status } = await get_projects()
    if (status !== 200) {
      setNotification('Ocorreu um erro...', 'error')
      return
    }

    this.projects = data
    //console.log(this.projects)
  },

  methods: {
    async handleClick(editEventCmd) {
      const contact = this.$refs[this.userContactRef].value
      const company = this.$refs[this.userCompanyRef].value

      try {
        const formData = { company_name: company, phone_number: contact }

        const { data, status } = await update_user_details(formData)
        if (status !== 200)
          throw new Error('Erro a atualizar os detalhes do utilizador')

        this.$store.dispatch('updateUserDetails', data)

        //console.log(this.$store.state.user)

        setNotification('Detalhes atualizados com sucesso', 'success')
        this.editEvents(editEventCmd)
      } catch (error) {
        setNotification('Ocorreu um erro...', 'error')
        //console.log(error)
      }
    },
    async removeAvatar(editEventCmd) {
      try {
        const { status } = await delete_user_avatar()

        if (status !== 200) throw new Error('Error deleting avatar')

        this.$store.dispatch('updateUserAvatar', null)
        setNotification('Avatar removido com sucesso', 'success')
        this.editEvents(editEventCmd)
      } catch (error) {
        setNotification('Ocorreu um erro...', 'error')
        //console.log(error)
      }
    },
    handleUpload() {
      //click on upload button
      this.$refs[this.avatarUploaderRef].click()
    },
    //create the handler for the upload
    async handleFileSelect(event) {
      if (event.target.files[0]) {
        this.selectedFile = await event.target.files[0]
        this.setModal = 'setUploadFile'
      }
    },
    closeModal() {
      this.setModal = null
    },
    resetFiles() {
      this.setModal = null
      this.$refs[this.avatarUploaderRef].value = null
      // //console.log('ok')
    },
    getProjectImg(imgURL) {
      if (imgURL) return `${process.env.VUE_APP_BASE_URL}/storage/${imgURL}`

      return require('../../assets/thumbnails/model_bimms.png')
    },
    getCreationDate(date) {
      return new Date(date).toLocaleDateString()
    },
    getUpdatedDate(date) {
      return new Date(date).toLocaleDateString()
    },
    editEvents(event) {
      switch (event) {
        case 'edit':
          this.editing = true
          break
        case 'cancel':
          this.editing = false
          break
        case 'save':
          this.editing = false
          break
        case 'avatar':
          this.editing = false
          break
      }
    },
    toggleLoading() {
      this.loading = !this.loading
    }
  },
  computed: {
    getUserAvatar() {
      if (!this.user.avatar || this.user.avatar === '0x') {
        const thumbnail_url =
          'https://cdn.pixabay.com/photo/2017/07/18/23/23/user-2517433_1280.png'
        return thumbnail_url
      }

      return `${process.env.VUE_APP_BASE_URL}/storage/${this.user.avatar}`
    },
    getCreatedDate() {
      return new Date(this.user.created_at).toLocaleDateString()
    },
    getUserContact() {
      const phoneNumber = this.user.phone_number || 'Não definido'

      return phoneNumber.replace(/(\d{3})(?=\d)/g, '$1 ')
    },
    getUserCompany() {
      return this.user.company_name || 'Não definido'
    },
    get_projects_msg() {
      return this.projects?.length !== 1 ? 'Projetos' : 'Projeto'
    },
    validateAvatar() {
      return this.user.avatar || this.user.avatar === '0x'
    }
  }
}
</script>

<style scoped>
.container {
  padding-top: 2rem;
}
.flex-item {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 0.6rem;
}

.icon-edit {
  -webkit-mask-image: url('../../assets/icons/edit3.svg');
  mask-image: url('../../assets/icons/edit3.svg');
  background-image: url('../../assets/icons/edit3.svg');
}

i {
  background: var(--title-color);
  cursor: pointer;
  scale: 0.75;
}

i:hover {
  background: var(--highlight-color);
  transform: translateY(-2px) rotate(16deg);
}

.icon-close-sm:hover {
  background: salmon;
}
.width100 {
  width: 100%;
}
.my-bold {
  font-family: 'SulphurPoint-Bold';
}
.p-flex {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.avatar-wrapper {
  position: relative;
  z-index: 0;
  isolation: isolate;
  width: 150px;
  height: 150px;
  border-radius: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  justify-self: center;
  user-select: none;
  overflow: hidden;
}
.avatar-wrapper:hover::before {
  content: 'Alterar imagem';
  position: absolute;
  z-index: 1;
  font-size: 1.4vmin;
  letter-spacing: 1px;
  width: 100%;
  height: 100%;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: avatar-animation 320ms ease;
}
.avatar-wrapper:hover {
  cursor: pointer;
}
.avatar {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
}
.upload-avatar {
  visibility: hidden;
  opacity: 0;
}
.loading-wrapper {
  position: relative;
  height: 4rem;
  margin-bottom: 4rem;
}
.projects-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  width: 100%;
}
.project-wrapper {
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 4px;
  border: 1px solid #ccc;
  display: flex;
  --img-width: 18vmin;
  min-height: var(--img-height);
  max-height: var(--img-height);
  background: whitesmoke;
}
.project-img-wrapper {
  position: relative;
  height: var(--img-height);
  width: var(--img-width);
  inset: 0;
  overflow: hidden;
}
.project-img-wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  scale: 0.96;
  border-radius: 4px;
  transition: opacity 0.2s ease-in-out;
}
.project-img-wrapper:hover > img {
  opacity: 0.2;
}
.project-img-wrapper:hover > .project-desc-wrapper {
  opacity: 1;
  transition: opacity 800ms ease-in-out;
}
.project-img-wrapper > .project-desc-wrapper {
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  padding-top: 2rem;
  padding-inline: 1rem;
  inset: 0;
  top: 0;
  left: 0;
  opacity: 0;
  overflow: auto;
  text-align: left;
}
.project-content-wrapper {
  padding: 1rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 16px;
  overflow: auto;
}
.main-info-wrapper,
.dates-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}
.main-info-wrapper > h4,
.dates-wrapper > p {
  margin: 0;
  padding: 0;
  text-align: left;
}
.project-content-wrapper > p {
  margin: 0;
  padding: 0;
  text-align: left;
}
.stage-info {
  padding: 0.5rem;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.location-wrapper {
  display: flex;
  align-items: center;
  align-self: center;
  gap: 8px;
  margin: 0;
}

.icon-home {
  display: block;
  cursor: default !important;
}
.location-wrapper > p {
  margin: 0;
}

.icon-close-sm:hover {
  background: #999 !important;
}
.icon-remove-avatar:hover {
  background: salmon !important;
}

@keyframes avatar-animation {
  from {
    opacity: 0;
    border-radius: 0;
  }
  to {
    opacity: 1;
    border-radius: inherit;
  }
}
</style>
