<template>
  <main>
    <Topnav />
    <section v-if="project" class="page">
      <Proj :key="project.project_image" />
      <Stages />
      <MainTool />
      <AppsNav />
      <OffCanvas />
      <section class="content">
        <router-view />
      </section>
    </section>
    <section v-else>
      <router-view />
    </section>
    <ProjectNotification
      v-if="notificationMessage && notificationStatus"
      :notificationMessage="notificationMessage"
      :notificationStatus="notificationStatus"
      :key="notificationMessage"
    />
  </main>
</template>

<script>
import Topnav from './components/navbars/Topnav.vue'
import { mapGetters } from 'vuex'
import Proj from './components/Proj.vue'
import Stages from './components/Stages.vue'
import MainTool from './components/navbars/MainTool.vue'
import AppsNav from './components/navbars/AppsNav.vue'
import OffCanvas from './views/content/OffCanvas.vue'
import ProjectNotification from './components/ProjectNotification.vue'
import ServerError from './views/ServerError.vue'
import { version } from '../package'
export default {
  components: {
    Topnav,
    Proj,
    Stages,
    MainTool,
    AppsNav,
    OffCanvas,
    ProjectNotification,
    ServerError
  },
  computed: mapGetters([
    'project',
    'notificationMessage',
    'notificationStatus'
  ]),

  async beforeCreate() {
    //THIS IS ONLY FOR VERSION CHECK - VERSIONING STILL MANUAL
    if (version === this.$store.state.version) return

    await this.$router.push({ name: 'Welcome' })
    await this.$store.dispatch('reset')
    await this.$store.dispatch('version', version)
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.$store.dispatch('notificationMessage', null)
        this.$store.dispatch('notificationStatus', null)
      }
    }

    // '$route.name': {
    //   handler: function (search) {
    //     try {
    //       this.$store.dispatch('scope', search.toLowerCase())
    //     } catch (error) {
    //       this.$store.dispatch('scope', null)
    //     }
    //     //console.log(search)
    //   },
    //   deep: true,
    //   immediate: true
    // }
  }
}
</script>

<style>
#app {
  font-family: 'SulphurPoint', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #444 !important;
  /* ##### */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>

<style scoped>
/* .view {
  position: relative;
  top: 3.55em;
} */
</style>
