<template>
  <main class="content-wrapper">
    <div class="title-wrapper">
      <h1 class="content-title">ENTREGÁVEIS NA FASE {{ stageIndex }}</h1>
    </div>
    <section class="subtool">
      <div class="btn-container">
        <SearchBar
          :results="`${entregs.length} de ${entregsLength}`"
          @inputValueChange="filterEntregs"
        />
        <div
          v-if="canAddEntregable"
          class="subtool-btn"
          @click="setModal = 'addEntregavel'"
        >
          <h5>Adicionar Entregável</h5>
        </div>
      </div>
    </section>

    <div v-if="showLoading">
      <ShowLoading />
    </div>
    <div v-else class="list-container">
      <ListEntregs :entregs="computedEntregs" />
    </div>
  </main>

  <section v-if="setModal">
    <Modal :setModal="setModal" @closeModal="closeModal" />
  </section>
</template>

<script>
import ListEntregs from '../../components/ListEntregs.vue'
import ShowLoading from '../../components/ShowLoading.vue'
import SearchBar from '../../components/SearchBar.vue'
import Modal from '../Modal.vue'
import { mapGetters } from 'vuex'
import { getEntregs } from '../../services/lib/entregs'
import { hasPermission } from '../../services/lib/rolesHelper'

export default {
  name: 'Dashboard',
  components: { ListEntregs, ShowLoading, SearchBar, Modal },
  data() {
    return {
      setModal: null,
      debounce: null,
      entregs: [],
      entregsLength: 0,
      searchedString: ''
    }
  },
  async created() {
    if (this.$store.state.stageIndex != null) {
      try {
        await getEntregs()
      } catch (error) {
        //console.log(error)
      }
    }
  },
  computed: {
    ...mapGetters(['showLoading', 'stageIndex', 'user']),
    computedEntregs() {
      return this.entregs.filter((entreg) => {
        return (
          entreg.entregable.ref
            .toLowerCase()
            .match(this.searchedString.toLowerCase()) ||
          entreg.entregable.name
            .toLowerCase()
            .match(this.searchedString.toLowerCase()) ||
          entreg.state.state
            .toLowerCase()
            .match(this.searchedString.toLowerCase())
        )
      })
    },
    canAddEntregable() {
      return hasPermission(this.user.role, 'add entregable')
    }
  },
  methods: {
    closeModal() {
      this.setModal = null
    },
    filterEntregs(searched) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchedString = searched
      }, 350)
    }
  },

  watch: {
    '$store.state.entregs': function () {
      if (!this.$store.state.entregs) return //need this to avoid state conflict
      this.entregs = this.$store.state.entregs
      this.entregsLength = this.entregs.length

      this.$store.dispatch('showLoading', false)
    },

    'entregs.length': function () {
      if (!this.entregs) return //need this to avoid state conflict
      this.entregsLength = this.entregs.length
    }
  },
  unmounted() {
    this.$store.dispatch('cleanView', 'entregs')
  }
}
</script>

<style scoped></style>
