<template>
  <iframe
    width="100%"
    height="auto"
    style="border: 0"
    allowfullscreen
    loading="lazy"
    :src="location"
  ></iframe>
</template>

<script>
export default {
  props: ['propSearch'],
  data() {
    return {
      location: `https://www.google.com/maps/embed/v1/place?key=AIzaSyADFnkmxkknB8qyp5z4fSvE6gCMSiNN33I&q=`
    }
  },
  created() {
    if (this.propSearch === 'Localização por definir') {
      this.location = `${this.location}portugal`
    } else {
      this.location = `${this.location}${this.propSearch}`
    }
  }
}
</script>
