<template>
  <div class="form-title title-banner"><h2>NOVO EMPREENDIMENTO</h2></div>

  <section v-if="showLoading" class="showLoading">
    <ShowLoading style="position: relative; left: 0; top: 0" />
  </section>

  <section v-else class="creation-form">
    <Form @submit="handleSubmit" :validation-schema="validation" class="form">
      <div class="flexbox-container">
        <div>
          <p>Referência</p>
          <Field
            class="form-control"
            name="referencia"
            type="text"
            placeholder="Ex: AB 1234"
            v-model="referencia"
            style="width: 70%"
          />
          <span class="err">
            <ErrorMessage name="referencia" />
          </span>
        </div>

        <div>
          <p>Nome do Empreendimento</p>
          <Field
            class="form-control"
            name="projName"
            type="text"
            placeholder="Ex: Edifício de Escritórios"
            v-model="projName"
            style="width: 100%"
          />
          <span class="err">
            <ErrorMessage name="projName" />
          </span>
        </div>
      </div>

      <Field name="desc" type="text" v-model="desc">
        <textarea
          class="form-control"
          v-model="desc"
          maxlength="255"
          placeholder="Descrição do Empreendimento"
        ></textarea>
      </Field>
      <div style="display: flex; justify-content: space-between">
        <label style="padding-bottom: 1rem">
          Caracteres restantes: {{ remainingChars(desc) }}
        </label>
        <span class="err">
          <ErrorMessage name="desc" />
        </span>
      </div>

      <div class="sec-title">
        <h3>&nbsp;Framework Inicial do Empreendimento</h3>
      </div>
      <h5 class="pointer" @click="handleSection('section1')">
        <span><i class="fas fa-chevron-right"></i></span>
        Templates
      </h5>

      <div class="section" v-if="section === 'section1'">
        <Field name="template">
          <select v-model="template" class="form-select">
            <option selected disabled hidden>Edifício</option>
            <option value="edificio">Edifício</option>
            <!-- <option value="ponte">BIM4Bridges</option>
            <option value="">Sem Template</option> -->
          </select>
        </Field>
      </div>

      <hr />
      <h5 class="pointer" @click="handleSection('section2')">
        <span><i class="fas fa-chevron-right"></i></span>
        Empreendimento Existente
      </h5>

      <div class="section" v-if="section === 'section2'">
        <Field name="projects">
          <select v-model="template" name="projects" class="form-select">
            <option selected disabled hidden>Em Desenvolvimento</option>
            <option value="">* Em Desenvolvimento *</option>
          </select>
        </Field>
      </div>

      <hr />
      <h5 class="pointer" @click="handleSection('section3')">
        <span><i class="fas fa-chevron-right"></i></span>
        Tutorial
      </h5>

      <div class="section" v-if="section === 'section3'">
        <Field name="tutorial">
          <select v-model="template" class="form-select">
            <option value="">* Em Desenvolvimento *</option>
          </select>
        </Field>
      </div>

      <br />
      <div class="btn-group">
        <button class="confirmar">Criar Empreendimento</button>
        <span class="cancelbtn pointer" @click="closeModal">Cancelar</span>
      </div>
    </Form>
  </section>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import projects from '../../../services/lib/projects'
import ShowLoading from '../../ShowLoading.vue'
import { resolveDynamicComponent } from '@vue/runtime-core'
import { getStages } from '../../../services/lib/stages'
import {
  setNotification,
  calcChars
} from '../../../services/filters/dreamCatcher'
export default {
  components: { Form, Field, ErrorMessage, ShowLoading },
  emits: ['closeModal'],
  data() {
    const validation = yup.object({
      referencia: yup.string().required('Campo obrigatório'),
      projName: yup.string().required('Campo obrigatório'),
      desc: yup.string().required('Campo obrigatório')
    })
    return {
      showLoading: false,
      projName: '',
      referencia: '',
      desc: '',
      template: 'Edifício',
      validation,
      section: 'section1'
    }
  },
  methods: {
    async handleSubmit() {
      try {
        this.showLoading = !this.showLoading

        const data = {
          template_id: 1,
          ref: this.referencia,
          project_name: this.projName,
          descrip: this.desc
        }

        const res = await projects.createProject(data)

        switch (res.status) {
          case 200:
            //TEMPORARIO
            const projeto = await projects.getProject(res.id)

            const stages = await getStages()
            this.$store.dispatch('stages', stages)
            //tem de ser antes de dar dispatch do project, possivelmente por causa do v-if em App.vue

            this.$store.dispatch('project', projeto[0])
            this.$store.dispatch('addRoleToCurrentUser', 'administrator')
            this.showLoading = !this.showLoading
            await this.$router.push({ name: 'Dashboard' })

            setNotification('Criado com sucesso!', 'success')
            break
          default:
            //console.log('defaulted')
            this.showLoading = !this.showLoading
            break
        }
      } catch (err) {
        this.showLoading = !this.showLoading
        //console.log(err)
      }
    },
    remainingChars(text) {
      let result = calcChars(text)
      return result
    },
    closeModal() {
      this.$emit('closeModal')
    },

    handleSection(section) {
      this.section = section
    }
  }
}
</script>

<style scoped>
p {
  margin-bottom: 0.25rem;
}
h2 {
  color: var(--text-color);
  padding: 0.5rem;
}
h4,
h3 {
  margin-top: 0.5em;
}
h1,
h2,
h3 {
  margin-bottom: 0.5em;
}
h5,
h3 {
  font-family: 'SulphurPoint';
}
.flexbox-container {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.form-title {
  position: inherit;
  background-color: var(--main-color);
  width: 100%;
  height: 100%;
  padding-inline: 1rem;
}
.content {
  padding-inline: 1.5rem;
  padding-top: 0;
  padding-bottom: 1rem;
  border: none;
}
input,
textarea,
option,
select {
  width: 100%;
  outline: none;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
input,
textarea {
  padding: 10px 20px;
}

.creation-form {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1rem;
}
.err {
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  justify-content: left;
  font-size: 0.8rem;
  color: salmon;
}
textarea {
  resize: none;
}
.btn-group {
  display: flex;
  justify-content: flex-end;
}
.confirmar,
.cancelbtn {
  border: none;
  background-color: var(--text-color);
  font-size: 0.9vmax;
  padding-block: 0.5rem !important;
  padding: 1em;
  text-align: center;
  border-radius: 0.25rem;
}
.confirmar {
  background-color: var(--main-color);
  color: var(--text-color);
  margin-right: 1rem;
}
.confirmar:hover {
  background-color: var(--transp-main-color);
}
.cancelbtn:hover {
  background-color: #0000001e;
}
.sec-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
}
.sec-title > h3 {
  border-left: 5px solid var(--main-color);
}
.showLoading {
  position: relative;
  width: 100%;
  height: 13.33vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-select {
  cursor: pointer;
}
/* ANIMATIONS */
/* .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
} */
</style>
