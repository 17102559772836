<template>
  <main class="notification-wrapper" @click="closeMe">
    <section
      class="notification"
      :class="{ success: success, warn: warn, error: error }"
      ref="notif"
      title="Clique para fechar"
    >
      <p>{{ notificationMessage }}</p>
    </section>
  </main>
</template>

<script>
export default {
  props: ['notificationMessage', 'notificationStatus'],
  data() {
    return {
      success: false,
      warn: false,
      error: false,
      timer: null
    }
  },
  created() {
    switch (this.notificationStatus) {
      case 'success':
        this.success = true
        break
      case 'error':
        this.error = true
        break
      case 'warn':
        this.warn = true
        break

      default:
        break
    }

    this.timer = setTimeout(() => {
      this.closeMe()
    }, '4000')
  },
  methods: {
    closeMe() {
      this.$refs.notif.classList.add('close')
      setTimeout(() => {
        this.$store.dispatch('notificationMessage', null)
        this.$store.dispatch('notificationStatus', null)
      }, 1000)
    }
  },
  beforeUnmount() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }
}
</script>

<style scoped>
.notification-wrapper {
  min-width: 160px;
  position: absolute;
  top: 80px;
  right: 0;
  overflow: hidden;
  cursor: pointer;
  z-index: 101;
}
.notification {
  position: relative;
  width: 100%;
  min-height: 60px;
  padding-right: 8px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 100;
  white-space: nowrap;
  background: var(--topnav-color);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  animation: 320ms slide ease-out;
  user-select: none;
}
p {
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  color: var(--text-color);
  font-family: 'SulphurPoint-Bold';
  animation: 1.5s ease-in fade-in;
}
.close {
  opacity: 0;
  animation: 1s ease-in slide-out;
}

.notification-wrapper > .notification > p {
  opacity: 0.8;
}

.notification-wrapper > .success {
  border-left: 8px solid rgba(129, 220, 129, 0.8);
}
.notification-wrapper > .error {
  border-left: 8px solid rgb(220, 129, 129, 0.8);
}
.notification-wrapper > .warn {
  border-left: 8px solid rgba(255, 178, 63, 0.9);
}
.notification-wrapper:hover > .notification > p {
  opacity: 1 !important;
}
.notification-wrapper:hover > .success {
  border-color: rgba(129, 220, 129, 1);
}
.notification-wrapper:hover > .error {
  border-color: rgb(220, 129, 129, 1);
}
.notification-wrapper:hover > .warn {
  border-color: rgba(255, 178, 63, 1);
}
p,
.success,
.error,
.warn {
  transition: all 200ms ease-in-out;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes slide-out {
  0% {
    opacity: 1;
  }

  100% {
    opcaity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
</style>
