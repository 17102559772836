import apiClient from '../apiClient'
import store from '../../store/store'
import { catchErr } from '../filters/dreamCatcher'

export async function get_projects() {
  try {
    const { data, status } = await apiClient.get('/projects')

    return { data, status }
  } catch (error) {
    //console.log(error)
  }
}

export default {
  async createProject(template_id, ref, project_name, descrip) {
    try {
      const rawres = await apiClient.post(
        '/projects',
        template_id,
        ref,
        project_name,
        descrip
      )
      const res = Object
      res.status = rawres.status
      res.id = rawres.data

      return res
    } catch (err) {
      return err
    }
  },
  async getProjects() {
    try {
      const res = await apiClient.get('/projects')
      //console.log('getProjects: ', res)
      store.dispatch('projectsLength', res.data.length)

      return res
    } catch (error) {
      const res = catchErr(error)
      return res
    }
  },
  async getProject(id) {
    try {
      const { data } = await apiClient.get(`/projects/${id}`)
      // //console.log(res)
      return data
    } catch (error) {
      const res = catchErr(error)
      return res
    }
  },
  async deleteProject(id) {
    try {
      const data = {
        project_id: id
      }
      const res = await apiClient.delete(`/projects/${id}`, data)

      return res.status
    } catch (error) {
      const res = catchErr(error)
      return res
    }
  },
  async isUserInProject(pid, uid) {
    try {
      const res = await apiClient.get(`/is_user_in_project/${pid}/${uid}`)

      return res
    } catch (error) {
      const res = catchErr(error)
      return res
    }
  }
}
