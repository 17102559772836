<template>
  <div class="title-banner">
    <h2>ESPECIFIQUE O SISTEMA</h2>
  </div>

  <main class="grid-container">
    <div class="grid-span-3">
      <label>
        Selecione uma especificação para
        <span class="bold">
          {{ parent_system.system.code }} - {{ parent_system.system.name }}
        </span>
      </label>
    </div>

    <section v-if="!showLoading" class="specify-wrapper grid-span-3">
      <div
        class="specify-item"
        v-for="{ id, code, name } in children_systems"
        :key="id"
        @click="stageSystem(id)"
      >
        <div class="flex-row">
          <input
            class="form-check-input chkbox"
            type="checkbox"
            :ref="`s${id}`"
          />
          <label class="form-check-label">
            {{ name }}
          </label>
        </div>
        <label class="classification">{{ code }}</label>
      </div>
    </section>
    <section v-else class="loading grid-span-3">
      <ShowLoading />
    </section>

    <section class="grid-span-3 flex-row">
      <div
        @click="handleClick"
        :class="[stagedSystems.size ? 'my-btn-primary' : 'my-btn-hidden']"
        ref="confirmar"
      >
        <h5 class="prim-btn grid-span-2">Adicionar</h5>
      </div>
      <div class="my-btn-cancel" @click="closeModal">
        <h5 class="cancel-btn grid-span-1">Cancelar</h5>
      </div>
    </section>
  </main>
</template>

<script>
import ShowLoading from '../../../ShowLoading.vue'
import { setNotification } from '../../../../services/filters/dreamCatcher'
import { mapGetters } from 'vuex'
import {
  get_children_systems,
  specify_system
} from '../../../../services/lib/systems'
export default {
  props: ['item'],
  emits: ['closeModal'],
  components: { ShowLoading },
  data() {
    return {
      parent_system: this.item,
      children_systems: null,
      stagedSystems: new Set(),
      //state management
      showLoading: true
    }
  },
  async created() {
    await this.get_children_systems()
    this.showLoading = !this.showLoading
  },
  methods: {
    async get_children_systems() {
      try {
        const formData = {
          system_parent_id: this.parent_system.system_parent_id
        }
        const { data, status } = await get_children_systems(formData)

        if (status !== 200) throw new Error('Error getting children systems')

        this.children_systems = data
      } catch (error) {
        setNotification('Ocorreu um erro...', 'error')
        //console.log(error)
      }
    },
    stageSystem(child_id) {
      const specify_item = this.$refs[`s${child_id}`]
      specify_item.checked = !specify_item.checked

      !this.stagedSystems.has(child_id)
        ? this.stagedSystems.add(child_id)
        : this.stagedSystems.delete(child_id)

      // Convert the Set back to an Array for sorting
      //console.log(this.stagedSystems)
    },
    async handleClick() {
      //update systems in store logic here

      try {
        const formData = {
          system_parent_id: this.parent_system.system_parent_id,
          project_id: this.$store.state.project.id,
          system_child_id: Array.from(this.stagedSystems).sort()
        }

        //console.log('FORM DATA : ', formData)

        const { data, status } = await specify_system(formData)
        if (status !== 200) throw new Error('Error specifying system')

        this.$store.dispatch('specifySystem', {
          children: data,
          parent_id: this.parent_system.id
        })

        setNotification('Sistema especificado com sucesso!', 'success')
        this.closeModal()
      } catch (error) {
        setNotification('Ocorreu um erro...', 'error')
        //console.log(error)
      }
    },
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped>
.grid-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding-top: 0.5rem;
}
.grid-span-3 {
  grid-column: span 3;
  margin-inline: 1rem;
}
.grid-span-2 {
  grid-column: span 2;
  text-align: center;
  justify-content: end;
}
.grid-span-1 {
  grid-column: span 1;
  text-align: center;
}
.flex-row {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.specify-wrapper {
  border: 1px solid #ddd;
  position: relative;
  left: 0;
  top: 0;
  min-height: auto;
  max-height: 350px;
  overflow: auto;
}
.specify-item {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0.5rem;
  min-width: 500px;
  width: max(45vw, 200px);
  cursor: pointer;
  user-select: none;
}
.specify-item:hover * {
  cursor: pointer;
}
.specify-item:hover {
  background-color: var(--bg-color);
}
.specify-item input {
  margin-right: 0.5rem;
}
.classification {
  color: rgb(155, 155, 155);
  position: absolute;
  right: 0.5rem;
}

.loading {
  position: relative;
  height: 100%;
  margin-bottom: 8rem;
  top: -25%;
}

.bold {
  font-weight: bold;
}

.chkbox {
  user-select: none !important;
  pointer-events: none !important;
}

@media screen and (max-width: 799px) {
  .classification {
    visibility: hidden;
  }
}
</style>
