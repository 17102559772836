const roles = {
    'administrator': [
        'delete project', 
        'view project details', 
        'view project lifecycle details', 
        'edit project details', 
        'change project stage', 
        'add stage notes',

        'invite user', 
        'kick user', 
        'change user role', 
        'view users', 
        'view jobs', 
        'add agent', 
        'delete agent', 
        'associate user to agent', 
        'disassociate user from agent',

        'view tasks', 
        'add task', 
        'edit task details', 
        'add task note', 
        'delete task', 
        'associate user to task', 
        'edit task state', 
        'edit task deliv date',

        'view systems', 
        'add system', 
        'delete system', 
        'associate user to system', 
        'edit system state', 
        'edit system deliv date', 
        'edit lod and loi', 
        'specify system',

        'view entregables',
        'add entregable',
        'delete entregable',
        'edit entregable state',
        'edit entregable details',

        /* ---------------------------------------------------------------------------------------------- */

    ],

    'coordinator': [
        'view project details',
        'view project lifecycle details',
        'add stage notes',

        'invite user',
        'view users',
        'view jobs',
        'add agent',
        'disassociate user from agent',

        'view tasks',
        'add task', 
        'edit task details',
        'delete task',

        'view systems',
        'add system',
        'edit lod and loi',
        'specify system',

        'view entregables',
        'add entregable',
        'edit entregable state',

        /* ---------------------------------------------------------------------------------------------- */

        'kick invited user',
        'delete created agent',
        'associate user to created agent',
        'add associated and invited associated task note',
        'associate user to created task',
        'edit created or invited associated task state',
        'edit created or invited associated task deliv date',
        'delete created system',
        'associate user to created system', 
        'edit created system state', 
        'edit created system deliv date',
        'delete created entregable',
        'edit created entregable details',
        'change invited user role', 
    ],

    'integrator': [
        'view project details',
        'view project lifecycle details',

        'view users',
        'view jobs',

        'view tasks',

        'view systems',

        'view entregables',

        /* ---------------------------------------------------------------------------------------------- */

        'edit created task details',
        'add associated task note',
        'add associated task',
        'delete created task',
        'edit associated task state',
        'edit associated system state', 
    ],

    'guest': [
        'view project details',

        'view jobs',

        'view tasks',

        'view systems',

        'view entregables',
    ],
};


export function hasPermission(role, permission) {
    const permissionsArray = roles[role];

    if (permissionsArray && permissionsArray.includes(permission)) {
        return true;
    }
    return false;
}