import apiClient from '../apiClient'

export async function getUnreadNotifications($user) {
  try {
    const { data, status } = await apiClient.get(
      `/unreadNotifications/` + $user.id
    )

    if (status !== 200) return

    return { data, status }
  } catch (error) {
    //console.log(error)
    return res
  }
}

export async function getNotifications(n) {
  try {
    const { data, status } = await apiClient.get(`/getNotifications/` + n)

    if (status !== 200) return

    return { data, status }
  } catch (error) {
    //console.log(error)
    return res
  }
}

export async function markOneAsRead($nid) {
  try {
    const { data, status } = await apiClient.post(`/markAsRead/` + $nid)

    if (status !== 200) return

    return { data, status }
  } catch (error) {
    //console.log(error)
    return res
  }
}

export async function getUnreadNotificationsGeneral($uid) {
  try {
    const { data, status } = await apiClient.get(
      `/unreadNotificationsGeneral/` + $uid
    )

    if (status !== 200) return

    return { data, status }
  } catch (error) {
    //console.log(error)
    return res
  }
}

export async function getUnreadNotificationsProject($uid, $pid) {
  try {
    const { data, status } = await apiClient.get(
      `/unreadNotificationsProject/` + $uid + '/' + $pid
    )

    if (status !== 200) return

    return { data, status }
  } catch (error) {
    //console.log(error)
    return res
  }
}

export async function markAllAsRead(nots) {
  try {
    const { data, status } = await apiClient.post(`/markAllAsRead`, nots)

    if (status !== 200) return

    return { data, status }
  } catch (error) {
    //console.log(error)
    return res
  }
}
