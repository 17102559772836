<template>
  <div class="page">
    <div class="container" ref="container">
      <ResetPassForm
        @loadOut="loadOut"
        :email="getEmail"
        :token="params.token"
      />
    </div>
  </div>
</template>

<script>
import ResetPassForm from '../components/forms/auth/ResetPassForm.vue'
export default {
  components: {
    ResetPassForm
  },
  data() {
    return {
      params: this.$route.params
    }
  },
  computed: {
    getEmail() {
      return atob(this.params.email)
    }
  },
  methods: {
    loadOut() {
      this.$refs.container.classList.add('loadOut')

      setTimeout(() => {
        this.$router.push({ name: 'Login' })
      }, 600)
    }
  }
}
</script>

<style scoped>
.container {
  position: relative;
  width: clamp(260px, 45vw, 45vw);
  height: clamp(260px, 47vh, 47vh);
  min-height: 400px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  padding: 0;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-image: url('https://images.unsplash.com/photo-1591955506264-3f5a6834570a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
