<template>
  <div class="loader-container">
    <div class="lds-dual-ring">
    </div>
    <p>Esta operação pode demorar...</p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.loader-container {
  position: relative;
  text-align: center;
  z-index: 100;
  top: 45%;
  left: 45%;
  padding: 2px;
  margin-top: 20px;
}
.loader-container p{
  color: #555;
  font-size: 16px;
  font-family: 'SulphurPoint-Bold';
}
.lds-dual-ring {
  display: inline-block;
  width: auto;
  height: auto;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 4rem;
  height: 4rem;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: var(--sec-color) transparent var(--main-color) transparent;
  animation: lds-dual-ring 0.75s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>