import store from '../../store/store'

export function catchErr(err) {
  const errPosition = err.message.length - 3
  const statusCode = err.message.slice(errPosition)

  return statusCode
}

export const filterById = (valuesArray, objectId) => {
  let valor
  valuesArray.forEach((item) => {
    if (objectId === item.id) {
      valor = item
    }
  })

  return valor
}

export const setNotification = (message, statusCode) => {
  if (store.state.notificationMessage) {
    store.dispatch('notificationMessage', null)
    store.dispatch('notificationStatus', null)
    store.dispatch('notificationMessage', message)
    store.dispatch('notificationStatus', statusCode)
  } else {
    store.dispatch('notificationMessage', message)
    store.dispatch('notificationStatus', statusCode)
  }
}

export const calcChars = (text) => {
  return text ? 255 - text.length : 255
}
