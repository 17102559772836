<template>
  <main class="apps-nav">
    <section class="app-group">
      <router-link
        :to="{ name: 'Dashboard' }"
        class="app-item router"
        :class="{ select: gestao }"
      >
        <h5>Gestão do Empreendimento</h5>
      </router-link>
      <!-- ### -->
      <router-link
        :to="{ name: 'Visualizador' }"
        class="app-item router"
        :class="{ ifc: ifc }"
      >
        <h5>BIMMSight</h5>
      </router-link>
      <!-- ### -->
      <router-link
        :to="{ name: 'PronicWrapper' }"
        class="app-item router"
        :class="{ pronic: pronic }"
      >
        <h5>ProNIC 2.0</h5>
      </router-link>

      <router-link
        :to="{}"
        class="app-item router"
        @click="redirect('https://cf98-37-189-88-154.ngrok-free.app/')"
      >
        <h5>idBIM 4.0</h5>
      </router-link>

      <router-link
        :to="{}"
        class="app-item router"
        @click="selectme('under-dev')"
      >
        <h5>PointCloud4BIM</h5>
      </router-link>

      <router-link
        :to="{}"
        class="app-item router"
        @click="redirect('https://pdts.pt/')"
      >
        <h5>pdts.pt</h5>
      </router-link>

      <router-link
        :to="{}"
        class="app-item router"
        @click="selectme('under-dev')"
      >
        <h5><i class="fas fa-plus"></i></h5>
      </router-link>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      gestao: true,
      ifc: false,
      pronic: false,
      baseCusto: false,
      store: false
    }
  },
  methods: {
    selectme(nome) {
      switch (nome) {
        case 'under-dev':
          alert('A ser implementado...')
          break
        default:
          break
      }
    },
    redirect(url) {
      window.open(url, '_blank')
    }
  },
  watch: {
    $route(to) {
      switch (to.path) {
        case '/dashboard':
        case '/detalhes':
        case '/agentes':
        case '/tarefas':
        case '/sistemas':
          this.gestao = true
          this.ifc = false
          this.pronic = false
          break
        case '/visualizador':
          this.gestao = false
          this.ifc = true
          this.pronic = false
          break
        case '/pronic':
          this.gestao = false
          this.ifc = false
          this.pronic = true
          break
        default:
          break
      }
    }
  }
}
</script>

<style scoped>
.app-group {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1px;
  box-shadow: -1.25px 0 0 rgba(0, 0, 0, 0.05);
}
.app-item {
  cursor: pointer;
  position: relative;
  background: var(--topnav-color);
  margin-top: 4px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms;
  /* border-right: 0.1rem solid var(--bg-color); */
}
.app-item:hover {
  background: var(--main-color);
  margin-top: 0%;
}
h5 {
  text-align: center;
  padding-top: 0.4rem;
  padding-inline: 1rem;
  color: var(--text-color);
  display: flex;
  font-family: 'SulphurPoint-Bold';
}
.select,
.select:hover,
.ifc,
.ifc:hover {
  background-color: var(--text-color);
  margin-top: 0;
  border: none;
  border-top: 0.25rem solid var(--main-color);
}
.ifc,
.ifc:hover {
  background-color: #222;
  transition: 1s;
}
.select h5 {
  color: black;
}
.pronic {
  background: #00085a;
}
.pronic:hover {
  background: #00085a;
}
.ifc h5 {
  color: var(--text-color);
}
</style>
