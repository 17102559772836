<template>
  <div class="page">
    <div class="container" ref="container">
      <LoginInfo @loadOut="loadOut" />
      <LoginCard @loadOut="loadOut" />
    </div>
  </div>
</template>

<script>
import LoginCard from '../components/forms/auth/LoginCard.vue'
import LoginInfo from '../components/forms/auth/LoginInfoCard.vue'
import ShowLoading from '../components/ShowLoading.vue'

export default {
  components: { LoginCard, LoginInfo, ShowLoading },
  data() {
    return {}
  },
  methods: {
    loadOut() {
      this.$refs.container.classList.add('loadOut')
    }
  }
}
</script>

<style scoped>
.container {
  position: relative;
  width: clamp(260px, 65vw, 65vw);
  height: clamp(260px, 67vh, 67vh);
  min-height: 400px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  padding: 0;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  animation: load-in 400ms ease-in-out;
  animation-fill-mode: forwards;
}
.loadOut {
  animation: load-out 400ms ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes load-in {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes load-out {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-120%);
  }
}
</style>
