import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import VueParticles from 'vue-particles'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '@fortawesome/fontawesome-free/js/all'
import './assets/main.css'
import './assets/icons.css'

createApp(App).use(router).use(store).use(VueParticles).mount('#app')
