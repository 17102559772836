<template>
  <section class="title-banner">
    <h2>EDITAR SISTEMA</h2>
  </section>
  <main class="grid-container">
    <div class="grid-span-3 info">
      <p v-if="updatedSystem.system.code <= 11">
        Defina quem é o responsável por este sistema
      </p>
      <p v-else>
        Defina o nível de informação (LOI), o nível de detalhe (LOD) e quem é o
        responsável por este sistema
      </p>
    </div>

    <section class="grid-span-3">
      <div class="entregavel">
        <h5>
          <b>
            {{ updatedSystem.system.code }}
            {{ updatedSystem.system.name }}
          </b>
        </h5>
      </div>

      <div>
        <p>Quem será responsável por este sistema?</p>
        <select
          :disabled="
            !(canAssociateUserToSystem || canAssociateUserToCreatedSystem)
          "
          @change="setJob"
          ref="agentesDpdownRef"
        >
          <option selected disabled hidden v-if="!agentes">
            {{ dpdwnLoadingString }}
          </option>
          <option selected disabled hidden v-else>
            {{ getAgent }} {{ getRelatedUser(updatedSystem.user) }}
          </option>
          <option v-for="{ id, job, user } in agentes" :key="id">
            {{ getJobTitle(job) }} {{ getRelatedUser(user) }}
          </option>
        </select>
      </div>
      <div>
        <p>Pretende adicionar uma nota?</p>
        <textarea maxlength="255" v-model="updatedSystem.notes"></textarea>
      </div>
    </section>

    <section class="grid-span-2">
      <p style="margin-top: 0">Estado:</p>
      <select
        :disabled="
          !(
            canEditSystemState ||
            canEditCreatedSystemState ||
            (canEditAssociatedSystemState &&
              updatedSystem.system_state.state == 'Em Execução')
          )
        "
        class="custom-select"
        ref="stateDpdownRef"
        @change="setState"
      >
        <option selected disabled hidden>
          {{ updatedSystem.system_state.state }}
        </option>
        <option
          v-show="!canEditAssociatedSystemState"
          class="custom-select-option"
        >
          Em Execução
        </option>
        <option class="custom-select-option">Para Verificação</option>
        <option
          v-show="!canEditAssociatedSystemState"
          class="custom-select-option"
        >
          Concluído
        </option>
      </select>
    </section>

    <section class="grid-span-1">
      <p style="margin-top: 0">Data de Entrega</p>
      <input
        :disabled="!(canEditSystemDelivDate || canEditCreatedSystemDelivDate)"
        type="date"
        class="date"
        v-model="updatedSystem.deliv_date"
      />
    </section>

    <hr class="grid-span-3" />

    <section class="grid-span-3">
      <p style="margin-top: 0">Quais os níveis de detalhe e informação?</p>
      <div class="flex-row lodloi">
        <div>
          <h5>LOD</h5>
          <select
            :disabled="!canEditLodandLoi"
            class="dpdown"
            ref="lodDpdown"
            v-model="updatedSystem.lod_id"
          >
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div>
          <h5>LOI</h5>
          <select
            :disabled="!canEditLodandLoi"
            class="dpdown"
            ref="loiDpdown"
            v-model="updatedSystem.loi_id"
          >
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
          </select>
        </div>
      </div>
    </section>

    <section class="grid-span-3">
      <div class="btn-group">
        <div
          :class="[formEdited ? 'my-btn-primary' : 'my-btn-hidden']"
          @click="handleClick"
        >
          <h5 class="prim-btn grid-span-2">Guardar</h5>
        </div>
        <div class="my-btn-cancel" @click="closeModal">
          <h5 class="cancel-btn grid-span-1">Cancelar</h5>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { getAllAgents } from '../../../../services/lib/agentes'
import { update_system } from '../../../../services/lib/systems'
import { setNotification } from '../../../../services/filters/dreamCatcher'
import { mapGetters } from 'vuex'
import { hasPermission } from '../../../../services/lib/rolesHelper'

export default {
  emits: ['closeModal'],
  props: ['item'],
  data() {
    return {
      updatedSystem: Object,
      getAgente: null,
      formEdited: false,
      agentes: null,
      //MISC
      dpdwnLoadingString: 'A Processar...',
      agentesDpdownRef: 'agentesDpdownRef',
      stateDpdownRef: 'stateDpdownRef'
    }
  },
  async created() {
    this.updatedSystem = JSON.parse(JSON.stringify(this.item))

    try {
      const { data } = await getAllAgents()
      this.agentes = data
      //console.log(this.agentes)
    } catch (error) {
      setNotification('Ocorreu um erro...', 'error')
      //console.log(error)
    }
  },
  mounted() {
    //console.log('EDITED DELIV: ', this.updatedSystem)
  },
  computed: {
    ...mapGetters(['user']),
    getAgent() {
      return this.updatedSystem.job?.title || 'Por Delegar'
    },
    canAssociateUserToSystem() {
      return hasPermission(this.user.role, 'associate user to system')
    },
    canAssociateUserToCreatedSystem() {
      return hasPermission(
        this.user.role,
        'associate user to created system'
      ) && this.updatedSystem.creator_id == this.user.id
        ? true
        : false
    },
    canEditSystemState() {
      return hasPermission(this.user.role, 'edit system state')
    },
    canEditCreatedSystemState() {
      return hasPermission(this.user.role, 'edit created system state') &&
        this.updatedSystem.creator_id == this.user.id
        ? true
        : false
    },
    canEditAssociatedSystemState() {
      if (
        hasPermission(this.user.role, 'edit associated system state') &&
        this.updatedSystem.user != null
      ) {
        if (this.updatedSystem.user.id == this.user.id) {
          return true
        }
      }
      return false
    },
    canEditSystemDelivDate() {
      return hasPermission(this.user.role, 'edit system deliv date')
    },
    canEditCreatedSystemDelivDate() {
      return hasPermission(this.user.role, 'edit created system deliv date') &&
        this.updatedSystem.creator_id == this.user.id
        ? true
        : false
    },
    canEditLodandLoi() {
      return hasPermission(this.user.role, 'edit lod and loi')
    }
  },
  methods: {
    async handleClick() {
      this.formEdited = false

      try {
        const formData = {
          project_id: this.$store.state.project.id,
          system_pers_id: this.updatedSystem.id,
          job_id: this.updatedSystem.job_id,
          user_id: this.updatedSystem.user_id,
          lod_id: this.updatedSystem.lod_id,
          loi_id: this.updatedSystem.loi_id,
          deliv_state: this.updatedSystem.deliv_state,
          deliv_date: this.updatedSystem.deliv_date,
          notes: this.updatedSystem.notes
        }

        const { data, status } = await update_system(formData)
        if (status !== 200) throw new Error('Error updating task')

        this.$store.dispatch('updateSystem', data[0])

        setNotification('Editado com sucesso!', 'success')
        this.$emit('closeModal')
      } catch (err) {
        //console.log(err)
        this.formEdited = true
        setNotification('Ocorreu um erro...', 'error')
      }
    },
    setState() {
      const selectedIndex = this.$refs[this.stateDpdownRef].selectedIndex
      this.updatedSystem.deliv_state = selectedIndex
      //console.log('NEW STATE ID: ', selectedIndex)
    },
    async setJob() {
      const selectedIndex = this.$refs[this.agentesDpdownRef].selectedIndex - 1

      //console.log('AGENTES SELECTED INDEX IS: ', selectedIndex)

      this.updatedSystem.job_id = this.agentes[selectedIndex].job.id
      this.updatedSystem.user_id = this.agentes[selectedIndex].user?.id || null

      //console.log('JOB ID: ', this.updatedSystem.job_id)
      //console.log('USER ID: ', this.updatedSystem.user_id)
    },
    getJobTitle(job) {
      if (!job) return ''
      return `${job.title}`
    },
    getRelatedUser(user) {
      if (!user) return ''
      return `- ${user.name}`
    },
    closeModal() {
      this.$emit('closeModal')
    }
  },

  watch: {
    updatedSystem: {
      handler(newData) {
        if (JSON.stringify(newData) !== JSON.stringify(this.item)) {
          //console.log(newData)
          this.formEdited = true
        } else {
          this.formEdited = false
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  position: relative;
  margin-inline: 1rem;
}

.grid-span-3 {
  grid-column: span 3;
}

.grid-span-2 {
  grid-column: span 2;
}

.grid-span-1 {
  grid-column: span 1;
}

.flex-row {
  display: flex;
  gap: 1rem;
}

.btn-group {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.info {
  border-bottom: 1px solid #ddd;
}

.info p {
  margin-block: 0.5rem;
}

.entregavel {
  text-align: center;
  background: var(--bg-color);
  position: relative;
  left: -1rem;
  width: calc(100% + 2rem);
}

.entregavel h5 {
  padding: 1rem;
}

select {
  width: 100%;
  border: 1px solid #ddd;
  cursor: pointer;
}

textarea {
  resize: none;
  padding: 10px 15px;
  width: 100%;
  height: 5rem;
  border: 1px solid #ddd;
}

p {
  margin-bottom: 0.25rem;
  margin-top: 1.5rem;
}

.lodloi {
  margin-top: 1.25rem;
}

.lodloi h5 {
  font-family: 'SulphurPoint-Bold';
}

.dpdown {
  width: 4rem;
}

input[type='date'] {
  width: 100%;
  border: 1px solid #ddd;
  padding: 0.5rem;
}

::-webkit-calendar-picker-indicator {
  opacity: 1 !important;
  position: absolute !important;
  left: 82.5% !important;
  cursor: pointer;
}

textarea:focus,
select:focus {
  outline: 1px solid var(--bg-color) !important;
}

hr {
  border-color: #ccc;
}
</style>
