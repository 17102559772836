import apiClient from '../apiClient'
import store from '../../store/store'

export async function show(stages) {
  try {
    const { id } = store.state.project
    const { id: uid } = store.state.user

    const endpoint = `/exportA/${id}/${uid}/${stages}`
    const res = await apiClient.get(endpoint, { responseType: 'blob' })

    openPDF(res)
    return res
  } catch (error) {
    //console.log(error);
  }
}

export async function details(stages) {
  try {
    const { id } = store.state.project
    const { id: uid } = store.state.user

    const endpoint = `/exportD/${id}/${uid}/${stages}`
    const res = await apiClient.get(endpoint, { responseType: 'blob' })

    openPDF(res)

    return res
  } catch (error) {
    //console.log(error);
  }
}

export async function detailsAgents(stages) {
  try {
    const { id } = store.state.project
    const { id: uid } = store.state.user

    const endpoint = `/exportDP/${id}/${uid}/${stages}`
    const res = await apiClient.get(endpoint, { responseType: 'blob' })

    openPDF(res)

    return res
  } catch (error) {
    //console.log(error);
  }
}

export async function state(stages) {
  try {
    const { id } = store.state.project
    const { id: uid } = store.state.user

    const endpoint = `/exportS/${id}/${uid}/${stages}`
    const res = await apiClient.get(endpoint, { responseType: 'blob' })

    openPDF(res)

    return res
  } catch (error) {
    //console.log(error);
  }
}

export async function json(id, stages) {
  try {
    const { id: uid } = store.state.user

    const endpoint = `/exportJson/${id}/${uid}/${stages}`
    const res = await apiClient.get(endpoint)
    const jsonString = JSON.stringify(res.data, null, 2)

    return jsonString
  } catch (error) {
    //console.log(error);
  }
}

export function saveJsonToFile(jsonContent, fileName) {
  const jsonData = JSON.parse(jsonContent)
  const blob = new Blob([JSON.stringify(jsonData, null, 2)], {
    type: 'application/json'
  })
  const url = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  link.click()

  URL.revokeObjectURL(url)
}

export function openPDF(res) {
  // Salvar o PDF como um arquivo no navegador
  const blob = new Blob([res.data], { type: 'application/pdf' })

  // Criar uma URL a partir do blob do PDF
  const pdfURL = URL.createObjectURL(blob)

  // Abrir a URL em uma nova janela ou guia
  window.open(pdfURL, 'reportProject')
}
