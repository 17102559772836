<template>
  <main class="profile-content">
    <div class="container py-4">
      <div class="row" style="margin-bottom: 1.5rem">
        <div class="title-wrapper">
          <h1 class="content-title">Documentação do Formato JSON do Projeto</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-7">
          <div class="card">
            <br />
            <h2>Campos Obrigatórios</h2>
            <div class="digi-card">
              <table class="text">
                <thead>
                  <tr>
                    <th><p>Campo</p></th>
                    <th><p>Tipo</p></th>
                    <th><p>Descrição</p></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="field"><p>reference</p></td>
                    <td><p>string</p></td>
                    <td><p>Referência do projeto</p></td>
                  </tr>
                  <tr>
                    <td class="field"><p>name</p></td>
                    <td><p>string</p></td>
                    <td><p>Nome do projeto</p></td>
                  </tr>
                  <tr>
                    <td class="field"><p>description</p></td>
                    <td><p>string</p></td>
                    <td><p>Descrição do projeto</p></td>
                  </tr>
                  <tr>
                    <td class="field"><p>value</p></td>
                    <!-- title="stage_value: 0 -> 'Programa Preliminar'stage_value: 1 ->'Programa Base'
                              stage_value: 2 -> 'Estudo Prévio'
                              stage_value: 3 -> 'Anteprojeto'
                              stage_value: 4 -> 'Projeto de Execução'
                              stage_value: 5 -> 'Contratação'
                              stage_value: 6 -> 'Execução da Obra'
                              stage_value: 7 -> 'Entrega e Garantias'
                              stage_value: 8 -> 'Uso'" -->
                    <td><p>integer</p></td>
                    <td><p>Valor da fase atual</p></td>
                  </tr>
                  <tr>
                    <td class="field"><p>stage_value</p></td>
                    <!-- title="stage_value: 0 -> 'Programa Preliminar'
stage_value: 1 ->'Programa Base'
stage_value: 2 -> 'Estudo Prévio'
stage_value: 3 -> 'Anteprojeto'
stage_value: 4 -> 'Projeto de Execução'
stage_value: 5 -> 'Contratação'
stage_value: 6 -> 'Execução da Obra'
stage_value: 7 -> 'Entrega e Garantias'
stage_value: 8 -> 'Uso'" -->
                    <td><p>integer</p></td>
                    <td><p>Valor da fase</p></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <hr />
            <h2>Campos Opcionais</h2>
            <div class="digi-card">
              <table class="text">
                <thead>
                  <tr>
                    <th><p>Campo</p></th>
                    <th><p>Tipo</p></th>
                    <th><p>Descrição</p></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="field"><p>location</p></td>
                    <td><p>string</p></td>
                    <td><p>Localização do projeto</p></td>
                  </tr>
                  <tr>
                    <td class="field"><p>unit_life_cycle_id</p></td>
                    <!-- title="unit_life_cycle_id: 1 -> 'indefinido'
                    unit_life_cycle_id: 2 -> 'anos' unit_life_cycle_id: 3 ->
                    'meses'" -->
                    <td><p>integer</p></td>
                    <td><p>Unidade do ciclo de vida</p></td>
                  </tr>
                  <tr>
                    <td class="field"><p>value_life_cycle</p></td>
                    <td><p>number</p></td>
                    <td><p>Valor do ciclo de vida</p></td>
                  </tr>
                  <tr>
                    <td class="field"><p>proc_id</p></td>
                    <!-- title="proc_id: 1 -> 'Indefinido' proc_id: 2 -> 'Tradicional
                    (Design-Bid-Build)' proc_id: 3 -> 'Projeção e Construção
                    (Single-Stage)' proc_id: 4 -> 'Projeção e Construção
                    (Two-Stage)' proc_id: 5 -> 'Gestão-Geral' proc_id: 6 ->
                    'Coordenação de Empreitada'" -->
                    <td><p>integer</p></td>
                    <td><p>Método contratual</p></td>
                  </tr>
                  <tr>
                    <td class="field"><p>type_of_works_id</p></td>
                    <!-- title="type_of_works_id: 1 -> 'Indefinido' type_of_works_id:
                    2 -> 'Construção Nova' type_of_works_id: 3 ->
                    'Demolição/Desconstrução' type_of_works_id: 4 ->
                    'Reabilitação' type_of_works_id: 5 -> 'Reconstrução'
                    type_of_works_id: 6 -> 'Alteração' type_of_works_id: 7 ->
                    'Ampliação' type_of_works_id: 8 -> 'Conservação'
                    type_of_works_id: 9 -> 'Outros tipos de intervenção'" -->
                    <td><p>integer</p></td>
                    <td><p>Tipo de intervenção</p></td>
                  </tr>
                  <tr>
                    <td class="field"><p>proj_functions_id</p></td>
                    <!-- title="proj_functions_id: 1 -> 'Indefinido'
proj_functions_id: 2 -> 'Edifício para Habitação Unifamiliar'
proj_functions_id: 3 -> 'Edifício para Habitação Multifamiliar'
proj_functions_id: 4 -> 'Edifício de Alojamento Coletivo'
proj_functions_id: 5 -> 'Edifício de Hotelaria e Similares'
proj_functions_id: 6 -> 'Edifício de Restauração e de Bebidas'
proj_functions_id: 7 -> 'Edifício da Administração Pública'
proj_functions_id: 8 -> 'Edifício de Serviços'
proj_functions_id: 9 -> 'Edifício Comercial'
proj_functions_id: 10 -> 'Edifício de Infraestruturas de Transporte'
proj_functions_id: 11 -> 'Edifício de Telecomunicação'
proj_functions_id: 12 -> 'Edifício para Fins Sócio-Culturais e Recreativos'
proj_functions_id: 13 -> 'Edifício para o Culto e Práticas Religiosas'
proj_functions_id: 14 -> 'Edifício para Fins de Ensino e Formação'
proj_functions_id: 15 -> 'Edifício de Investigação'
proj_functions_id: 16 -> 'Edifício para Fins de Saúde'
proj_functions_id: 17 -> 'Edifício de Ação Social'
proj_functions_id: 18 -> 'Recintos e Instalações Desportivas'
proj_functions_id: 19 -> 'Edifício Industrial'
proj_functions_id: 20 -> 'Edifício de Armazenagem'
proj_functions_id: 21 -> 'Edifício para Uso das Forças Armadas ou de Segurança'
proj_functions_id: 22 -> 'Edifício Prisional'
proj_functions_id: 23 -> 'Outros Edifícios Não-Residenciais'" -->
                    <td><p>integer</p></td>
                    <td><p>Função do empreendimento</p></td>
                  </tr>
                  <tr>
                    <td class="field" title="aaaa-mm-dd"><p>start_date</p></td>
                    <td><p>string</p></td>
                    <td><p>Data de início da fase</p></td>
                  </tr>
                  <tr>
                    <td class="field" title="aaaa-mm-dd"><p>end_date</p></td>
                    <td><p>string</p></td>
                    <td><p>Data de fim da fase</p></td>
                  </tr>
                  <tr>
                    <td class="field"><p>budget</p></td>
                    <td><p>number</p></td>
                    <td><p>Valor a ser gasto na fase</p></td>
                  </tr>
                  <tr>
                    <td class="field"><p>notes</p></td>
                    <td><p>string</p></td>
                    <td><p>Notas da fase</p></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="row">
            <div class="card mb-4">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div>
                      <p>Exemplo</p>
                      <pre class="json-structure">
{
  "project_details": {
    "reference": "AB 1234",
    "name": "Edifício de Escritórios",
    "description": "Descrição do Empreendimento",
    "location": "Porto",
    "unit_life_cycle_id": 2,
    "unit_life_cycle": "anos",
    "value_life_cycle": 3,
    "proc_id": 3,
    "procurement": "Projeção e Construção (Single-Stage)",
    "type_of_works_id": 8,
    "type_of_work": "Conservação",
    "proj_functions_id": 14,
    "proj_function": "Edifício para Fins de Ensino e Formação",
    "current_stage": {
      "value": 3,
      "name": "Anteprojeto",
    }
  },
  "stage_details": [
    {
      "stage_value": 0,
      "stage_name": "Programa Preliminar",
      "start_date": "2023-06-21",
      "end_date": "2023-07-21",
      "budget": 200,
      "notes": "Na fase 0 deverá ser feito ..."
    },
    {
      "stage_value": 1,
      "stage_name": "Programa Base",
      "start_date": "2023-07-22",
      "end_date": "2023-08-21",
      "budget": 3000,
      "notes": "Na fase 1 deverá ser feito ..."
    },
    ...
}
                        </pre
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
.type {
  float: right;
  font-size: 15px;
}

.json-structure {
  font-size: small;
  background-color: #e1ecf6;
  padding: 16px;
  border-radius: 4px;
  text-align: left;
  display: flex;
  justify-content: center;
}

.profile-content {
  position: relative;
  width: 83vw;
  height: 54vw;
  margin-top: 15rem;
  margin-bottom: 3rem;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.digi-card {
  align-items: baseline;
  height: min-content;
  padding: 2%;
  display: flex;
  justify-content: center;
}

.field {
  font-weight: bold;
  font-size: 15px !important;
}

.card {
  height: min-content;
}

.text {
  text-align: left;
  width: 90%;
  height: 20%;
}

h2 {
  margin-top: 5px;
  text-align: left;
  padding-left: 2rem;
  color: var(black);
  font-family: 'SulphurPoint-Bold';
}

td,
.field,
p {
  text-align: left;
  padding: 0;
  margin: 0;
}

hr {
  margin-block: 1rem;
  width: 90%;
  align-self: center;
}

.digi-card p {
  padding-left: 0.32rem !important;
}

::-webkit-scrollbar {
  display: none;
}
</style>
