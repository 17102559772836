<template>
  <Ifc />
</template>

<script>
import Ifc from '../../components/Ifc.vue'
export default {
  name: 'Visualizador',
  components: { Ifc }
}
</script>

<style scoped></style>
