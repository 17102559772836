<template>
  <main class="content-wrapper">
    <div class="title-wrapper">
      <h1 class="content-title">{{ getTitle }}</h1>
    </div>
    <section class="subtool">
      <section class="btn-container">
        <div>
          <div
            ref="agentesBtnRef"
            class="subtool-btn-menu"
            @click="toggleMenuBtn(agentesBtnRef)"
            :class="{ isActive: listAgentes }"
          >
            <h5>Agentes</h5>
          </div>
          <div
            v-if="canSeeUsers"
            ref="usersBtnRef"
            class="subtool-btn-menu"
            @click="toggleMenuBtn(usersBtnRef)"
            :class="{ isActive: listUsers }"
          >
            <h5>Participantes</h5>
          </div>
        </div>

        <div>
          <SearchBar
            :results="getSearchResults"
            @inputValueChange="getSearchFilter"
          />
          <div
            v-if="canAddAgent"
            class="subtool-btn"
            @click="setModal = 'addAgente'"
          >
            <h5>Adicionar Agente</h5>
          </div>
          <div
            v-if="canInvite"
            class="subtool-btn"
            @click="setModal = 'inviteUser'"
          >
            <h5>Convidar Participante</h5>
          </div>
        </div>
      </section>
    </section>

    <div v-if="showLoading">
      <ShowLoading />
    </div>
    <div v-else class="list-container">
      <component :is="getComponent.component" v-bind="getComponent.props" />
    </div>
  </main>

  <section v-if="setModal">
    <Modal :setModal="setModal" @closeModal="closeModal" />
  </section>
</template>

<script>
import { watchEffect } from 'vue'
import ListAgentes from '../../components/ListAgentes.vue'
import ListUsers from '../../components/ListUsers.vue'
import agentes from '../../services/lib/agentes'
import ShowLoading from '../../components/ShowLoading.vue'
import Modal from '../Modal.vue'
import SearchBar from '../../components/SearchBar.vue'
import { mapGetters } from 'vuex'
import { hasPermission } from '../../services/lib/rolesHelper'
export default {
  name: 'Dashboard',
  components: { ListAgentes, ListUsers, ShowLoading, Modal, SearchBar },
  data() {
    return {
      searchedString: '',
      setModal: null,
      debounce: null,
      agentes: [],
      agentesLength: 0,
      users: [],
      usersLength: 0,
      roles: [],
      rolesLength: 0,
      listAgentes: true,
      listUsers: false,
      //REFS
      agentesBtnRef: 'agentesBtnRef',
      usersBtnRef: 'usersBtnRef'
    }
  },
  async created() {
    if (this.$store.state.stageIndex != null) {
      try {
        await agentes.getStageAgentes()
        await agentes.getUsers()
        await agentes.getRoles()
      } catch (error) {
        //console.log(error)
      }
    }
  },
  methods: {
    closeModal(data) {
      this.setModal = null
      if (data) this.$store.dispatch('addAgente', data)
    },
    getSearchFilter(searched) {
      this.listAgentes
        ? this.filterAgentes(searched)
        : this.filterUsers(searched)
    },
    filterAgentes(searched) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchedString = searched
        //console.log('SEACHED STRING: ', this.searchedString)
      }, 350)
    },
    filterUsers(searched) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchedString = seached
      }, 350)
    },

    toggleMenuBtn(ref) {
      if (this.$refs[ref].classList.contains('isActive')) return

      this.listAgentes = !this.listAgentes
      this.listUsers = !this.listUsers
    }
  },
  computed: {
    ...mapGetters(['stageIndex', 'showLoading', 'user']),
    computedAgents() {
      return this.agentes.filter((agente) => {
        return (
          agente.job.title
            ?.toLowerCase()
            .match(this.searchedString.toLowerCase()) ||
          agente.user?.name
            ?.toLowerCase()
            .match(this.searchedString.toLowerCase()) ||
          agente.user?.email
            ?.toLowerCase()
            .match(this.searchedString.toLowerCase()) ||
          agente.user?.company_name
            ?.toLowerCase()
            .match(this.searchedString.toLowerCase())
        )
      })
    },
    computedUsers() {
      return this.users.filter((user) => {
        return user.name.toLowerCase().match(this.searchedString.toLowerCase())
      })
    },
    getTitle() {
      return this.listAgentes
        ? `AGENTES NA FASE ${this.$store.state.stageIndex}`
        : `PARTICIPANTES`
    },
    getSearchResults() {
      return this.listAgentes
        ? `${this.agentes.length} de ${this.agentesLength}`
        : `${this.users.length} de ${this.usersLength}`
    },
    getComponent() {
      return this.listAgentes
        ? { component: ListAgentes, props: { agentes: this.computedAgents } }
        : {
            component: ListUsers,
            props: { users: this.computedUsers, roles: this.roles }
          }
    },
    canInvite() {
      return hasPermission(this.user.role, 'invite user')
    },
    canAddAgent() {
      return hasPermission(this.user.role, 'add agent')
    },
    canSeeUsers() {
      return hasPermission(this.user.role, 'view users')
    }
  },
  watch: {
    '$store.state.agentes': function () {
      if (!this.$store.state.agentes) return //need this to avoid state conflict

      this.agentes = this.$store.state.agentes
      this.agentesLength = this.agentes.length

      this.$store.dispatch('showLoading', false)
    },

    'agentes.length': function () {
      if (!this.agentes) return //need this to avoid state conflict
      this.agentesLength = this.agentes.length
    },

    '$store.state.users': function () {
      if (!this.$store.state.users) return //need this to avoid state conflict
      this.users = this.$store.state.users
      this.usersLength = this.users.length

      this.$store.dispatch('showLoading', false)
    },

    '$store.state.roles': function () {
      if (!this.$store.state.roles) return //need this to avoid state conflict
      this.roles = this.$store.state.roles
      this.rolesLength = this.roles.length

      this.$store.dispatch('showLoading', false)
    }
  },
  unmounted() {
    this.$store.dispatch('cleanView', 'agentes')
  }
}
</script>

<style scoped>
.btn-container {
  margin-left: 1rem;
}

.subtool-btn-menu {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 0.5rem;
  padding-block: 0.3rem;
  background: var(--title-color);
  border-radius: 0.25rem;
  letter-spacing: 1px;
  opacity: 0.8;
  transition: all 200ms;
}

.subtool-btn-menu h5 {
  color: var(--text-color);
}

.subtool-btn-menu:hover {
  /* background-color: var(--sec-color); */
  transform: translateY(-2px);
  opacity: 0.9;
}

.isActive {
  opacity: 1;
  transition: all 200ms;
}

.isActive:hover {
  transform: translateY(0);
  opacity: 1;
}

.btn-container {
  justify-content: space-between !important;
}

.btn-container > div {
  display: flex;
  gap: 16px;
}
</style>
