<template>
  <div class="title-banner">
    <h2>ADICIONAR ENTREGÁVEL</h2>
  </div>

  <main class="add-entregable-grid">
    <div class="grid-span-1 item">
      <label>Referência:</label>
      <input
        type="text"
        placeholder="Insira a referência"
        v-model="ref"
        maxlength="10"
      />
    </div>
    <div class="grid-span-5 item">
      <label>Nome do Entregável:</label>
      <input
        type="text"
        placeholder="Insira o nome do entregável"
        v-model="name"
        maxlength="200"
      />
    </div>
  </main>

  <div class="grid-span-3 flex-row">
    <div
      ref="mainBtnRef"
      class="my-btn-primary"
      :class="[ref && name.length ? 'my-btn-primary' : 'my-btn-hidden']"
      @click="handleClick"
    >
      <h5 class="prim-btn grid-span-2">Adicionar</h5>
    </div>
    <div class="my-btn-cancel" @click="closeModal">
      <h5 class="cancel-btn grid-span-1">Cancelar</h5>
    </div>
  </div>
</template>

<script>
import { setNotification } from '../../../../services/filters/dreamCatcher'
import { addEntregavel } from '../../../../services/lib/entregs'
export default {
  name: 'AddEntregavel',
  emits: ['closeModal'],
  data() {
    return {
      ref: null,
      name: '',
      //REFS
      mainBtnRef: 'mainBtnRef'
    }
  },
  methods: {
    async handleClick() {
      this.$refs[this.mainBtnRef].classList.add('my-btn-hidden')

      const formData = {
        ref: this.ref,
        name: this.name,
        project_id: this.$store.state.project.id,
        stage_template_id: parseInt(this.$store.state.stageIndex) + 1
      }

      try {
        const { data, status } = await addEntregavel(formData)

        if (status === 409) {
          setNotification(data.message, 'warn')
          this.formEdited = true
          return
        } else if (status !== 200) throw new Error('Error updating entreg')

        this.$store.dispatch('addEntregavel', data[0])

        setNotification('Adicionado com sucesso!', 'success')
        this.closeModal()
      } catch (error) {
        //console.log(error)
        setNotification('Ocorreu um erro...', 'error')
        this.$refs[this.mainBtnRef].classList.remove('my-btn-hidden')
      }
    },
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped>
.add-entregable-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  padding: 1rem;
  max-width: 40vw;
}

.grid-span-5 {
  grid-column: span 5;
}

.grid-span-1 {
  grid-column: span 1;
}
.item {
  display: flex;
  flex-direction: column;
}
.item-row {
  display: flex;
  gap: 0.5rem;
}
.flex-row {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-inline: 1rem;
}

input[type='text'] {
  border: 1px solid lightgrey;
  border-radius: 0.25rem;
  outline: none;
}

input[type='text'] {
  padding: 0.5rem;
}

label {
  margin-bottom: 0.25rem;
}
p {
  padding: 0;
  margin: 0;
  line-height: 100%;
}
</style>
