<template>
  <main class="chart-container">
    <div class="chart-header"><h2>Entregáveis</h2></div>

    <div class="chart-wrapper">
      <apexchart
        type="pie"
        height="180%"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </main>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts
  },
  props: ['entregsSeries'],
  data() {
    return {
      series: this.getEntregsSeries(),
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie'
        },
        colors: ['#1ab7ea', '#39539E', '#26e7a6'],
        labels: this.getEntregsLabels(),
        legend: {
          position: 'bottom'
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      }
    }
  },
  methods: {
    getEntregsSeries() {
      return this.entregsSeries.map(({ data }) => data)
    },
    getEntregsLabels() {
      return this.entregsSeries.map(({ name }) => name)
    }
  }
}

// export default {
//   name: 'Dashboard'
// }
</script>
