<template>
  <div class="view-window">
    <div class="project-view" v-if="project">
      <div class="project-img pointer" ref="pimage"></div>
      <div class="project-info">
        <div class="main-info">
          <h4>{{ project.project_name }}</h4>
        </div>
        <div class="sec-info">
          <h5>REF: {{ project.ref }}</h5>
          <p
            @click="handleClick"
            :class="{ clickable: getProjectLocationState }"
          >
            <i class="fas fa-map-marker-alt"></i>
            {{ getProjectLocation }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Stages from './Stages.vue'

export default {
  components: { Stages },
  data() {
    return {}
  },
  mounted() {
    if (this.$store.state.project.project_image) {
      this.$refs.pimage.style.backgroundImage = `url(
        ${process.env.VUE_APP_BASE_URL}/storage/${this.$store.state.project.project_image}
      )`
    }
  },
  computed: {
    ...mapGetters(['project']),
    getProjectLocation() {
      return this.project.detail.location
    },
    getProjectLocationState() {
      return this.getProjectLocation === 'Localização por definir'
    }
  },
  methods: {
    handleClick() {
      if (!this.getProjectLocationState) return

      this.$router.push({ name: 'ProjectDetails' })
    }
  }
}
</script>

<style scoped>
* {
  --transp: rgba(0, 0, 0, 0.4);
}
.project-view {
  position: absolute;
  width: 97%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: hidden;
}
.project-img {
  position: absolute;
  width: 100%;
  height: 79%;
  top: 21%;
  background-image: var(--proj-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.25rem;
  /* box-shadow: var(--title-color) -1px -2.5px 5px 0px,
    var(--title-color) 1px 2.5px 5px 0px; */
  transition: 0.5s ease-out;
}
.project-info {
  position: absolute;
  width: 100%;
  height: 79%;
  top: 21%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.main-info {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  padding-inline: 8px;
  padding-block: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background: var(--transp);
  transition: 0.5s ease;
}
.sec-info {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 60%;
  width: 100%;
}

.sec-info > p {
  user-select: none;
}
h4 {
  font-family: 'SulphurPoint';
}
h5,
p {
  padding: 0.25rem;
}
h5 {
  position: absolute;
  bottom: 0;
  display: flex;
  text-align: center;
  background: var(--transp);
}
p {
  position: absolute;
  top: 25%;
  color: transparent;
  transition: 0.5s ease;
}
h4,
h5 {
  color: var(--text-color);
  transition: 1s ease;
}
/*EFFECTS*/
.project-view:hover .project-img {
  filter: blur(2.5px);
}
.project-view:hover p {
  background: var(--transp);
  color: var(--text-color);
}
.project-view:hover .main-info {
  transform: translateY(32px);
  border-radius: 0;
}

.clickable:hover {
  cursor: pointer;
  color: var(--highlight-color) !important;
}
</style>
