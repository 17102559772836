<template>
  <div class="title-banner">
    <h2>ADICIONAR SISTEMA</h2>
  </div>
  <!-- STEP 1 -->
  <section :class="currentStep === 1 ? 'current-step' : 'hidden'">
    <main class="add-system-grid">
      <div class="grid-span-6 steps">
        <p class="step-title">
          <b>Passo 1</b>
          - Procure o sistema que pretende adicionar ao seu empreendimento
        </p>
      </div>

      <div class="grid-span-6">
        <input
          type="text"
          name="searchbar"
          placeholder="Digite para procurar"
          class="searchbar form-control"
          autocomplete="off"
          v-model="searched"
          @input="search"
        />
      </div>

      <label class="grid-span-6" v-if="showLoading">
        <span style="padding: 1rem">A pesquisar...</span>
      </label>

      <section class="grid-span-6" v-if="getResultsState">
        <label for="searchbar" class="search-results-container">
          <span
            class="search-results"
            v-for="{ id, code, name, system_parent_id } in search_results"
            :key="id"
            @click="stageSystem({ id, code, name, system_parent_id })"
          >
            {{ name }}
            <span style="padding-left: 1rem">
              <b>{{ code }}</b>
            </span>
          </span>
        </label>
      </section>

      <section class="grid-span-6">
        <div class="flex-row">
          <div class="my-btn-cancel" @click="closeModal">
            <h5 class="cancel-btn grid-span-1">Cancelar</h5>
          </div>
        </div>
      </section>
    </main>
  </section>

  <!-- STEP 2 -->

  <section :class="currentStep === 2 ? 'current-step' : 'hidden'">
    <main class="step2-grid">
      <div class="grid-span-6 steps">
        <p class="step-title">
          <b>Passo 2</b>
          - Defina o nível de informação (LOI), o nível de detalhe (LOD) e quem
          é o responsável por este sistema
        </p>
      </div>

      <section class="step2-grid">
        <div class="grid-span-6">
          <h5 class="system-title" v-if="stagedSystem">
            {{ stagedSystem.name }}
          </h5>
        </div>

        <div class="step2-grid-item item">
          <p>Quem será responsável por este sistema?</p>
          <select @change="setAgent" ref="agentesDpdownRef">
            <option selected disabled hidden v-if="!agents">
              {{ dpdwnLoadingString }}
            </option>
            <option v-for="{ id, job, user } in agents" :key="id">
              {{ getJobTitle(job) }} {{ getRelatedUser(user) }}
            </option>
          </select>
        </div>

        <div class="step2-grid-item item">
          <p>Que nível de detalhe e informação é necessário?</p>
          <div class="lodloi-wrapper">
            <div class="item-row">
              <h5 class="lodloi-title">LOD</h5>
              <select class="lodloi" ref="lodDpdown" v-model="lod_id">
                <option selected disabled hidden v-if="stageIndex > 1">
                  {{ lodValue }}
                </option>
                <option selected disabled hidden v-else>2</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
            <div class="item-row">
              <h5 class="lodloi-title">LOI</h5>
              <select class="lodloi" ref="loiDpdown" v-model="loi_id">
                <option selected disabled hidden v-if="stageIndex > 1">
                  {{ loiValue }}
                </option>
                <option selected disabled hidden v-else>2</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
              </select>
            </div>
          </div>
        </div>
        <div class="step2-grid-item item">
          <div class="flex">
            <label for="notes-chkbox">Pretende adicionar uma nota?</label>

            <div
              class="toggler-wrapper"
              :class="{ 'toggler-wrapper-active': showNotes }"
              @click="toggleShowNotes"
            >
              <input
                type="checkbox"
                name="notes-chkbox"
                id="notes-chkbox"
                v-model="showNotes"
              />
            </div>
          </div>

          <textarea
            maxlength="255"
            v-model="notes"
            v-show="showNotes"
            placeholder="Escreva aqui as suas notas..."
          ></textarea>
        </div>
      </section>

      <section class="grid-span-6">
        <div class="flex-row">
          <div class="my-btn-cancel back" @click="prevStep">
            <h5 class="cancel-btn grid-span-1">
              <i class="fas fa-angle-left"></i>
              Voltar
            </h5>
          </div>
          <div
            class="my-btn-primary"
            :class="[clickedAdd ? 'my-btn-hidden' : 'my-btn-primary']"
            @click="handleClick"
          >
            <h5 class="prim-btn grid-span-4">Adicionar</h5>
          </div>

          <div class="my-btn-cancel" @click="closeModal">
            <h5 class="cancel-btn grid-span-1">Cancelar</h5>
          </div>
        </div>
      </section>
    </main>
  </section>

  <section class="steps-guide-container">
    <div class="steps-guide-1" :class="currentStep >= 1 ? 'check' : ''"></div>
    <div class="steps-guide-gap" :class="currentStep > 1 ? 'check' : ''"></div>
    <div class="steps-guide-2" :class="currentStep > 1 ? 'check' : ''"></div>
  </section>
</template>

<script>
import { setNotification } from '../../../../services/filters/dreamCatcher'
import { mapGetters } from 'vuex'
import { getAllAgents } from '../../../../services/lib/agentes'
import {
  search_system,
  add_system_child,
  add_system_parent
} from '../../../../services/lib/systems'
export default {
  emits: ['closeModal'],
  data() {
    return {
      agents: null,
      searched: '',
      search_results: null,
      stagedSystem: null,
      message: null,
      job_id: null,
      loi_id: 2,
      lod_id: 2,
      user_id: null,
      notes: '',
      showNotes: false,
      currentStep: 1,
      showLoading: false,
      clickedAdd: false,
      dpdwnLoadingString: 'A Processar...',
      //REFS
      agentesDpdownRef: 'agentesDpdownRef'
    }
  },
  async created() {
    const { data } = await getAllAgents()
    this.agents = data
    //console.log('ALL AGENTES :', this.agents)

    this.setJob()
  },
  methods: {
    getJobTitle(job) {
      if (!job) return ''
      return `${job.title}`
    },
    getRelatedUser(user) {
      if (!user) return ''
      return `- ${user.name}`
    },
    async setAgent() {
      const { value } = this.$refs[this.agentesDpdownRef]
      //console.log('VALUE: ', value)
      if (value === this.dpdwnLoadingString || !value) return

      this.setJob()
    },
    setJob() {
      const { selectedIndex } = this.$refs[this.agentesDpdownRef]
      //console.log(selectedIndex)

      this.job_id = this.agents[selectedIndex].job.id
      this.user_id = this.agents[selectedIndex].user?.id || null

      //console.log('JOB ID: ', this.job_id)
      //console.log('USER ID: ', this.user_id)
    },
    search() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        if (this.searched.length > 1) {
          this.showLoading = !this.showLoading
          this.message = null
          this.message = this.searched.trim()

          try {
            const { data, status } = await search_system(this.message)
            if (status !== 200) throw new Error('Erro ao pesquisar sistema')

            //filters the empty res
            if (!data.length) {
              setNotification('Sem resultados para a sua pesquisa.', 'warn')
              this.showLoading = !this.showLoading
              return
            }

            this.search_results = data
            this.showLoading = !this.showLoading
          } catch (error) {
            setNotification('Não foi possível encontrar o sistema', 'error')
            this.showLoading = !this.showLoading
            //console.log(error)
            return
          }
        }
      }, 600)
    },
    nextStep() {
      this.currentStep = this.currentStep + 1
    },
    prevStep() {
      this.currentStep = this.currentStep - 1
    },
    stageSystem(system) {
      this.stagedSystem = system
      //console.log(system)
      this.nextStep()
    },

    //STEP 2
    async handleClick() {
      this.stagedSystem.code.length > 11
        ? await this.add_child()
        : await this.add_parent()
    },
    async add_parent() {
      this.clickedAdd = !this.clickedAdd

      const formData = {
        project_id: this.$store.state.project.id,
        system_parent_id: this.stagedSystem.id,
        job_id: this.job_id,
        user_id: this.user_id,
        lod_id: this.lod_id,
        loi_id: this.loi_id,
        notes: this.notes
      }

      try {
        const { data, status } = await add_system_parent(formData)
        if (status !== 200) throw new Error('Erro ao adicionar sistema')

        this.$store.dispatch('addSystem', data[0])
        setNotification('Inserido com sucesso!', 'success')
        this.closeModal()
      } catch (error) {
        this.clickedAdd = !this.clickedAdd
        setNotification('Erro ao adicionar sistema', 'error')
      }
    },
    async add_child() {
      this.clickedAdd = !this.clickedAdd
      const formData = {
        project_id: this.$store.state.project.id,
        system_child_id: this.stagedSystem.id,
        job_id: this.job_id,
        user_id: this.user_id,
        lod_id: this.lod_id,
        loi_id: this.loi_id,
        notes: this.notes
      }

      //console.log(formData)

      try {
        const { data, status } = await add_system_child(formData)
        if (status !== 200) throw new Error('Erro ao adicionar sistema')

        this.$store.dispatch('addSystem', data[0])
        setNotification('Inserido com sucesso!', 'success')
        this.closeModal()
      } catch (error) {
        this.clickedAdd = !this.clickedAdd
        setNotification('Erro ao adicionar sistema', 'error')
      }
    },
    toggleShowNotes() {
      this.showNotes = !this.showNotes
    },
    //GLOBAL
    closeModal() {
      this.$emit('closeModal')
    }
  },
  computed: {
    ...mapGetters(['stageIndex']),
    getResultsState() {
      return this.search_results?.length
    },
    lodValue() {
      if (this.$store.state.stageIndex > 5) {
        return 5
      } else {
        return this.$store.state.stageIndex
      }
    },
    loiValue() {
      if (this.$store.state.stageIndex > 6) {
        return 6
      } else {
        return this.$store.state.stageIndex
      }
    }
  },

  watch: {
    searched: function () {
      if (this.searched.length < 2) {
        this.search_results = null
        this.message = ''
      }
    }
  }
}
</script>

<style scoped>
.add-system-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  position: relative;
  width: 45vw;
}
.step2-grid {
  grid-column: span 6;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  position: relative;
  width: 45vw;
}
.grid-span-6 {
  grid-column: span 6;
  margin-inline: 1rem;
}
.grid-span-5 {
  grid-column: span 5;
  margin-inline: 1rem;
  align-self: start;
}
.grid-span-4 {
  grid-column: span 4;
  margin-inline: 1rem;
  justify-self: stretch;
}
.grid-span-3 {
  grid-column: span 3;
  margin-inline: 1rem;
}
.grid-span-2 {
  grid-column: span 2;
  margin-inline: 1rem;
}
.grid-span-1 {
  grid-column: span 1;
  text-align: left;
  margin-inline: 1rem;
}
.step2-grid-item {
  grid-column: 2 / span 4;
}
.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  user-select: none;
}
.searchbar {
  padding: 0.4rem;
}
.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid var(--bg-color);
  padding: 1rem;
  gap: 0.5rem;
}
.item-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
th {
  background-color: rgb(245, 245, 245) !important;
  color: var(--title-color) !important;
}
label,
input[type='checkbox'] {
  cursor: pointer;
}
.flex-col {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.steps {
  margin-bottom: -1rem;
  height: 100%;
  margin-inline: 0;
  border-bottom: 1px solid var(--bg-color);
}
.step-title {
  padding-block: 1.5rem;
  padding-inline: 1rem;
}
.back {
  position: absolute;
  left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
select,
textarea {
  border-color: var(--bg-color);
  outline-color: var(--sec-color);
  resize: none;
}
textarea::placeholder {
  color: #bdbdbd;
}

.flex-row {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

/* principais do step 2 */
textarea {
  height: 7rem;
  padding: 0.5rem;
}
.lodloi-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 1rem;
}

.lodloi:hover {
  cursor: pointer;
}

.lodloi-title,
.system-title {
  font-family: 'SulphurPoint-Bold';
  padding-top: 0.75rem;
}

.flex-row-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}

/*conditional steps*/
.hidden {
  display: none;
}

/*EFFECTS*/
.toggler-wrapper > input {
  display: none;
}

.toggler-wrapper {
  background: var(--bg-color);
  width: 2rem;
  height: 1rem;
  border-radius: 4rem;
  display: flex;
  align-items: center;
  padding-inline: 2px;
  transition: background 200ms ease;
}
.toggler-wrapper:hover {
  cursor: pointer;
  background: #a8beca;
}
.toggler-wrapper::before {
  content: '';
  position: absolute;
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  border-radius: 50%;
  background: white;
  animation: toggler-animate 200ms ease-in-out forwards;
  transition: transform 200ms ease-in-out;
}
.toggler-wrapper-active {
  background: var(--highlight-color);
}
.toggler-wrapper-active:hover {
  background: var(--highlight-color);
  filter: brightness(1.2);
}
.toggler-wrapper-active::before {
  transform: translateX(1rem);
  animation: toggler-animate 200ms ease-in-out forwards;
}
.steps-guide-container {
  position: relative;
  left: 35%;
  width: 25%;
  height: 1rem;
  justify-self: center;
  display: flex;
  justify-content: center;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}
.steps-guide-1,
.steps-guide-2 {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  z-index: 1;
  transition: 1s ease;
  background: var(--bg-color);
}
.steps-guide-gap {
  background: var(--bg-color);
  position: relative;
  width: 0.5rem;
  height: 30%;
  top: 35%;
  z-index: 0;
  transition: 1s ease;
}
.check {
  box-shadow: inset 100px 0 0 0 var(--highlight-color);
  transition: 3s ease;
}
.search-results-container {
  outline: 1px solid var(--bg-color);
  height: auto;
  padding: 0.5rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 40vh;
  overflow: auto;
}

.search-results {
  padding: 0.5rem;
  user-select: none;
  display: flex;
  justify-content: space-between;
}
.search-results:hover {
  background: var(--bg-color);
}

textarea:focus,
select:focus {
  outline: 1px solid var(--bg-color) !important;
}

select:hover {
  cursor: pointer;
}

p {
  margin-block: 0;
  padding-block: 0;
}

@keyframes toggler-animate {
  from {
    height: 8px;
  }

  to {
    height: calc(1rem - 4px);
  }
}
</style>
