<template>
  <main class="content-wrapper">
    <img
      src="https://cdn.discordapp.com/attachments/928228506939297852/1110204701418471424/ProNIC2.0_Captura_de_ecra_2023-05-22_145151-2.png"
      alt="ProNIC 2.0"
    />
  </main>
</template>

<script>
export default {
  name: 'PronicWrapper'
}
</script>

<style scoped>
.content-wrapper {
  padding: 0;
  display: flex;
  align-items: flex-start;
}
.content-wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top;
}
</style>
