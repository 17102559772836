<template>
  <main class="list">
    <table class="table">
      <thead class="c-thead">
        <tr class="table-primary pointer">
          <th>REF</th>
          <th @click="sortTasks('task_name')">
            Tarefa
            <div :class="sortedTasks ? 'arrow-up' : 'arrow-down'">
              <i class="fas fa-sort-down"></i>
            </div>
          </th>
          <th @click="sortTasks('title')">
            Responsável
            <div :class="sortedTasks ? 'arrow-up' : 'arrow-down'">
              <i class="fas fa-sort-down"></i>
            </div>
          </th>
          <th
            style="text-align: left; width: 150px"
            @click="sortTasks('state')"
          >
            Estado
            <div :class="sortedTasks ? 'arrow-up' : 'arrow-down'">
              <i class="fas fa-sort-down"></i>
            </div>
          </th>
          <th style="text-align: center" @click="sortTasks('task_deliv_date')">
            Data de Entrega
            <div :class="sortedTasks ? 'arrow-up' : 'arrow-down'">
              <i class="fas fa-sort-down"></i>
            </div>
          </th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody v-for="task in tasks" :key="task.id">
        <tr
          class="linha"
          :ref="`l${task.id}`"
          :class="{
            'linha-verificacao': task.state.state === 'Para Verificação',
            'linha-concluido': task.state.state === 'Concluído'
          }"
        >
          <td>{{ task.task.ref }}</td>
          <td @click="toggleNotes(task)">
            <span
              v-if="task.task_notes || task.task_requirement"
              :ref="`i${task.id}`"
              style="cursor: pointer"
            >
              <i class="fas fa-angle-up"></i>
            </span>
            {{ task.task.name }}
          </td>
          <td :title="getUser(task.task_user)">{{ getJobTitle(task.job) }}</td>
          <td style="text-align: left">{{ task.state.state }}</td>
          <td style="text-align: center">
            <span v-if="task.task_deliv_date">
              {{ task.task_deliv_date }}
            </span>
            <span v-else style="text-align: center">-</span>
          </td>
          <td>
            <div
              v-if="
                canEditTaskDetails ||
                (canEditCreatedTask && task.creator_id == userId)
              "
              @click="updateTask(task)"
            >
              <i class="far fa-edit" title="Editar"></i>
            </div>
          </td>
          <td>
            <div
              v-if="
                canDeleteTask ||
                (canDeleteCreatedTask && task.creator_id == userId)
              "
              @click="deleteTask(task)"
            >
              <i class="fas fa-trash" title="Apagar"></i>
            </div>
          </td>
        </tr>
        <tr>
          <td
            colspan="7"
            class="notes-container"
            :class="{
              'linha-verificacao': task.state.state === 'Para Verificação',
              'linha-concluido': task.state.state === 'Concluído'
            }"
            v-if="showNotesIndex === task.id"
          >
            <!-- <label style="position: absolute; left: 9%; z-index: 2">Notas</label> -->
            <textarea
              class="notes"
              :class="{
                alt:
                  task.state.state === 'Para Verificação' ||
                  task.state.state === 'Concluído'
              }"
              v-if="showNotesIndex === task.id && task.task_notes"
              :value="'Notas:\n' + task.task_notes"
            ></textarea>
            <textarea
              class="notes"
              :class="{
                alt:
                  task.state.state === 'Para Verificação' ||
                  task.state.state === 'Concluído'
              }"
              v-else
              placeholder="Pressione o ícone de EDITAR para adicionar uma nota"
            ></textarea>
            <textarea
              class="requirements"
              :class="{
                alt:
                  task.state.state === 'Para Verificação' ||
                  task.state.state === 'Concluído'
              }"
              v-if="showNotesIndex === task.id && task.task_requirement"
              :value="
                'Requisitos de Informação da Entidade Contratante:\n' +
                task.task_requirement
              "
            ></textarea>
            <textarea
              class="requirements"
              :class="{
                alt:
                  task.state.state === 'Para Verificação' ||
                  task.state.state === 'Concluído'
              }"
              v-else
              placeholder="Pressione o ícone de EDITAR para adicionar um requisito de informação"
            ></textarea>
          </td>
        </tr>
      </tbody>
    </table>
  </main>

  <section v-if="setModal">
    <Modal
      :setModal="setModal"
      :message_title="message_title"
      :message_body="message_body"
      :scope="scope"
      :item="item"
      @closeModal="closeModal"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '../views/Modal.vue'
import { hasPermission } from '../services/lib/rolesHelper'
export default {
  name: 'Dashboard',
  components: { Modal },
  props: {
    tasks: Object
  },
  data() {
    return {
      stageMsg: this.$store.state.stageIndex,
      sortedTasks: false,
      //MODAL
      setModal: null,
      item: null,
      message_title: null,
      message_body: null,
      scope: '',
      showNotesIndex: null,
      targetRowRef: null,
      targetIconRef: null
    }
  },

  methods: {
    getJobTitle(job) {
      return job?.title || 'Por Delegar'
    },
    getUser(task_user) {
      return task_user?.name || 'Sem Utilizador Associado'
    },
    sortTasks(propScoped) {
      if (this.sortedTasks) {
        this.tasks.sort((a, b) => (a[propScoped] < b[propScoped] ? 1 : -1))
        this.sortedTasks = false
      } else {
        this.tasks.sort((a, b) => (a[propScoped] > b[propScoped] ? 1 : -1))
        this.sortedTasks = true
      }
    },
    updateTask(task) {
      this.item = task
      this.setModal = 'updateTask'
    },
    deleteTask(task) {
      //console.log('TASK: ', task)
      this.scope = 'danger'
      this.message_title = 'APAGAR TAREFA'
      this.message_body = `Tem a certeza que pretende apagar a tarefa "${task.task.ref}: ${task.task.name}" ?`
      this.item = task
      this.setModal = 'setConfirmChanges'
    },
    closeModal() {
      this.setModal = null
      this.scope = null
      this.task = null
    },
    toggleNotes(task) {
      //console.log(task)
      if (
        (task.task_notes && this.showNotesIndex !== task.id) ||
        (task.task_requirement && this.showNotesIndex !== task.id)
      ) {
        if (this.targetRowRef) {
          //clean the index and add border on previous row
          this.$refs[this.targetRowRef].classList.remove('hide-border')
          this.$refs[this.targetIconRef].classList.remove('rotate-icon')
        }
        this.targetRowRef = `l${task.id}`
        this.targetIconRef = `i${task.id}`
        this.$refs[this.targetRowRef].classList.add('hide-border')
        this.$refs[this.targetIconRef].classList.add('rotate-icon')
        this.showNotesIndex = task.id
      } else if (
        (task.task_notes && this.showNotesIndex === task.id) ||
        (task.task_requirement && this.showNotesIndex === task.id)
      ) {
        this.targetRowRef = `l${task.id}`
        this.targetIconRef = `i${task.id}`
        this.$refs[this.targetRowRef].classList.remove('hide-border')
        this.$refs[this.targetIconRef].classList.remove('rotate-icon')
        this.showNotesIndex = null
      } else return
    }
  },
  computed: {
    ...mapGetters(['user', 'stageIndex']),
    canEditTaskDetails() {
      return hasPermission(this.user.role, 'edit task details')
    },
    canEditCreatedTask() {
      return hasPermission(this.user.role, 'edit created task details')
    },
    canDeleteTask() {
      return hasPermission(this.user.role, 'delete task')
    },
    canDeleteCreatedTask() {
      return hasPermission(this.user.role, 'delete created task')
    },
    userId() {
      return this.user.id
    }
  }
}
</script>

<style scoped>
select,
option {
  font-size: 0.8rem !important;
}

.notes-container {
  position: relative;
  height: max(5.5rem, 60px);
  padding: 0;
  margin: 0;
  box-shadow: var(--main-color) 0px 10px 10px -10px;
  isolation: isolate;
  /* border-bottom: 1px solid #ccc !important; */
  text-align: center;
}

.notes {
  position: relative;
  z-index: 1;
  width: 40%;
  padding-block: 4px;
  padding-inline: 8px;
  height: max(4.5rem, 50px);
  border-image: linear-gradient(
      to right,
      rgba(0, 140, 255, 0.4),
      rgba(0, 49, 139, 0.4)
    )
    1;
  /* background: rgb(233, 251, 255) !important;
  background: linear-gradient(
    90deg,
    rgba(233, 251, 255, 1) 29%,
    rgba(223, 240, 255, 1) 100%
  ) !important; */
  resize: none !important;
  user-select: none;
  pointer-events: none;
  margin-right: 8px;
}

.requirements {
  position: relative;
  z-index: 1;
  width: 40%;
  padding-block: 4px;
  padding-inline: 8px;
  height: max(4.5rem, 50px);
  border-image: linear-gradient(
      to right,
      rgba(0, 49, 139, 0.4),
      rgba(0, 140, 255, 0.4)
    )
    1;
  /* background: rgb(223, 240, 255);
  background: linear-gradient(
    90deg,
    rgba(223, 240, 255, 1) 15%,
    rgba(233, 251, 255, 1) 100%
  ); */
  resize: none;
  user-select: none;
  pointer-events: none;
  margin-left: 8px;
}

.hide-border {
  border-bottom: transparent;
}

.rotate-icon {
  display: inline-block;
  transform: rotate(180deg);
}

/* EFFECTS */

.linha:hover + tr {
  background: var(--bg-color);
}

.alt {
  background: rgb(255, 255, 255, 0.5);
}

th {
  z-index: 1000;
}
</style>
