<template>
  <div class="tool">
    <div class="new-menu">
      <div class="new-menu-container">
        <div
          v-for="{ label, iconClass, isActive, path } in menuItems"
          :key="label"
          :class="{ active: isActive }"
          @click="toggleActive(path)"
        >
          <router-link :to="{ path: path }" class="menu-item">
            <i :class="iconClass"></i>
            <span>{{ label }}</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="version">
      <p>V {{ version }}</p>
      <p>
        <a href="mailto:digi4construction@bimms.net">
          digi4construction@bimms.net
        </a>
      </p>
      <div style="display: flex; margin: 0; padding: 0">
        <p>by BIMMS</p>
      </div>
    </div>

    <div class="particles">
      <vue-particles
        color="#eee"
        :particleOpacity="0.7"
        :particlesNumber="120"
        shapeType="circle"
        :particleSize="3"
        linesColor="#00ccff"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.8"
        :linesDistance="150"
        :moveSpeed="0.5"
        :hoverEffect="false"
        hoverMode="repulse"
        :clickEffect="false"
        clickMode="push"
      ></vue-particles>
    </div>
  </div>
</template>

<script>
import { version } from '../../../package'

export default {
  data() {
    return {
      menuItems: [
        {
          label: 'Dashboard',
          iconClass: 'icon-home',
          isActive: false,
          path: '/dashboard'
        },
        {
          label: 'Detalhes',
          iconClass: 'icon-list',
          isActive: false,
          path: '/detalhes'
        },
        {
          label: 'Agentes',
          iconClass: 'icon-users',
          isActive: false,
          path: '/agentes'
        },
        {
          label: 'Tarefas',
          iconClass: 'icon-edit',
          isActive: false,
          path: '/tarefas'
        },
        {
          label: 'Sistemas',
          iconClass: 'icon-tree',
          isActive: false,
          path: '/sistemas'
        },
        {
          label: 'Entregáveis',
          iconClass: 'icon-download',
          isActive: false,
          path: '/entregaveis'
        }
      ]
    }
  },
  methods: {
    toggleActive(path) {
      const activeItem = this.menuItems.find(({ isActive }) => isActive) // returns undefined if no item is active
      if (activeItem) activeItem.isActive = !activeItem.isActive

      const newItem = this.menuItems.find(
        ({ path: itemPath }) => itemPath === path
      )
      if (!newItem) return

      newItem.isActive = !newItem.isActive
    }
  },
  computed: {
    version() {
      return version
    }
  },
  watch: {
    $route(to) {
      this.toggleActive(to.fullPath)
    }
  }
}
</script>

<style scoped>
* {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.new-menu {
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 75%;
  display: flex;
}

.new-menu-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  gap: 0.5rem;
  flex: 1;
  background: linear-gradient(
    180deg,
    rgba(23, 37, 42, 1) 0%,
    rgba(255, 255, 255, 0) 32%
  );
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  min-height: 50px;
  padding-inline: 1rem;
  padding-block: 16px;
  font-family: 'SulphurPoint-Bold';
  color: var(--text-color);
  text-decoration: none;
  overflow: hidden;
  transition: background 400ms ease, letter-spacing 200ms;
  /* background: #2a424ad0; */
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
}

.menu-item:hover {
  background: #20333dc5;
  border-right: var(--highlight-color) solid 4px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.menu-item > span {
  padding-top: 4px;
}

.version {
  position: absolute;
  z-index: 1;
  bottom: 0%;
  width: 95%;
  line-height: 1;
  height: auto;
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  justify-content: flex-end;
  color: var(--text-color);
  overflow: auto;
  /* background: #32363f; */
  background: rgba(255, 255, 255, 0.1);
  color: rgb(196, 196, 196);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-top: 8px;
}

.version a {
  text-decoration: none;
  color: var(--text-color);
  transition: ease 500ms;
}

.version a:hover {
  color: var(--highlight-color);
}

.version p,
.version h5 {
  margin: 0;
  padding: 0 !important;
}

.temp-logo {
  color: var(--text-color);
}

.active {
  border-right: var(--highlight-color) solid 4px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  transition: background ease 650ms;
}

.active > .menu-item {
  color: var(--highlight-color);
  background: #433e4eb7;
  letter-spacing: 1px;
}

.active:hover {
  border-right: none;
}

.menu-item:hover i {
  background: var(--highlight-color);
}

.active > .menu-item i {
  background: var(--highlight-color) !important;
}

.particles {
  height: 100%;
  position: relative;
}
.particles > #particles-js {
  height: 100%;
}
</style>
