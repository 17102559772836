<template>
  <div class="form-title title-banner">
    <h2>IMPORTAR EMPREENDIMENTO</h2>
  </div>
  <section v-if="showLoading" class="showLoading">
    <ShowLoading style="position: relative; left: 0; top: 0" />
  </section>
  <section v-else class="creation-form">
    <div>
      <h5 class="pointer">
        <span><i class="fas fa-chevron-right"></i></span>
        Inserir ficheiro Json
      </h5>
      <div class="grid-span-4">
        <div class="flex-item">
          <br />
          <input ref="fileInput" type="file" class="form-control" />
        </div>
      </div>
      <br />
      <h5 class="pointer" style="font-size: small; text-decoration: underline">
        <router-link
          :to="{ name: 'DocumentationPage' }"
          class="nav-link active"
        >
          Informações para importar empreendimento
        </router-link>
      </h5>
      <hr />
      <div class="btn-group">
        <button class="confirmar" @click="continueHandler">Continuar</button>
        <span class="cancelbtn" @click="closeModal">Cancelar</span>
      </div>
    </div>
  </section>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import ShowLoading from '../../ShowLoadingText.vue'
import projects from '../../../services/lib/projects'
import { getStages } from '../../../services/lib/stages'
import { setNotification } from '../../../services/filters/dreamCatcher'
import { updateProjectDetails } from '../../../services/lib/project-details/generalDetails'
import {
  updateStage,
  importStageDetails
} from '../../../services/lib/project-details/stageDetails'

export default {
  components: { Form, Field, ErrorMessage, ShowLoading },
  emits: ['closeModal'],
  data() {
    return {
      showLoading: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    x_function() {
      this.x = !this.x
    },
    handleSection(currentSection) {
      currentSection.isChecked = !currentSection.isChecked
    },
    async continueHandler() {
      const file = this.$refs.fileInput.files[0]
      const reader = new FileReader()

      reader.onload = async (event) => {
        try {
          this.showLoading = !this.showLoading
          const jsonContent = JSON.parse(event.target.result)
          const projectDetails = jsonContent.project_details
          const stageDetails = jsonContent.stage_details

          const projectDetailsSchema = {
            type: 'object',
            properties: {
              description: { type: 'string' },
              name: { type: 'string' },
              reference: { type: 'string' },
              location: { type: 'string' },
              value_life_cycle: { type: ['integer', 'null'] },
              unit_life_cycle_id: { type: 'integer' },
              proc_id: { type: 'integer' },
              type_of_works_id: { type: 'integer' },
              proj_functions_id: { type: 'integer' },
              current_stage: {
                type: 'object',
                properties: {
                  value: { type: 'integer' }
                },
                required: ['value']
              },
              stage_details: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    stage_number: { type: 'integer' },
                    start_date: { type: ['string', 'null'] },
                    end_date: { type: ['string', 'null'] },
                    budget: { type: ['number', 'null'] },
                    notes: { type: ['string', 'null'] }
                  },
                  required: ['stage_value']
                }
              }
            },
            required: ['description', 'name', 'reference']
          }

          // Adicionando as etapas ao esquema JSON
          projectDetailsSchema.properties.stage_details.items.enum =
            stageDetails

          const Ajv = require('ajv')
          const ajv = new Ajv()
          const validate = ajv.compile(projectDetailsSchema)

          const isValid = validate(projectDetails)

          if (!isValid) {
            const validationErrors = validate.errors.map(
              (error) => error.message
            )
            this.closeModal('closeModal')
            // console.log(
            //   'O arquivo JSON não está em conformidade com o JSON Schema:'
            // )
            //console.log(validationErrors)
            return
          }

          const data = {
            template_id: 1,
            ref: projectDetails.reference,
            project_name: projectDetails.name,
            descrip: projectDetails.description
          }

          const currentStage = {
            stage_id: projectDetails.current_stage.value + 1
          }

          const res = await projects.createProject(data)
          const allStages = []
          switch (res.status) {
            case 200:
              const dataDetails = {
                id: res.id,
                ref: projectDetails.reference,
                project_name: projectDetails.name,
                descrip: projectDetails.description,
                location: projectDetails.location,
                proj_functions_id: projectDetails.proj_functions_id,
                type_of_works_id: projectDetails.type_of_works_id,
                proc_id: projectDetails.proc_id,
                value_life_cycle: projectDetails.value_life_cycle,
                unit_life_cycle: projectDetails.unit_life_cycle_id
              }

              for (const stage of stageDetails) {
                const stageData = {
                  stage_id: stage.stage_value + 1,
                  start_stage: stage.start_date,
                  end_stage: stage.end_date,
                  stage_budget: stage.budget,
                  stage_notes: stage.notes
                }

                allStages.push(stageData)
              }

              await importStageDetails(allStages, res.id)
              await updateProjectDetails(dataDetails, res.id)
              await updateStage(res.id, currentStage.stage_id)

              const projeto = await projects.getProject(res.id)
              const stages = await getStages()

              this.$store.dispatch('stages', stages)
              this.$store.dispatch('project', projeto[0])

              this.$store.dispatch('addRoleToCurrentUser', 'administrator')
              this.showLoading = !this.showLoading
              await this.$router.push({ name: 'Dashboard' })

              setNotification('Criado com sucesso!', 'success')
              break
            default:
              //console.log('defaulted')
              this.showLoading = !this.showLoading
              break
          }
        } catch (error) {
          this.closeModal('closeModal')
          //console.log('Erro ao analisar o arquivo JSON:', error)
        }
      }
      reader.readAsText(file)
    }
  }
}
</script>

<style scoped>
.flex-item {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.grid-span-4 {
  grid-column: span 4;
}

.form-control:focus + .search-container {
  border: 1px solid rgba(0, 110, 255, 0.459);
}

.btn-group {
  display: flex;
  justify-content: flex-end;
}

.nav-link {
  transition: 100ms;
}

.confirmar,
.cancelbtn {
  border: none;
  background-color: var(--text-color);
  font-size: 0.9vmax;
  padding-block: 0.5rem !important;
  padding: 1em;
  text-align: center;
  border-radius: 0.25rem;
}
.cancelbtn:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}

.confirmar {
  background-color: var(--main-color);
  color: var(--text-color);
  margin-right: 1rem;
}

.confirmar:hover {
  filter: brightness(1.2);
}

.form-title {
  position: inherit;
  background-color: var(--main-color);
  width: 100%;
  height: 100%;
  padding-inline: 1rem;
}

.creation-form {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.showLoading {
  position: relative;
  width: 100%;
  height: 13.33vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
