import apiClient from '../apiClient'
import { catchErr } from '../filters/dreamCatcher'
import store from '../../store/store'

export async function updateTask(formData) {
  try {
    const { id: projectId } = store.state.project

    const endpoint = `update_task/${projectId}`
    const { data, status } = await apiClient.post(endpoint, formData)

    //console.log('API RES: ', data)
    return { data, status }
  } catch (err) {
    //console.log(err)
    return 'houve um erro: ' + catchErr(err)
  }
}

export async function deleteTask(task_id, task_pers_id) {
  try {
    const endpoint = `delete_task/${task_id}/${task_pers_id}`
    const { status } = await apiClient.delete(endpoint)

    return { status }
  } catch (err) {
    //console.log(err)
    return 'houve um erro: ' + catchErr(err)
  }
}

export default {
  async getTasks() {
    try {
      const projectId = store.state.project.id
      const stageId = parseInt(store.state.stageIndex) + 1

      const endpoint = `tasks_list/${projectId}/${stageId}`

      const { data, status } = await apiClient.get(endpoint)
      //console.log('TASKS: ', data)
      store.dispatch('tasks', data)
      return true
    } catch (err) {
      //console.log(err)
      return 'houve um erro: ' + catchErr(err)
    }
  },
  async addTask(formData) {
    try {
      const projectId = store.state.project.id
      const endpoint = `add_task_pers/${projectId}`

      const { data, status } = await apiClient.post(endpoint, formData)
      //console.log('added task: ', data)

      return { data, status }
    } catch (error) {
      //console.log(error)
    }
  }
}
