<template>
  <section v-if="showLoading">
    <ShowLoading />
  </section>
  <section v-else>
    <div class="title-banner">
      <h2>CONVIDAR PARTICIPANTE</h2>
    </div>
    <main class="grid-container">
      <div class="item grid-span-3">
        <label>
          Adicione um participante ao empreendimento através do email:
        </label>
        <input
          type="email"
          v-model="email"
          class="form-control"
          placeholder="Ex: exemplo@gmail.com"
          @keyup.enter="handleClick"
        />
        <label style="color: #e34242" v-if="err">
          {{ err }}
        </label>
        <label style="padding-top: 1rem; color: #666">
          <span style="color: var(--main-color)">Nota*:</span>
          O participante irá receber um convite para participar no
          empreendimento
          <span style="font-family: 'SulphurPoint-Bold'">
            "{{ project.project_name }}"
          </span>
          na caixa de entrada do email inserido.
        </label>
      </div>

      <div class="grid-span-3 flex-row">
        <div
          class="my-btn-primary"
          :class="[formEdited ? 'my-btn-primary' : 'my-btn-hidden']"
          @click="handleClick"
        >
          <h5 class="prim-btn grid-span-2">Adicionar</h5>
        </div>
        <div class="my-btn-cancel" @click="closeModal">
          <h5 class="cancel-btn grid-span-1">Cancelar</h5>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import agentes from '../../../../services/lib/agentes'
import { setNotification } from '../../../../services/filters/dreamCatcher'
import ShowLoading from '../../../ShowLoading.vue'
export default {
  emits: ['closeModal'],
  components: { ShowLoading },
  data() {
    return {
      email: '',
      formEdited: false,
      err: '',
      showLoading: false
    }
  },
  methods: {
    async handleClick() {
      this.showLoading = true
      const data = {
        project_id: this.project.id,
        invited_email: this.email
      }

      const res = await agentes.invite(data)

      if (res.status === 200) {
        this.showLoading = false
        this.closeModal()
        setNotification('Convite enviado com sucesso', 'success')
      } else if (res.status === 201) {
        this.err = res.data.message
        this.showLoading = false
      } else {
        setNotification('Erro', 'success')
        this.showLoading = false
      }
    },
    closeModal() {
      this.$emit('closeModal')
    }
  },
  watch: {
    email: function () {
      if (this.email.length > 0 && this.email.includes('@')) {
        this.formEdited = true
      } else {
        this.formEdited = false
      }
    }
  },
  computed: {
    ...mapGetters(['project'])
  }
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding-top: 1rem;
}

.grid-span-3 {
  grid-column: span 3;
  margin-inline: 1rem;
}

.grid-span-2 {
  grid-column: span 2;
  text-align: center;
  justify-content: end;
}

.grid-span-1 {
  grid-column: span 1;
  text-align: center;
}

.item {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

input {
  border: 1px solid lightgrey;
  border-radius: 0.25rem;
  outline: none;
  padding: 0.5rem;
}

label {
  margin-bottom: 0.25rem;
}

/*EFFECTS*/
.hidden {
  display: none;
}

.show {
  display: block;
}
</style>
