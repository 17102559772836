import apiClient from '../apiClient'
import { catchErr } from '../filters/dreamCatcher'

export async function getStages() {
  try {
    const rawres = await apiClient.get('/stages')
    const response = rawres.data
    //console.log('STAGES RES: ', response)
    return response
  } catch (err) {
    //console.log(catchErr(err))
    return 'Houve um erro: ' + catchErr(err)
  }
}
