<template>
  <main v-if="!entregs.length" class="list">
    <h1>Não foi encontrado nenhum entregável.</h1>
  </main>
  <main v-else>
    <div class="list">
      <table class="table">
        <thead>
          <tr class="table-primary pointer">
            <th @click="sortEntregs('entregable.ref')">
              Referência
              <div :class="sortedEntregs ? 'arrow-up' : 'arrow-down'">
                <i class="fas fa-sort-down"></i>
              </div>
            </th>
            <th @click="sortEntregs('entregable.name')">
              Entregável
              <div :class="sortedEntregs ? 'arrow-up' : 'arrow-down'">
                <span
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <i class="fas fa-sort-down"></i>
                </span>
              </div>
            </th>
            <th
              style="text-align: center; width: 150px"
              @click="sortEntregs('entreg_deliv_date')"
            >
              Data de Entrega
              <div :class="sortedEntregs ? 'arrow-up' : 'arrow-down'">
                <i class="fas fa-sort-down"></i>
              </div>
            </th>
            <th
              style="text-align: left; width: 150px"
              @click="sortEntregs('state.state')"
              colspan="3"
            >
              Estado
              <div :class="sortedEntregs ? 'arrow-up' : 'arrow-down'">
                <i class="fas fa-sort-down"></i>
              </div>
            </th>
          </tr>
        </thead>

        <tbody v-for="(entreg, index) in entregs" :key="entreg.id">
          <tr
            class="linha"
            :class="{
              'linha-verificacao': entreg.state.state === 'Para Verificação',
              'linha-concluido': entreg.state.state === 'Concluído'
            }"
            :ref="`l${entreg.entregable.id}`"
            @click="logMe(entreg)"
          >
            <td>
              {{ entreg.entregable.ref }}
            </td>
            <td>
              {{ entreg.entregable.name }}
            </td>
            <td style="text-align: center">
              {{ getDeadline(entreg.entreg_deliv_date) }}
            </td>
            <td>
              {{ entreg.state.state }}
            </td>
            <td>
              <div
                v-if="
                  canEditEntregable ||
                  (canEditCreatedEntregable &&
                    entreg.creator_id != null &&
                    entreg.creator_id == getCurrentUserId)
                "
                @click="updateEntregavel(entreg)"
              >
                <i class="far fa-edit" title="Editar"></i>
              </div>
            </td>
            <td>
              <div
                v-if="
                  canDeleteEntregable ||
                  (canDeleteCreatedEntregable &&
                    entreg.creator_id != null &&
                    entreg.creator_id == getCurrentUserId)
                "
                @click="deleteEntreg(entreg)"
              >
                <i class="fas fa-trash" title="Apagar"></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </main>

  <section v-if="setModal">
    <Modal
      :setModal="setModal"
      :scope="scope"
      :message_title="message_title"
      :message_body="message_body"
      :item="item"
      @closeModal="closeModal"
    />
  </section>
</template>

<script>
import Modal from '../views/Modal.vue'
import { mapGetters } from 'vuex'
import { hasPermission } from '../services/lib/rolesHelper'

export default {
  name: 'Dashboard',
  components: { Modal },
  props: ['entregs'],
  data() {
    return {
      sortedEntregs: true,
      setModal: null,
      getEntreg: null,
      message_title: '',
      message_body: '',
      item: null,
      scope: '',
      targetRowRef: null,
      targetIconRef: null
    }
  },
  methods: {
    logMe(entreg) {
      //console.log(entreg)
    },
    updateEntregavel(entreg) {
      this.item = entreg
      this.setModal = 'updateEntregavel'
    },
    setEntreg(entreg, index) {
      this.getEntreg = entreg
      this.getEntreg.index = index
      this.setModal = 'specifyEntreg'
    },
    deleteEntreg(entreg) {
      this.scope = 'danger'
      this.message_title = 'APAGAR ENTREGAVEL'
      this.message_body = `Tem a certeza que pretende remover o entregável "${entreg.entregable.ref}: ${entreg.entregable.name}" ?`
      this.item = entreg
      this.setModal = 'setConfirmChanges'
    },
    getDeadline(deadline) {
      return deadline || '-'
    },
    closeModal() {
      this.setModal = null
      this.scope = null
    },
    sortEntregs(prop) {
      const [key, nestedKey] = prop.split('.')
      this.entregs.sort((a, b) => {
        const valueA = a?.[key]?.[nestedKey] ?? a?.[key]
        const valueB = b?.[key]?.[nestedKey] ?? b?.[key]
        const sortOrder = this.sortedEntregs ? -1 : 1

        return sortOrder * (valueA < valueB ? -1 : valueA > valueB ? 1 : 0)
      })
      this.sortedEntregs = !this.sortedEntregs
    }
  },
  computed: {
    ...mapGetters(['stageIndex', 'user']),
    getCurrentUserId() {
      return this.user.id
    },
    canDeleteEntregable() {
      return hasPermission(this.user.role, 'delete entregable')
    },
    canDeleteCreatedEntregable() {
      return hasPermission(this.user.role, 'delete created entregable')
    },
    canEditEntregable() {
      return hasPermission(this.user.role, 'edit entregable details')
    },
    canEditCreatedEntregable() {
      return hasPermission(this.user.role, 'edit created entregable details')
    }
  }
}
</script>

<style scoped>
select,
option {
  font-size: 0.8rem !important;
}

.date {
  pointer-events: none;
}

.hide-border {
  border-bottom: transparent;
}

.linha:hover {
  background: var(--bg-color);
}

td {
  white-space: normal;
}
</style>
