<template>
  <main class="list">
    <table class="table">
      <thead>
        <tr class="table-primary pointer">
          <th @click="sortByJob">
            Função
            <div :class="sortedAgentes ? 'arrow-up' : 'arrow-down'">
              <i class="fas fa-sort-down"></i>
            </div>
          </th>
          <th>Responsável</th>
          <th>Contacto</th>
          <th>Empresa</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody v-for="(agente, index) in agentes" :key="agente">
        <tr class="linha" @click="logMe(agente)">
          <td>{{ getAgentTitle(index) }}</td>
          <td>{{ getUser(index) }}</td>
          <td>{{ getUserContact(index) }}</td>
          <td>{{ getUserCompany(index) }}</td>
          <td>
            <div
              v-if="
                canEditAgent ||
                (canEditCreatedAgent && agente.creator_id == this.user.id)
              "
              @click="updateAgent(agente)"
            >
              <i class="far fa-edit" title="Editar"></i>
            </div>
          </td>
          <td>
            <div
              v-if="
                canDeleteAgent ||
                (canDeleteCreatedAgent && agente.creator_id == this.user.id)
              "
              @click="deleteAgente(agente)"
            >
              <i class="fas fa-trash" title="Apagar"></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </main>
  <section v-if="setModal">
    <Modal
      :setModal="setModal"
      :message_title="message_title"
      :message_body="message_body"
      :scope="scope"
      :item="item"
      @closeModal="closeModal"
    />
  </section>
</template>

<script>
import agentes from '../services/lib/agentes'
import Modal from '../views/Modal.vue'
import { mapGetters } from 'vuex'
import { hasPermission } from '../services/lib/rolesHelper'
export default {
  components: { Modal },
  props: ['agentes'],
  data() {
    return {
      sortedAgentes: false,
      //MODAL
      setModal: null,
      item: null,
      message_title: null,
      message_body: null,
      scope: ''
    }
  },
  created() {
    //console.log('AGENTES ON CREATED', this.agentes)
  },
  methods: {
    logMe(agente) {
      //console.log(agente)
    },
    sortByJob() {
      this.sortedAgentes = !this.sortedAgentes
      this.agentes.sort((a, b) => {
        if (a.job.title < b.job.title) {
          return this.sortedAgentes ? -1 : 1
        }
        if (a.job.title > b.job.title) {
          return this.sortedAgentes ? 1 : -1
        }
        return 0
      })
    },
    logme(agente) {
      //console.log(agente)
    },
    getAgentTitle(index) {
      if (!this.agentes[index].job) return 'Error loading...'

      return this.agentes[index].job.title
    },
    getUser(index) {
      if (!this.agentes[index].user) return 'Sem responsável'

      return this.agentes[index].user.name
    },
    getUserContact(index) {
      if (!this.agentes[index].user) return '-'

      return this.agentes[index].user.email
        ? this.agentes[index].user.email
        : 'Sem contacto'
    },
    getUserCompany(index) {
      if (!this.agentes[index].user) return '-'

      return this.agentes[index].user.company_name
        ? this.agentes[index].user.company_name
        : 'Sem empresa'
    },
    updateAgent(agent) {
      this.item = agent
      this.setModal = 'updateAgent'
    },

    deleteAgente(agente) {
      this.scope = 'danger'
      this.message_title = 'APAGAR AGENTE'
      this.message_body = `Tem a certeza que pretende remover o agente "${agente.job.title}" ?`
      this.item = agente
      this.setModal = 'setConfirmChanges'
    },
    closeModal() {
      this.setModal = null
      this.scope = null
      this.task = null
    }
  },
  computed: {
    ...mapGetters(['user']),
    canDeleteAgent() {
      return hasPermission(this.user.role, 'delete agent')
    },
    canDeleteCreatedAgent() {
      return hasPermission(this.user.role, 'delete created agent')
    },
    canEditAgent() {
      return hasPermission(this.user.role, 'associate user to agent')
    },
    canEditCreatedAgent() {
      return hasPermission(this.user.role, 'associate user to created agent')
    }
  }
}
</script>

<style scoped></style>
