<template>
  <main ref="bg">
    <div class="title-banner">
      <h2>{{ message_title }}</h2>
    </div>

    <section class="grid-container">
      <main class="grid-span-3 message"></main>

      <!-- BUTTONS -->
      <section class="grid-span-3 flex-row">
        <div
          :class="{ hidden: emitedEvent }"
          class="my-btn-primary"
          @click="handleClick()"
        >
          <h5 class="prim-btn grid-span-2">{{ getPrimaryClickValue }}</h5>
        </div>
        <div class="my-btn-cancel grid-span-1" @click="$emit('resetFiles')">
          <h5 class="cancel-btn">Cancelar</h5>
        </div>
      </section>
    </section>
  </main>
</template>

<script>
import { uploadFile } from '../../../services/lib/project-details/generalDetails'
import { setNotification } from '../../../services/filters/dreamCatcher'
import { update_profile_pic } from '../../../services/lib/auth'
export default {
  props: ['message_title', 'file', 'scope'],
  emits: ['closeModal', 'resetFiles'],
  data() {
    return { emitedEvent: false, showLoading: false }
  },
  computed: {
    getPrimaryClickValue() {
      return !this.emitedEvent ? 'Confirmar' : 'A Processar...'
    }
  },
  methods: {
    async handleClick() {
      switch (this.scope) {
        case 'profile':
          await this.uploadProfileImg()
          break

        default:
          await this.uploadProjectImg()
          break
      }
    },
    async uploadProjectImg() {
      const project_image = new FormData()
      project_image.append('project_image', this.file)

      const res = await uploadFile(this.$store.state.project.id, project_image)
      switch (res.status) {
        case 200:
          const newProject = JSON.parse(
            JSON.stringify(this.$store.state.project)
          )
          newProject.project_image = res.data
          this.$store.dispatch('project', newProject)
          this.$emit('closeModal')
          setNotification('Imagem alterada com sucesso!', 'success')
          break

        default:
          setNotification('Ocorreu um problema...', 'error')
          break
      }
    },
    async uploadProfileImg() {
      this.emitedEvent = true

      try {
        const avatar = new FormData()
        avatar.append('avatar', this.file)
        const { data, status } = await update_profile_pic(avatar)

        if (status !== 200) throw new Error('Ocorreu um erro...')

        this.$store.dispatch('updateUserAvatar', data)
        setNotification('Imagem alterada com sucesso!', 'success')
        this.$emit('closeModal')
      } catch (error) {
        setNotification('Ocorreu um erro...', 'error')
        //console.log(error)
        this.emitedEvent = false
      }
    }
  },

  created() {
    //console.log(this.file)
  },
  mounted() {
    const upedImage = URL.createObjectURL(this.file)
    this.$refs.bg.style.backgroundImage = `url(${upedImage})`
    this.$refs.bg.style.backgroundPosition = `center`
    this.$refs.bg.style.backgroundRepeat = `no-repeat`
    this.$refs.bg.style.backgroundSize = `cover`
  }
}
</script>

<style scoped>
.title-banner {
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  display: inline-block;
  padding-inline: 3rem;
  top: 2rem;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding-top: 0.5rem;
  background-image: none;
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  width: 40vw;
}
.grid-span-3 {
  grid-column: span 3;
  margin-inline: 1rem;
}
.grid-span-2 {
  grid-column: span 2;
}
.grid-span-1 {
  grid-column: span 1;
}
.my-btn-primary,
.my-btn-cancel {
  margin: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0;
  transition: 200ms;
  display: flex;
  justify-content: center;
}
.my-btn-primary:hover {
  background: rgba(49, 139, 79, 0.712);
}
.my-btn-cancel:hover {
  background: rgba(206, 76, 59, 0.712);
}
.my-btn-primary:hover > h5,
.my-btn-cancel:hover > h5 {
  scale: 1.1;
}
.my-btn-cancel {
  align-self: right;
}
.flex-row {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.message {
  justify-self: center;
  padding: 1rem;
  margin: 8rem;
  width: 100%;
}
.message h3 {
  color: var(--text-color);
}
.hidden {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.15);
}
.hidden:hover {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.15);
}
h5 {
  color: var(--text-color);
}
</style>
