<template>
  <section class="chart-container">
    <div class="chart-header"><h2>Planeamento Geral</h2></div>
    <apexchart
      type="area"
      height="160%"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </section>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts
  },
  props: ['overallData'],
  data() {
    return {
      series: this.getSeries(),
      chartOptions: {
        chart: {
          height: 'auto',
          type: 'area'
        },
        legend: {
          position: 'bottom'
        },

        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          logBase: 10,
          labels: {
            formatter: function (val) {
              return Math.floor(val)
            }
          }
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          }
        }
      }
    }
  },
  methods: {
    getSeries() {
      //console.log(this.overallData)
      return this.overallData || []
    }
  }
}
</script>
