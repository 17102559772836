<template>
  <div class="title-banner" :class="scope">
    <h2>{{ message_title }}</h2>
  </div>

  <section class="grid-container">
    <main class="grid-span-3 message">
      <h3>{{ message_body }}</h3>
    </main>
    <!-- BUTTONS -->
    <section class="grid-span-3 flex-row">
      <div
        :class="[{ hidden: emitedEvent }, scope]"
        :id="scope"
        class="my-btn-primary grid-span-2"
        @click="handleClick(message_title)"
      >
        <h5 class="prim-btn">Confirmar</h5>
      </div>
      <div
        class="my-btn-cancel grid-span-1"
        v-if="!emitedEvent"
        @click="closeModal"
      >
        <h5 class="cancel-btn">Cancelar</h5>
      </div>
    </section>
  </section>
</template>

<script>
import systems from '../../../services/lib/systems'
import agentes, { deleteAgente } from '../../../services/lib/agentes'
import { deleteTask } from '../../../services/lib/tasks'
import { delete_system } from '../../../services/lib/systems'
import { deleteEntregavel } from '../../../services/lib/entregs'
import project from '../../../services/lib/projects'
import { setNotification } from '../../../services/filters/dreamCatcher'
export default {
  props: ['message_title', 'message_body', 'scope', 'item'],
  emits: ['closeModal', 'updateStage', 'deleteSuccess'],
  data() {
    return {
      stage: '',
      emitedEvent: false,
      project: null
    }
  },
  methods: {
    async handleClick(message) {
      this.emitedEvent = !this.emitedEvent
      // await new Promise((resolve) => setTimeout(resolve, 400))

      switch (message) {
        case 'Prentende confirmar a transição de fase?':
          // this.emitedEvent = !this.emitedEvent
          this.$emit('updateStage')
          break
        case 'APAGAR PROJETO':
          try {
            const res = await project.deleteProject(this.item.id)
            //console.log(res)

            if (res !== 200)
              throw new Error('Erro em ConfirmChanges - deleteProject')

            this.$emit('deleteSuccess')
          } catch (error) {
            setNotification('Ocorreu um erro...', 'error')
            this.$emit('closeModal')
          }

          break
        case 'APAGAR AGENTE':
          try {
            //console.log('deleting agent', this.item)
            const { status } = await deleteAgente(
              this.item.job.id,
              this.item.id
            )

            if (status !== 200) throw new Error('erro em ConfirmChanges')

            this.$store.dispatch('deleteAgent', this.item.id)
            setNotification('Apagado com sucesso!', 'success')
            this.$emit('closeModal')
          } catch (error) {
            setNotification('Ocorreu um erro...', 'error')
            this.$emit('closeModal')
          }

          break
        case 'APAGAR TAREFA':
          try {
            //console.log('deleting task ', this.item)

            const { status } = await deleteTask(this.item.task.id, this.item.id)

            //console.log('DELETE TASK STATUS: ', status)
            // //console.log(res)
            if (status !== 200) throw new Error('erro em ConfirmChanges')

            this.$store.dispatch('deleteTask', this.item.id)

            setNotification('Apagado com sucesso!', 'success')
            this.$emit('closeModal')
          } catch (error) {
            setNotification('Ocorreu um erro...', 'error')
            this.$emit('closeModal')
          }

          break
        case 'APAGAR SISTEMA':
          try {
            const { status } = await delete_system(this.item.id)
            if (status !== 200)
              throw new Error('erro em ConfirmChanges - delete_system')

            this.$store.dispatch('deleteSystem', this.item.id)

            setNotification('Apagado com sucesso!', 'success')
            this.$emit('closeModal')
          } catch (error) {
            setNotification('Ocorreu um erro...', 'error')
            this.$emit('closeModal')
          }

          break
        case 'APAGAR ENTREGAVEL':
          try {
            const { status } = await deleteEntregavel(
              this.item.entregable.id,
              this.item.id
            )

            if (status !== 200)
              throw new Error('erro em ConfirmChanges - deleteEntregavel')

            //console.log(this.item.id)

            this.$store.dispatch('deleteEntregavel', this.item.id)

            setNotification('Apagado com sucesso!', 'success')
            this.$emit('closeModal')
          } catch (error) {
            //console.log('erro em ConfirmChanges - deleteEntregavel')
            setNotification('Ocorreu um erro...', 'error')
            this.$emit('closeModal')
          }

          break
        case 'EXPULSAR UTILIZADOR':
          try {
            const data = {
              to_kick_id: this.item.id,
              project_id: this.$store.state.project.id
            }

            const res = await agentes.kickUser(data)

            if (res.status === 200) {
              //remove no frontend
              const updateUsers = this.$store.state.users.filter(
                (user) => user.id !== this.item.id
              )

              this.$store.dispatch('users', updateUsers)

              setNotification('Expulso com sucesso!', 'success')
              this.$emit('closeModal')
            } else {
              //console.log('erro em ConfirmChanges - kickUser')
            }
          } catch (error) {}
          break
        default:
          setNotification('Ocorreu um erro...', 'error')
          //console.log('switch defaulted')
          this.$emit('closeModal')
          break
      }
    },
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding-top: 0.5rem;
}

.grid-span-3 {
  grid-column: span 3;
  margin-right: 1rem;
}

.grid-span-2 {
  grid-column: span 2;
}

.grid-span-1 {
  grid-column: span 1;
}

.my-btn-cancel {
  align-self: right;
}

.flex-row {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.message {
  /* justify-self: center; */
  padding: 1.5rem;
  /* padding-left: 15rem; */
}

.hidden {
  pointer-events: none;
  background-color: #ddd !important;
  /* background-color: rgba(0, 0, 0, 0.15); */
}

.hidden:hover {
  pointer-events: none;
  background-color: #ddd !important;
  /* background-color: rgba(0, 0, 0, 0.15); */
}

.danger {
  background: var(--topnav-color);
}

#danger:hover {
  background: rgba(177, 0, 0, 0.5);
}

#confirm:hover {
  background: rgba(0, 177, 68, 0.5);
}
</style>
