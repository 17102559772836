<template>
  <div class="login-container">
    <div class="layer">
      <h1>Login</h1>
      <LoginForm @loadOut="loadOut" />
    </div>
  </div>
</template>

<script>
import LoginForm from './LoginForm.vue'

export default {
  emits: ['loadOut'],
  components: { LoginForm },
  methods: {
    loadOut() {
      this.$emit('loadOut')
    }
  }
}
</script>

<style scoped>
.login-container {
  position: relative;
  width: 40%;
  min-width: 250px;
  background-image: url('https://images.unsplash.com/photo-1464938050520-ef2270bb8ce8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1174&q=80');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-right-radius: 1rem;
}

.layer {
  position: relative;
  height: 100%;
  background-color: rgba(35, 39, 40, 0.7);
  width: 45%;
  padding-inline: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  left: 10%;
  /* animation: load-in 350ms ease;
  animation-fill-mode: forwards; */
}
h1 {
  color: var(--text-color);
}

@keyframes load-in {
  0% {
    left: 50%;
  }
  100% {
    left: 10%;
  }
}
</style>