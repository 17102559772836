<template>
  <div class="title-banner">
    <h2>EDITAR ENTREGÁVEL</h2>
  </div>

  <main class="add-task-grid">
    <div class="grid-span-3 item">
      <label>Referência:</label>
      <input
        type="text"
        v-model="updatedEntreg.entregable.ref"
        placeholder="Defina o nome do entregável"
        minlength="1"
        maxlength="10"
      />
    </div>
    <section class="grid-span-3 item">
      <label>Estado:</label>
      <select ref="stateDpdown" @change="setState">
        <option selected disabled hidden>
          {{ updatedEntreg.state.state }}
        </option>

        <option class="custom-select-option">Em Execução</option>
        <option class="custom-select-option">Para Verificação</option>
        <option class="custom-select-option">Concluído</option>
      </select>
    </section>
    <div class="grid-span-6 item">
      <label>Nome do Entregável:</label>
      <input
        type="text"
        v-model="updatedEntreg.entregable.name"
        placeholder="Defina o nome do entregável"
        minlength="1"
        maxlength="200"
      />
    </div>
    <div class="grid-span-3 item">
      <label>Data de Entrega:</label>
      <input
        type="date"
        class="date"
        v-model="updatedEntreg.entreg_deliv_date"
      />
    </div>

    <section class="grid-span-6">
      <div class="flex-row">
        <div
          :class="[formEdited ? 'my-btn-primary' : 'my-btn-hidden']"
          @click="handleClick"
        >
          <h5 class="prim-btn grid-span-5">Guardar</h5>
        </div>
        <div class="my-btn-cancel" @click="closeModal">
          <h5 class="cancel-btn grid-span-1">Cancelar</h5>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { updateEntregavel } from '../../../../services/lib/entregs'
import { setNotification } from '../../../../services/filters/dreamCatcher'
export default {
  name: 'UpdateEntregavel',
  props: ['item'],
  emits: ['closeModal'],
  data() {
    return {
      updatedEntreg: null,
      formEdited: false,
      stateDpdown: 'stateDpdown'
    }
  },
  created() {
    //console.log('ENTREG TO UPDATE: ', this.item)

    this.updatedEntreg = JSON.parse(JSON.stringify(this.item))
  },
  methods: {
    async handleClick() {
      if (
        !this.updatedEntreg.entregable.ref.length ||
        !this.updatedEntreg.entregable.name
      )
        return setNotification('Campo obrigatório em falta', 'warn')

      this.formEdited = false

      try {
        const formData = {
          ref: this.updatedEntreg.entregable.ref,
          name: this.updatedEntreg.entregable.name,
          project_id: this.updatedEntreg.project_id,
          stage_template_id: this.updatedEntreg.stage_template_id,
          entreg_state: this.getStateID(),
          entregable_id: this.updatedEntreg.entregable.id,
          entreg_pers_id: this.updatedEntreg.id,
          entreg_deliv_date: this.updatedEntreg.entreg_deliv_date
        }

        //console.log('FORM DATA', formData)

        const { data, status } = await updateEntregavel(formData)

        if (status === 409) {
          setNotification(data.message, 'warn')
          this.formEdited = true
          return
        } else if (status !== 200) throw new Error('Error updating entreg')
        //console.log('UPDATED ENTREG FROM API: ', data[0])
        this.$store.dispatch('updateEntregavel', data[0])

        setNotification('Editado com sucesso!', 'success')
        this.$emit('closeModal')
      } catch (error) {
        this.formEdited = true
        setNotification('Ocorreu um erro...', 'error')
        //console.log(error)
      }
    },

    getStateID() {
      const selectedStateIndex = this.$refs[this.stateDpdown].selectedIndex

      return selectedStateIndex === 0 ? this.item.state.id : selectedStateIndex
    },
    setState() {
      const dropdownIndex = this.$refs[this.stateDpdown].selectedIndex

      //console.log('SELECTED INDEX: ', dropdownIndex)

      if (this.$refs[this.stateDpdown].id == dropdownIndex) return

      this.updatedEntreg.state.id = dropdownIndex
    },
    closeModal() {
      this.$emit('closeModal')
    }
  },

  watch: {
    updatedEntreg: {
      handler(newData) {
        JSON.stringify(newData) !== JSON.stringify(this.item)
          ? (this.formEdited = true)
          : (this.formEdited = false)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.add-task-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  padding-top: 0.5rem;
}
.grid-span-6 {
  grid-column: span 6;
  margin-inline: 1rem;
}
.grid-span-5 {
  margin-inline: 1rem;
  grid-column: span 5;
}
.grid-span-3 {
  grid-column: span 3;
  margin-inline: 1rem;
}
.grid-span-4 {
  grid-column: span 4;
  margin-inline: 1rem;
}
.grid-span-2 {
  grid-column: span 2;
  margin-inline: 1rem;
}
.grid-span-1 {
  margin-inline: 1rem;
  grid-column: span 1;
  text-align: center;
}
.item {
  display: flex;
  flex-direction: column;
}
.item-row {
  display: flex;
  gap: 0.5rem;
}
.flex-row {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

select,
option,
input,
textarea {
  border: 1px solid lightgrey;
  border-radius: 0.25rem;
  outline: none;
}
select,
option,
input,
textarea {
  padding: 0.5rem;
}
textarea {
  height: 100px;
  resize: none;
}
label {
  margin-bottom: 0.25rem;
}
p {
  padding: 0;
  margin: 0;
  line-height: 100%;
}
select {
  cursor: pointer;
}
</style>
