<template>
  <form @submit.prevent="handleSubmit">
    <div class="form-floating mb-3">
      <input
        type="email"
        class="form-control"
        id="floatingInput"
        placeholder="Email"
        v-model="email"
      />
      <label for="floatingInput">Email</label>
    </div>
    <div class="form-floating mb-3">
      <input
        type="password"
        class="form-control"
        id="floatingPassword"
        autocomplete="on"
        placeholder="Password"
        v-model="password"
      />
      <label for="floatingPassword">Password</label>
    </div>

    <span v-if="showError">
      <label class="err">{{ errorMsg }}</label>
    </span>

    <button class="btn btn-secondary" :class="{ hidden: showLoading }">
      Entrar
    </button>
    <div v-if="showLoading" class="loader"><ShowLoadingLogin /></div>

    <label class="pointer recoverpass" @click="recoverPass">
      Recuperar password
    </label>
  </form>
</template>

<script>
import auth from '../../../services/lib/auth'
import { mapGetters } from 'vuex'
import ShowLoadingLogin from '../../ShowLoadingLogin.vue'

export default {
  emits: ['loadOut'],
  components: { ShowLoadingLogin },
  data() {
    return {
      email: '',
      password: '',
      showError: false,
      errorMsg: 'Erro ao estabelecer ligação',
      showLoading: false
    }
  },
  methods: {
    async handleSubmit() {
      if (!this.email || !this.password) return

      this.showLoading = !this.showLoading
      const data = {
        email: this.email,
        password: this.password
      }

      this.resetFormState()

      const { status } = await auth.login(data)
      switch (status) {
        case 200:
          await this.$router.push('/projetos')
          this.showError = false
          break
        case 422:
          this.errorMsg = 'Credenciais de login inválidas'
          this.showError = !this.showError
          break
        case 403:
          //console.log('credenciais erradas')
          this.errorMsg = 'Verifique o seu email e tente novamente'
          this.showError = !this.showError
          break

        default:
          this.errorMsg = 'Erro ao estabelecer ligação. Tente novamente.'
          this.showError = !this.showError

          await this.$store.dispatch('reset')
          break
      }
      this.showLoading = !this.showLoading
    },
    resetFormState() {
      this.email = ''
      this.password = ''
      if (this.showError) {
        this.showError = !this.showError
      }
    },
    recoverPass() {
      this.$emit('loadOut')
      setTimeout(() => {
        this.$router.push({ name: 'RecoverPass' })
      }, 600)
    }
  },
  computed: {
    ...mapGetters(['user'])
  }
}
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}
button {
  border-radius: 1rem;
  width: 50%;
  margin-block: 1rem;
  min-width: 100px;
  background-color: var(--highlight-color);
  align-self: flex-start;
}

input {
  background-color: rgb(0, 0, 0, 0);
  border: none;
  border-bottom: solid #fff 1px;
  border-radius: 0;
  color: var(--text-color);
}
input:focus {
  background-color: rgb(0, 0, 0, 0);
  box-shadow: none;
  outline: none;
  border-bottom: solid white 1px;
  color: var(--text-color);
}

label {
  color: #fff;
}

.recoverpass:hover {
  color: var(--highlight-color);
}
button:hover {
  background-color: #fff;
  color: black;
}
input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--text-color);
  font-family: 'SulphurPoint';
}
.btn .btn-secondary {
  position: relative !important;
}
.hidden {
  display: none;
}
.loader {
  position: relative;
  height: 5rem;
  width: 50%;
  margin-bottom: 4rem;
}
.err {
  color: var(--error-color) !important;
  text-align: left;
}
</style>
