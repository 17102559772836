import apiClient from '../apiClient'
import store from '../../store/store'

export async function getEntregs() {
  try {
    const projectId = store.state.project.id
    const stageId = parseInt(store.state.stageIndex) + 1

    const endpoint = `entregs_list/${projectId}/${stageId}`

    const { data, status } = await apiClient.get(endpoint)

    if (status !== 200) throw new Error('err on getEntregs() entregs.js: ')

    //console.log(data)
    store.dispatch('entregs', data)
    return
  } catch (err) {
    //console.log(err)
    return
  }
}

export async function addEntregavel(formData) {
  try {
    const endpoint = `add_entregable`

    const { data, status } = await apiClient.post(endpoint, formData)
    //console.log({ data, status })

    return { data, status }
  } catch (err) {
    //console.log('err on addEntregavel - entregs.js: ', err)
    return
  }
}
export async function updateEntregavel(formData) {
  try {
    const endpoint = `update_entregable`

    const { data, status } = await apiClient.post(endpoint, formData)

    return { data, status }
  } catch (err) {
    //console.log('err on updateEntregavel - entregs.js: ', err)
    return
  }
}

export async function deleteEntregavel(entregable_id, entreg_pers_id) {
  try {
    const endpoint = `delete_entregable/${entregable_id}/${entreg_pers_id}`
    //console.log('endpoint: ', endpoint)
    const { data, status } = await apiClient.delete(endpoint)

    if (status !== 200)
      throw new Error('err on deleteEntregavel - entregs.js: ')

    //console.log(data, status)
    return { data, status }
  } catch (err) {
    //console.log('err on deleteEntregavel - entregs.js: ', err)
    return
  }
}
