<template>
  <section class="canvas-container">
    <button
      class="toggleCanvas"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRight"
      aria-controls="offcanvasRight"
    >
      <span class="arrow">
        <i class="fas fa-angle-double-right"></i>
      </span>
    </button>
  </section>

  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel"
  >
    <div class="offcanvas-header">
      <h5 id="offcanvasRightLabel"><b>Painel Guia</b></h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>

    <div class="offcanvas-body" v-if="routerPath === 'dashboard'">
      <p>
        Bem-vindo ao <b class="link">Digi4Construction</b>, a plataforma digital para controlo e
        gestão do ciclo de vida de um Empreendimento. Esta plataforma auxilia na
        definição, gestão e verificação de responsabilidades no processamento e
        entrega de informação, tarefas a realizar e peças a entregar ao longo
        das várias fases do ciclo de vida de um Empreendimento. DASHBOARD Neste
        painel é possível encontrar diversos indicadores elucidativos do estado
        atual do Empreendimento, desde o acompanhamento em tempo real do
        planeamento da empreitada, a consulta e gestão de equipas de Projeto,
        indicação do progresso total do Projeto, contabilização e controlo dos
        sistemas e tarefas inerentes a cada fase do Projeto.
        <ul>
          <li class="link pointer">Informação Externa</li>
          <li class="link pointer">Links da
          Legislação / Sistemas / Documentos relevantes</li>   
        </ul>
        </p>
        <p v-show="stageIndex == 0"> <span class="link"><b>Fase 0</b>:</span> - Programa Preliminar: Nesta fase o principal objetivo prende-se com a definição dos meios e métodos de trabalho que permitem cumprir com as exigências do Cliente assim como as informações preliminares sobre a Obra. Não se trata de uma fase de Projeto mas sim de definição estratégica e preparatória aos desenvolvimentos técnicos. Esta fase inclui o estudo de prós e contras, riscos e orçamento do Projeto em questão.
         </p> <p v-show="stageIndex == 1"> <span class="link"><b>Fase 1</b>:</span> - Programa Base: A Fase 1 tem como principal tarefa a preparação do Sumário de Projeto Inicial. Além disto, nesta fase é incluído o desenvolvimento dos diferentes Estudos de Viabilidade, relacionados com a composição das equipas de projeto e os papeis e responsabilidades atribuídas a cada elemento assim como os processos de troca de informação. Estas atividades são essenciais para produtividade da Fase 2.
         </p> <p v-show="stageIndex == 2"> <span class="link"><b>Fase 2</b>:</span> - Estudo Prévio: Durante esta fase o Projeto é desenvolvido tendo em conta as especificidades aprovadas e desenvolvidas na fase do Programa Base (Fase 1). Nesta fase são também desenvolvidas as Estratégias de Trabalho. Estas tarefas irão influenciar diretamente o desenrolar das fases seguintes. Aqui será emitido o Sumário Estratégico Final, parte integrante das Trocas de Informação efetuadas no final desta fase.
         </p> <p v-show="stageIndex == 3"> <span class="link"><b>Fase 3</b>:</span> - Anteprojeto: Nesta fase o Projeto é desenvolvido com maior pormenor e o trabalho dos projetistas é elaborado até à conclusão dos exercícios de coordenação espacial. Aqui incluem-se a Informação de Custos e Estratégias de Projeto de acordo com o Programa de Projeto.
         </p> <p v-show="stageIndex == 4"> <span class="link"><b>Fase 4</b>:</span> - Projeto de Execução: Durante esta fase, os Desenhos Técnicos são preparados de acordo com a Matriz de Responsabilidades de Projeto. Todos os aspetos relativos aos diversos componentes de Projeto devem estar concluídos no final desta fase, à exceção de pequenos pormenores decorrentes das condições reais do local. Em alguns projetos as fases 4 e 6 podem sobrepor-se, nomeadamente os aspetos de espacialidades dos subempreiteiros.
         </p> <p v-show="stageIndex == 5"> <span class="link"><b>Fase 5</b>:</span> - Contratação: O ciclo de vida do Empreendimento é intercalado por momentos de Contratação encontrando-se esta fase situada no momento de adjudicação com maior presença (anterior à Fase de Obra) mas mantendo-se transversal a sinergias com múltiplas outras fases. Nesta fase o foco incide sobre a preparação, fecho e partilha dos Entregáveis: Elementos de Consulta, Procedimentos de Contratação e Documentação Contratual referente a Propostas e Adjudicação.
         </p> <p v-show="stageIndex == 6"> <span class="link"><b>Fase 6</b>:</span> - Execução da Obra: Nesta fase é feita a passagem para o terreno de acordo com o Projeto de Execução. A estratégia de aquisição e/ou Programa de Serviços estabelecem as responsabilidades/obrigações dos projetistas para dar resposta às especificidades de Projeto.
         </p> <p v-show="stageIndex == 7"> <span class="link"><b>Fase 7</b>:</span> - Entrega e Garantias: Nesta fase do ciclo de vida a principal prioridade das equipas de Projeto prende-se com facilitar o processo de entrega da Obra em conformidade com o Mapa de Trabalhos assim como a conclusão de todos os artigos do Contrato de Empreitada.
         </p> <p v-show="stageIndex == 8"> <span class="link"><b>Fase 8</b>:</span> - Uso: Esta fase inclui os processos de avaliação de pós ocupação e revisão do desempenho das soluções e equipamentos empregues durante as fases de Construção. Além disto, é nesta fase que são geridas as atividades relacionadas com a Operação e Manutenção do património construído.
        </p>
    </div>

    <div class="offcanvas-body" v-if="routerPath === 'detalhes'">
      <p>
        O
        <b>DIGI4CONSTRUCTION</b>
        permite a definição de detalhes gerais de identificação do Projeto, bem
        como informação adicional para uma caracterização mais pormenorizada do
        empreendimento em gestão. Nesta página o utilizador pode ainda gerir
        informação geral referente às diferentes fases do ciclo de vida do
        Empreendimento, definindo uma estimativa e referência continuada dos
        prazos estabelecidos e orçamento da Obra.
      </p>
      <p><b>Referências informativas:</b></p>
      <ul>
        <li class="link pointer">Legislação</li>
        <li class="link pointer">Guias Internacionais</li>
        <li class="link pointer">Portal de Boas Práticas</li>
      </ul>
    </div>

        <div class="offcanvas-body" v-if="routerPath === 'agentes'">
      <p>
        Este menu é dedicado ao controlo e gestão dos vários agentes com participação direta nas diversas fases do ciclo de vida do Empreendimento. Aqui é possível consultar, gerir, adicionar e remover Agentes consoante as suas responsabilidades e Tarefas no decorrer dos trabalhos.  </p>
 <p>Além da listagem de Agentes/Funções apresentadas na plataforma é feita a abertura à criação de perfis personalizados de forma a garantir a inclusão de todos e quaisquer Agentes que possam surgir no decorrer dos trabalhos/responsabilidades inerentes às atividades da construção civil.  </p>
  </div>

        <div class="offcanvas-body" v-if="routerPath === 'tarefas'">
      <p>
        Esta secção permite fazer a gestão das Tarefas a executar por fase assim como a identificação do Agente responsável pela sua execução. Desta forma é possível manter um controlo eficiente das atividades necessárias para o correto desenvolvimento dos trabalhos.</p> 
	 <p>É importante realçar que uma determinada Tarefa pode ser responsabilidade de mais do que um Agente, pelo que se torna especialmente pertinente ter presente o mapeamento das tarefas com os seus responsáveis.  </p>
	 <p>Uma Tarefa pode consistir numa atividade da qual resulta um conjunto de informação estruturada (Sistema) a ser transmitida por parte do responsável pela realização da mesma. Noutros casos, uma Tarefa pode consistir no definir de metas/objetivos de trabalho que tem como objetivo harmonizar procedimentos e estabelecer prazos para o correto decorrer das atividades. </p>
	 <p>À semelhança do que sucedido com os Agentes são apresentadas várias Tarefas pré-definidas em função do tipo e fase do Empreendimento, no entanto é garantida a abertura à criação de Tarefas personalizadas que visem a dar resposta aos requisitos estabelecidos para o trabalho em questão.</p>

    </div>

    <div class="offcanvas-body" v-if="routerPath === 'sistemas'">
      <p>
        Na presente secção é possível fazer a gestão dos documentos de informação estruturada resultantes das atividades inerentes ao processo da construção civil. Estes documentos são, por norma, o resultado da realização de uma ou mais Tarefas. São estes documentos que permitem a correta execução e definição dos trabalhos a realizar.
      </p>
      <p>
        Tendo em conta as exigências existentes ao nível da legislação afeta ao mercado da construção civil, torna-se pertinente definir, organizar e consultar as diferentes peças, escritas ou desenhadas, resultantes da realização das várias Tarefas. A informação surge identificada de acordo com os padrões de classificação definidos a nível internacional, nomeadamente o sistema UniClass 2015 [por link] assim como a sua adaptação ao mercado português, nomeadamente o sistema SECClass [link_SeCClass].</p>
      <p> 
        Além da definição da informação estruturada, o Digi4Construction permite que, para cada Sistema, seja definido o nível de detalhe assim como o nível de informação (Level of Detail e Level of Information). Esta funcionalidade tem especial interesse do ponto de vista do cumprimento dos requisitos de informação estabelecidos pelo cliente (EIR).
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      routerPath: ''
    }
  },
  methods: {
    setCanvas() {}
  },
  computed: {
    ...mapGetters(['stageIndex'])
  },
  watch: {
    $route(to) {
      switch (to.path) {
        case '/dashboard':
          this.routerPath = 'dashboard'
          break
        case '/detalhes':
          this.routerPath = 'detalhes'
          break
        case '/agentes':
          this.routerPath = 'agentes'
          break
        case '/tarefas':
          this.routerPath = 'tarefas'
          break
        case '/sistemas':
          this.routerPath = 'sistemas'
          break
        default:
          break
      }
    }
  }
}
</script>

<style scoped>
.canvas-container {
  position: fixed;
  z-index: 5;
  border: none;
  background-color: transparent;
  right: 1%;
  top: 50%;
  transition: 0.25s ease;
}
.toggleCanvas {
  position: absolute;
  z-index: 5;
  border: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  right: 0;
  transition: 0.25s ease;
}
.arrow {
  font-size: clamp(20px, 1.25rem, 30px);
  color: var(--title-color);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s ease;
}
.canvas-container:hover .toggleCanvas {
  transform: rotateY(180deg);
}
.canvas-container:hover .arrow {
  color: var(--main-color);
}
.offcanvas-body {
  text-align: justify;
}
</style>