<template>
  <div class="stages">
    <div class="select-container">
      <select
        class="form-select-sm pointer"
        @change="handleClick($event)"
        ref="cstage"
        :class="{ hidden: hide }"
      >
        <option
          selected
          disabled
          hidden
          v-for="indexed in stageIndex"
          :key="indexed"
        >
          {{ indexed.stage_value }}. {{ indexed.stage_name }}
        </option>
        <option v-for="stage in stages" :key="stage">
          {{ stage.stage_value }}. {{ stage.stage_name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import agentes from '../services/lib/agentes'
import tasks from '../services/lib/tasks'
import systems from '../services/lib/systems'
import { getEntregs } from '../services/lib/entregs'

export default {
  data() {
    return {
      hide: false
    }
  },
  methods: {
    async handleClick(event) {
      if (this.$store.state.stageIndex == event.target.value.slice(0, 1)) return

      this.$store.dispatch('showLoading', true)
      this.$store.dispatch('stageIndex', event.target.value.slice(0, 1))
      const path = this.$router.currentRoute.value.path

      switch (path) {
        case '/agentes':
          await agentes.getStageAgentes()
          break
        case '/tarefas':
          await tasks.getTasks()
          break
        case '/sistemas':
          await systems.getSystems()
          break
        case '/entregaveis':
          await getEntregs()
          break

        default:
          break
      }
    }
  },
  beforeCreate() {
    //reset store current stage
    this.$store.dispatch(
      'stageIndex',
      this.$store.state.project.current_stage.stage_value
    )
  },
  computed: {
    ...mapGetters(['stages', 'project']),
    stageIndex() {
      return this.$store.state.stages.filter(
        (item) => item.stage_value === this.$store.state.stageIndex
      )
    }
  },

  watch: {
    $route(to, from) {
      if (to.path == '/detalhes') {
        this.hide = true
        this.$store.dispatch(
          'stageIndex',
          this.project.current_stage.stage_value
        )
      }
      if (from.path == '/detalhes') {
        this.hide = false
        this.$store.dispatch(
          'stageIndex',
          this.project.current_stage.stage_value
        )
      }
      if (to.path == '/sistemas') {
        this.hide = true
        this.$store.dispatch(
          'stageIndex',
          this.project.current_stage.stage_value
        )
      }
      if (from.path == '/sistemas') {
        this.hide = false
        this.$store.dispatch(
          'stageIndex',
          this.project.current_stage.stage_value
        )
      }
    }
    // '$store.state.project': function () {
    //   if (this.$store.state.project)
    //     this.$store.dispatch('stageIndex', this.project.current_stage_value)
    // }
  }
}
</script>

<style scoped>
* {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.select-container {
  position: absolute;
  width: 95%;
  height: 50%;
  bottom: 13%;
}
.form-select-sm {
  width: 100%;
  height: 100%;
  border: none;
  border-top: 2px solid transparent;
  border-radius: 0;
  outline: 0;
  color: var(--text-color);
  border-bottom: 0.15rem solid var(--highlight-color);
  background: transparent;
  letter-spacing: 1px;
  font-family: 'SulphurPoint-Bold';
}

.hidden {
  display: none;
}
option {
  color: var(--title-color);
  background-color: var(--bg-color);
}
</style>
