<template>
  <div v-if="!unreadnotifications?.length" class="no-notification-text">
    <h2>Sem Notificações</h2>
  </div>

  <div v-else class="notification-box">
    <div class="notification-header">
      <div class="col-8 notification-title">
        <p>
          {{ unreadnotifications.length }}
          {{ getTitle }}
        </p>
      </div>
      <div class="col col-lg-4 clean-button" @click="markAllAsRead()">
        <p class="red">Limpar</p>
      </div>
    </div>

    <div class="notifications-list">
      <div
        v-for="(unread, index) in unreadnotifications"
        :key="index"
        class="notifications-item"
        @click="redirect(unread.data.path)"
      >
        <div class="item-header">
          <!-- <div class="custom-icon-wrapper">
            <i :class="['custom-icon', unread.data.icon]"></i>
          </div> -->

          <div class="notification-text">
            <h4 style="padding: 0; margin: 0">{{ unread.data.title }}</h4>
          </div>

          <div class="icon-close" @click="markOneAsRead(unread.id)"></div>
        </div>

        <div class="notification-text-message">
          <p>{{ unread.data.message }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="notifications-btn" @click="goToNotification()">
    <p>Ver tudo</p>
  </div>
</template>
<script>
import {
  markAllAsRead,
  getUnreadNotificationsGeneral,
  getUnreadNotificationsProject,
  markOneAsRead,
  getNotifications
} from '../../services/lib/notifications'
import { mapGetters } from 'vuex'
import agentes from '../../services/lib/agentes'

export default {
  name: 'Notifications',
  emits: ['closeNotifications', 'notifications'],
  data() {
    return {
      unreadnotifications: {},
      newNot: {},
      timerId: null,
      dbRole: null
    }
  },
  methods: {
    async getNotifications() {
      try {
        if (!this.project) {
          this.unreadnotifications = await getUnreadNotificationsGeneral(
            this.user.id
          )
        } else {
          this.unreadnotifications = await getUnreadNotificationsProject(
            this.user.id,
            this.project.id
          )
        }

        this.unreadnotifications = this.unreadnotifications.data
        this.unreadnotifications.forEach((unread, index) => {
          unread.data = JSON.parse(unread.data)
        })

        if (this.unreadnotifications.length > 0) {
          this.$emit('notifications', true)
        } else {
          this.$emit('notifications', false)
        }
      } catch (error) {
        //console.log('error on getNotifications', error)
      }
    },

    async markAllAsRead() {
      try {
        const nots = {
          notsArray: this.unreadnotifications
        }
        await markAllAsRead(nots)
        this.getNotifications()
      } catch (error) {
        //console.log('error on markAllAsRead', error)
      }
    },
    async markOneAsRead($nid) {
      try {
        await markOneAsRead($nid)
        this.getNotifications()
      } catch (error) {
        //console.log('error on markOneAsRead', error)
      }
    },
    async goToNotification() {
      this.$router.push({ name: 'Notificacoes' })
      this.closeNotifications()
    },
    async checkNewNotifications() {
      try {
        //console.log('checking new notifications')
        if (!this.project) {
          this.newNot = await getUnreadNotificationsGeneral(this.user.id)
        } else {
          this.newNot = await getUnreadNotificationsProject(
            this.user.id,
            this.project.id
          )
        }
        this.newNot = this.newNot.data

        if (
          this.newNot[0]?.created_at != this.unreadnotifications[0]?.created_at
        ) {
          if (this.project != null) {
            this.dbRole = await agentes.getUserRole({
              project_id: this.project.id,
              user_id: this.user.id
            })

            if (this.user.role != this.dbRole.data.name) {
              this.$router.push({ name: 'Projetos' })
            }
          }
          await this.getNotifications()
        }
      } catch (error) {
        //console.log('error on checkNewNotifications', error)
      }
    },
    redirect(path) {
      //console.log('PATH: ', path)
      this.$router.push({ path: path })
      this.closeNotifications()
    },
    closeNotifications() {
      this.$emit('closeNotifications')
    }
  },

  async mounted() {
    await this.getNotifications()

    this.timerId = setInterval(async () => {
      //console.log('timer ran')
      this.checkNewNotifications()
    }, 30000)
  },
  unmounted() {
    //console.log('UNMOUNTED ran')
    clearInterval(this.timerId)
  },

  computed: {
    ...mapGetters(['user', 'project']),
    getTitle() {
      return this.unreadnotifications.length > 1
        ? 'Notificações'
        : 'Notificação'
    }
  },
  watch: {
    project: {
      handler(newVal, oldVal) {
        this.getNotifications()
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
@import './../../assets/icons.css';

* {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

h2 {
  margin: 0;
  padding: 0;
  color: black;
  display: center;
  font-family: 'SulphurPoint-Bold';
}

p {
  margin: 0;
  padding: 0;
  color: black;
  display: center;
  font-family: 'SulphurPoint-Bold';
}

.close {
  cursor: pointer;
  scale: 0.45;
  align-items: flex-end;
}

.close:hover {
  background: red;
  transform: translateY(-2px) rotate(12deg);
}

.custom-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-text {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
}
.notifications-item i {
  background: #444;
  color: white;
}
/* .notification-text h4 {
  color: #333;
  opacity: 0.8;
  font-size: 15px;
  margin-top: 3px;
  font-family: 'SulphurPoint-Bold';
  letter-spacing: 1px;
} */

.notification-text h4 {
  color: #888;
  opacity: 0.8;
  font-size: 15px;
  margin-top: 3px;
  /* font-family: 'SulphurPoint-Bold'; */
  letter-spacing: 0px;
}

.notification-text p {
  color: #aaa;
  font-size: 12px;
  text-align: left;
  margin: 0;
}

.notification-text-message {
  padding-inline: 12px;
}

.notification-text-message p {
  font-size: 12px;
  text-align: left;
  margin: 0;
  letter-spacing: 0;
  color: #333;
}

.notifications-list {
  max-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: column;
  gap: 0.32rem;
  padding: 4px;
}

.no-notification-text {
  cursor: default;
  border-radius: 4px !important;
}
.no-notification-text h2 {
  scale: 0.6;
}

/* .no-notification-text :hover {
  scale: 0.75;
} */

.notification-header {
  padding: 0;
  margin: 0;
  display: flex;
  padding: 4px;
  padding-right: 6px;
  gap: 4px;
  justify-content: space-between;
  width: 100%;
}
.notification-title,
.clean-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  padding-inline: 4px;
  border-radius: 2px;
}

.notification-title {
  background: var(--title-color);
  color: white;
}
.notification-title > p,
.clean-button > p {
  color: white;
  font-family: 'SulphurPoint-Bold';
}
.clean-button {
  background: rgba(255, 0, 0, 0.4);
}
.clean-button:hover {
  cursor: pointer;
  background: rgba(255, 0, 0, 0.6);
}
.notification-box {
  cursor: default;
}
.notifications-btn {
  background: var(--title-color);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  opacity: 1;
  transition: 200ms;
}

.notifications-btn > p {
  color: white;
}
.notifications-btn:hover {
  opacity: 0.8;
  letter-spacing: 2px;
}

.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.notifications-item {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 0.2rem;
  border-radius: 4px;
  box-shadow: rgba(149, 157, 165, 0.4) 0px 4px 24px;
  transition: 200ms;
  scale: 0.98;
  cursor: pointer;
}
.notifications-item:hover {
  box-shadow: rgba(58, 60, 63, 0.4) 0px 4px 24px;
}
.item-header {
  display: flex;
  justify-content: center;
  align-items: space-between;
  height: 24px;
  padding-inline: 8px;
  width: 100%;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  box-shadow: 0 4px 20px -1px #ddd;
  gap: 0.32rem;
}

.icon-close {
  width: 12px !important;
  height: 12px !important;
  align-self: center !important;
  border-radius: 50%;
  background: rgb(250, 173, 173);
}

.icon-close:hover {
  background: rgb(218, 5, 5, 0.6);
}
</style>
