<template>
  <div class="topnav" ref="topnav">
    <nav class="navbar navbar-expand-xl navbar-dark" :class="topnavBg">
      <div class="container-fluid">
        <div class="pointer logo-wrapper" @click="handleClick">
          <h2>
            Digi
            <span :class="quatro">4</span>
            Construction
          </h2>
        </div>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          ref="toggler"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!-- RIGHT SIDE -->
          <ul class="navbar-nav ms-auto">
            <template v-if="user">
              <li v-if="user" class="nav-item">
                <router-link
                  :to="{ name: 'Projetos' }"
                  class="nav-link active"
                  @click="toggleNavbar"
                >
                  Empreendimentos
                </router-link>
              </li>
            </template>

            <li class="nav-item" v-show="user">
              <router-link :to="{ name: 'Conteudos' }" class="nav-link active">
                Conteúdos
              </router-link>
            </li>

            <template v-if="user">
              <li class="nav-item dropdown">
                <span
                  class="nav-link active dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>{{ user.name }}</span>
                </span>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <router-link
                      :to="{ name: 'UserData' }"
                      class="dropdown-item"
                    >
                      Perfil
                    </router-link>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li @click="logout">
                    <router-link
                      :to="{}"
                      class="dropdown-item"
                      @click="toggleNavbar"
                    >
                      Logout
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item notification-parent" ref="notificationParent">
                <div
                  v-if="haveNotification"
                  class="notification-container heartbeat"
                  @click="toggleNotifications"
                >
                  <i class="fas fa-bell fa-lg notification-icon"></i>
                </div>
                <div
                  v-else
                  class="notification-container"
                  @click="toggleNotifications"
                >
                  <i class="fas fa-bell fa-lg notification-icon"></i>
                </div>
                <div
                  class="notifications"
                  :class="{ show: showNotifications }"
                  id="box"
                  ref="notifications"
                >
                  <Notifications
                    @notifications="handleNotification"
                    @closeNotifications="toggleNotifications"
                  />
                </div>
              </li>
              <li class="nav-item">
                <router-link :to="{}" class="nav-link">
                  <span>PT</span>
                </router-link>
              </li>
            </template>

            <template v-else>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'Login' }"
                  class="nav-link active"
                  @click="toggleNavbar"
                >
                  Entrar
                </router-link>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import Notifications from '../notifications/Notifications.vue'
import { mapGetters } from 'vuex'
import { setNotification } from '../../services/filters/dreamCatcher'
import auth from '../../services/lib/auth'

export default {
  components: {
    Notifications
  },
  data() {
    return {
      topnavBg: '',
      quatro: 'quatro',
      showNotifications: false,
      haveNotification: false
    }
  },
  methods: {
    toggleNotifications() {
      this.showNotifications = !this.showNotifications
    },
    handleOutsideClick(event) {
      const target = event.target
      const notificationsContainer = this.$refs.notifications
      const notificationIcon = this.$refs.notificationParent

      // Check if the target is within the notifications container or the notification icon
      if (
        notificationsContainer &&
        !notificationsContainer.contains(target) &&
        notificationIcon &&
        !notificationIcon.contains(target)
      ) {
        this.showNotifications = false
      }
    },
    handleClick() {
      this.$router.push({ name: 'Welcome' })
    },
    toggleNavbar() {
      if (window.innerWidth < 1200) {
        this.$refs.toggler.click()
      }
    },
    async logout() {
      await auth.logout()

      setTimeout(() => {
        setNotification('Saída com sucesso!', 'success')
      }, 500)
    },
    handleNotification(not) {
      this.haveNotification = not
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  created() {
    if (
      this.$route.path === '/' ||
      this.$route.path === 'NotFound' ||
      this.$route.path === 'server-error'
    ) {
      this.topnavBg = 'nav-welcome'
    }
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick)
  },

  watch: {
    $route(to) {
      if (to.name === 'NotFound') {
        this.topnavBg = 'nav-welcome'
        return
      } else {
        switch (to.path) {
          case '/':
            this.topnavBg = 'nav-welcome'
            break
          case '/server-error':
            this.topnavBg = 'nav-welcome'
            break
          // case '/registar':
          //   this.topnavBg = 'nav-auth'
          //   this.quatro = 'quatro-auth'
          //   break
          default:
            this.topnavBg = ''
            this.quatro = 'quatro'
            break
        }
      }
    }
  }
}
</script>

<style scoped>
* {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

h2 {
  margin: 0;
  padding: 0;
  color: var(--text-color);
  display: flex;
  font-family: 'SulphurPoint-Bold';
}

.topnav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.navbar {
  min-width: 540px;
  max-width: 100%;
  /**/
  height: 5vh;
}

.logo-wrapper {
  transition: 200ms ease;
}

.logo-wrapper:hover {
  transform: translateX(4px);
}

.navbar-toggler {
  margin-right: 5vw;
}

.dropdown-item {
  white-space: normal;
}

.dropdown-item:hover {
  /* background: #c4d3d298; */
  background: rgba(0, 0, 0, 0.1);
}

.dropdown-item:active {
  background-color: var(--main-color);
  /* background-color: rgb(155, 155, 155); */
  /* position: relative;
  z-index: 10; */
}

nav {
  letter-spacing: 0.1rem;
  background-color: var(--topnav-color);
  transition: 0.5s ease-in-out;
}

.nav-welcome {
  background-color: transparent;
}

.nav-auth {
  background-color: rgb(51, 51, 53);
}

.quatro {
  font-size: inherit;
  color: var(--highlight-color);
}

.quatro-auth {
  color: var(--highlight-color);
}

.dropdown-menu[data-bs-popper] {
  margin: 0;
  top: 120%;
}

.notification-container {
  position: relative;
  height: 100%;
  width: 100%;
  /* height: 1.5rem;
  width: 1.5rem;
  top: 0.25em; */
  border: 2px solid #ddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-icon {
  position: absolute;
  color: #ddd;
}

.notification-icon-have {
  position: absolute;
  color: #00a2ff;
}

.container-fluid {
  background-color: inherit;
}

.navbar-nav {
  display: flex;
  align-items: center;
  gap: 4px;
}

.nav-link {
  transition: 100ms;
}

.notification-parent {
  position: relative;
  height: 40px !important;
  width: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.notification-parent:hover {
  cursor: pointer;
}

.nav-link:hover {
  color: var(--highlight-color) !important;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

@media screen and (max-width: 1199px) {
  .notification-parent {
    align-self: center;
    justify-self: center;
  }
}

@media (max-width: 750px) {
  .navbar-dark .navbar-toggler {
    border-color: transparent;
    font-size: 12.5px;
    color: var(--text-color);
    outline: none;
    border: none;
    box-shadow: none;
  }
}

@media (max-width: 750px) {
  .navbar,
  .navbar-expand-xl,
  .navbar-dark,
  .nav-welcome {
    min-width: 90vw;
    /* min-height: 40px; */
  }
}

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200&display=swap');

.notifications {
  display: none;
  width: 300px;
  height: 0px;
  position: absolute;
  top: 50px;
  right: 5px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.notifications h2 {
  font-size: 14px;
  padding: 10px;
  border-bottom: 1px solid #eee;
  color: #999;
}

.notifications h2 span {
  color: var(--highlight-color);
}

.notifications.show {
  display: block;
  height: auto;
  opacity: 1;
}

.notifications-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 6px 9px;
  margin-bottom: 0px;
  gap: 1rem;
  cursor: pointer;
}

.notifications-item:hover {
  background-color: #eee;
}

.notifications-item img {
  display: block;
  width: 50px;
  height: 50px;
  margin-right: 9px;
  border-radius: 50%;
  margin-top: 2px;
}

.notifications-item .text {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
}

.notifications-item .text h4 {
  color: #777;
  font-size: 16px;
  margin-top: 3px;
}

.notifications-item .text p {
  color: #aaa;
  font-size: 12px;
  text-align: left;
  margin: 0;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.heartbeat {
  animation: heartbeat 2s infinite;
  border-color: var(--highlight-color);
}
</style>
