<template>
  <div class="page">
    <div class="intro"></div>
    <div class="info">
      <h1 class="noselect">
        <span>4</span>
        <span>0</span>
        <span>4</span>
      </h1>
      <h2 class="noselect">
        Oops... Não conseguimos
        <br />
        encontrar o que procura.
      </h2>
    </div>
    <div class="layer"></div>

    <div id="app" class="particles">
      <vue-particles
        color="#eee"
        :particleOpacity="0.7"
        :particlesNumber="70"
        shapeType="circle"
        :particleSize="4"
        linesColor="#00ccff"
        :linesWidth="1.5"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="200"
        :moveSpeed="4"
        :hoverEffect="true"
        hoverMode="repulse"
        :clickEffect="true"
        clickMode="push"
        width="100%"
        height="100"
      ></vue-particles>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  beforeCreate() {
    this.$store.dispatch('project', null)
  }
}
</script>

<style scoped>
.particles {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
}
#particles-js {
  position: absolute;
  width: 100%;
  height: 90%;
}

canvas {
  width: 1920px !important;
  height: 100% !important;
}
.info {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background-image: url('https://images.unsplash.com/photo-1498036882173-b41c28a8ba34?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1964&q=80');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  z-index: 0;
}
.info h1 {
  font-size: 7vw;
  animation: 3s ease fade;
  display: flex;
  font-family: 'SulphurPoint-Bold';
  position: relative;
  height: 85%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2vw;
  position: relative;
}
.info h2 {
  font-size: 2vw;
  animation: 9s ease fade;
  position: relative;
  bottom: 20%;
  right: 0;
  padding-bottom: 1vh;
  padding-right: 1vw;
  letter-spacing: 1vw;
}
h1,
h2 {
  color: #ddd;
}
.glow {
  animation: glow 3s ease-in infinite alternate;
  font-size: inherit !important;
}
.zup {
  animation: reverse-glow 3s ease-out infinite alternate;
  font-size: inherit !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
/**/
.intro {
  background: linear-gradient(
    120deg,
    #00ccff,
    #00ccff,
    var(--main-color),
    rgba(0, 0, 0, 0.5)
  );
  width: 100%;
  height: 100%;
  background-size: cover;
  background-blend-mode: hard-light;
  animation: hue-rotate 3s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.5;
}

@keyframes hue-rotate {
  from {
    -webkit-filter: hue-rotate(0);
    -moz-filter: hue-rotate(0);
    -ms-filter: hue-rotate(0);
    filter: hue-rotate(0);
  }
  to {
    -webkit-filter: hue-rotate(360deg);
    -moz-filter: hue-rotate(360deg);
    -ms-filter: hue-rotate(360deg);
    filter: hue-rotate(0);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px var(--main-color),
      0 0 40px var(--main-color), 0 0 50px var(--main-color),
      0 0 60px var(--main-color), 0 0 70px var(--main-color);
  }

  to {
    text-shadow: 0 0 20px #fff, 0 0 30px var(--main-color),
      0 0 40px var(--main-color) var(--main-color), 0 0 50px var(--main-color),
      0 0 60px var(--main-color), 0 0 70px var(--main-color),
      0 0 80px var(--main-color);
  }
}

@keyframes reverse-glow {
  from {
    text-shadow: 0 0 20px #fff, 0 0 30px var(--main-color),
      0 0 40px var(--main-color) var(--main-color), 0 0 50px var(--main-color),
      0 0 60px var(--main-color), 0 0 70px var(--main-color),
      0 0 80px var(--main-color);
  }
  to {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px var(--main-color),
      0 0 40px var(--main-color), 0 0 50px var(--main-color),
      0 0 60px var(--main-color), 0 0 70px var(--main-color);
  }
}
</style>
