<template>
  <main class="proj" @mouseenter="mouseover" @mouseleave="mouseover">
    <div :class="[hovered ? 'hovered' : 'img']" ref="bg"></div>
    <div class="title-container">
      <div
        v-if="hovered"
        class="download"
        @click="downloadJson(project.id)"
        title="Exportar como JSON"
      >
        <i
          class="bi bi-file-earmark-arrow-down"
          style="
            color: white !important;
            background-color: transparent !important;
            scale: 1.7;
          "
        ></i>
      </div>
      <div
        v-if="project.can_delete"
        class="icon-container"
        :class="{ hidden: !hovered }"
        @click="deleteProject(project)"
      >
        <i class="icon-close-sm"></i>
      </div>
      <h2 class="proj-title">{{ project.project_name }}</h2>
    </div>
    <div v-if="hovered" class="opened" @click="fetchProject(project.id)">
      <div class="proj-info">
        <div class="right-side">
          <p style="font-size: 150%; text-align: center">
            REF: {{ project.ref }}
          </p>

          <p style="font-size: 120%; text-align: center">
            Fase atual: {{ project.current_stage.stage_value }}
          </p>

          <p>
            {{ project.proj_creator.name }}
            <i class="fas fa-user-tie"></i>
          </p>
          <p>
            {{ project.detail.location }}
            <i class="fas fa-globe-europe"></i>
          </p>
        </div>
      </div>
    </div>
    <p>
      <span class="updatedAt">Atualizado a {{ getUpdated }}</span>
    </p>
  </main>

  <section v-if="setModal">
    <Modal
      :setModal="setModal"
      :message_title="message_title"
      :message_body="message_body"
      :item="item"
      :scope="scope"
      @closeModal="closeModal"
      @deleteSuccess="deleteSuccess"
    />
  </section>
</template>

<script>
import { getStages } from '../services/lib/stages'
import projectLib from '../services/lib/projects'
import agentes from '../services/lib/agentes'
import Modal from '../views/Modal.vue'
import { number } from 'yup/lib/locale'
import { json, saveJsonToFile } from '../services/lib/pdf.js'
import auth from '../services/lib/auth'
export default {
  components: {
    Modal
  },
  props: {
    project: Object,
    index: number
  },
  emits: ['toggleLoading', 'deleteSuccess'],
  data() {
    return {
      hovered: false,
      setModal: null,
      message_title: '',
      message_body: '',
      item: null,
      scope: ''
    }
  },
  mounted() {
    if (this.project.project_image) {
      this.$refs.bg.style.backgroundImage = `url(
        ${process.env.VUE_APP_BASE_URL}/storage/${this.project.project_image}
      )`
    }
  },
  methods: {
    async fetchProject(id) {
      try {
        this.toggleLoading()
        const [projeto] = await projectLib.getProject(id)
        const stages = await getStages()
        const role = await agentes.getUserRole({
          project_id: projeto.id,
          user_id: this.$store.state.user.id
        })

        this.$store.dispatch('stages', stages)
        this.$store.dispatch('project', projeto)
        this.$store.dispatch('addRoleToCurrentUser', role.data.name)

        this.$router.push({ name: 'Dashboard' })
      } catch (error) {
        //console.log(`on ListProjects: ${error}`)
      }
    },
    deleteProject(project) {
      this.item = project
      this.item.index = this.index
      this.scope = 'danger'
      this.message_title = 'APAGAR PROJETO'
      this.message_body = `Tem a certeza que pretende remover o empreendimento ${project.project_name} ?`
      //console.log(project.project_name)
      this.setModal = 'setConfirmChanges'
    },
    async downloadJson(id) {
      this.showLoading = !this.showLoading
      const stagesString = '1,2,3,4,5,6,7,8,9'
      const jsonData = await json(id, stagesString)
      saveJsonToFile(jsonData, 'Digi4Construction.json')
    },
    deleteSuccess(index) {
      this.$emit('deleteSuccess', index)
    },
    mouseover() {
      this.hovered = !this.hovered
    },
    toggleLoading() {
      this.$emit('toggleLoading')
    },
    closeModal() {
      this.setModal = null
      this.scope = null
    }
  },
  computed: {
    getUpdated() {
      if (this.project === 'Unauthenticated.') {
        auth.logout()
      }

      const { updated_at } = this.project
      return updated_at.slice(0, 10)
    }
  }
}
</script>

<style scoped>
* {
  text-decoration: none;
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.proj {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-inline: 1vw;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  width: clamp(260px, 18rem, 765px);
  height: clamp(400px, 25rem, 950px);
  background: var(--topnav-color);
  border-radius: 0.5rem;
  transition: box-shadow 1.5s;
  margin-bottom: 2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.proj:hover {
  /* box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; */
  box-shadow: rgba(0, 0, 0, 1) 0px 5px 15px;
}

.proj-title {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}

.img {
  position: relative;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: 100%;
  height: 100%;
  background-image: var(--proj-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: var(--topnav-color) 0px -65px 36px -28px inset;
  transition: 0.5s ease-in-out;
}

.hovered {
  width: 100%;
  height: 50%;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-image: var(--proj-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: var(--topnav-color) 0px -65px 36px -28px inset;
  position: absolute;
  top: 0;
  filter: blur(2px);
  -webkit-filter: blur(2px);
  transition: 0.5s ease-in-out;
  opacity: 0.75;
}

.hovered + .title-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10%;
  left: 0;
  width: 100%;
  height: 10%;
  padding-top: 1rem;
  padding-bottom: 0.3rem;
  background-color: var(--transp-main-color);
  box-shadow: rgba(0, 0, 0, 0.5) -1px 1px 5px 1px;
  transition: 0.5s ease-in-out;
}

.opened {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: 1.5s;
  animation: fade 1.5s;
  z-index: 1;
}

.right-side {
  display: inherit;
  position: relative;
  text-align: right;
  color: #ddd;
  padding-bottom: 1rem;
  z-index: 1;
}

.proj-info {
  position: absolute;
  bottom: 1rem;
  left: 0;
  color: rgb(175, 175, 175);
  padding: 1rem;
  text-align: justify;
  width: 100%;
}

h2 {
  color: var(--text-color);
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: 'SulphurPoint-Light';
}

.hovered + h2 {
  padding-top: 10rem;
}

.updatedAt {
  color: rgb(150, 150, 150);
  position: absolute;
  right: 0.5rem;
  bottom: 0;
  padding-bottom: 0.5rem;
  text-align: right;
}

.icon-container:hover {
  background: rgba(214, 60, 60, 0.836);
}

.download {
  left: 0.5rem;
  position: absolute;
  width: 2rem;
  height: 2rem;
  min-width: 25px;
  min-height: 25px;
  transition: 0.33s;
  animation: fade 1s;
  z-index: 2;
  bottom: 105%;
  right: 3%;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-close-sm {
  background: rgba(255, 255, 255, 0.75);
  width: 1.2rem;
  height: 1.2rem;
  min-width: 17px;
  min-height: 17px;
}

.icon-container {
  background: rgba(226, 83, 83, 0.514);
  position: absolute;
  width: 1.66rem;
  height: 1.66rem;
  min-width: 25px;
  min-height: 25px;
  transition: 0.33s;
  animation: fade 1s;
  z-index: 2;
  bottom: 115%;
  right: 3%;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-container i {
  margin: 0;
  padding: 0;
}

.hidden {
  display: none;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
