import apiClient from '../apiClient'
import store from '../../store/store'

export async function getOverallInfo(formData) {
  try {
    const endpoint = 'overall_chart'

    const { data, status } = await apiClient.post(endpoint, formData)

    return { data, status }
  } catch (error) {
    //console.log(error)
  }
}

export async function getTasksInfo(formData) {
  try {
    const endpoint = 'tasks_chart'

    const { data, status } = await apiClient.post(endpoint, formData)

    // //console.log('TASKS CHART: ', data)

    return { data, status }
  } catch (error) {
    //console.log(error)
  }
}

export async function getSystemsInfo(formData) {
  try {
    const endpoint = 'systems_chart'

    const { data, status } = await apiClient.post(endpoint, formData)
    // //console.log('SYSTEMS CHART: ', data)

    return { data, status }
  } catch (error) {
    //console.log(error)
  }
}

export async function getEntregsInfo(formData) {
  try {
    const endpoint = 'entregables_chart'

    const { data, status } = await apiClient.post(endpoint, formData)
    // //console.log('ENTREGS CHART: ', data)

    return { data, status }
  } catch (error) {
    //console.log(error)
  }
}
