<template>
  <main class="list">
    <table class="table">
      <thead>
        <tr class="table-primary pointer">
          <th @click="sortUsers">
            Nome
            <div :class="sortedUsers ? 'arrow-up' : 'arrow-down'">
              <i class="fas fa-sort-down"></i>
            </div>
          </th>
          <th>Email</th>
          <th>Contacto</th>
          <th>Empresa</th>
          <th>
            <div class="fa-question-wrapper">
              Permissões
              <div
                @click="setModal = 'infoPermi'"
                title="Clique no ícon para ver mais"
              >
                <i class="fas fa-question" style="color: white"></i>
              </div>
            </div>
          </th>
          <th>
            <div></div>
          </th>
        </tr>
      </thead>
      <tbody
        v-for="(
          {
            id,
            name,
            email,
            phone_number,
            company_name,
            user_projects,
            invited_by_current_user
          },
          index
        ) in users"
        :key="id"
      >
        <tr class="linha">
          <td>{{ name }}</td>
          <td>{{ email }}</td>
          <td>{{ getContact(phone_number) }}</td>
          <td>{{ getCompanyName(company_name) }}</td>
          <td>
            <select
              v-if="id != getProjectCreatorId"
              :disabled="
                !(
                  canChangeUserRole ||
                  (canChangeInvitedUserRole && invited_by_current_user)
                )
              "
              class="form-select"
              @change="assignRole(id, `${userDpDownRef}${id}`)"
              :ref="`${userDpDownRef}${id}`"
            >
              <option selected disabled hidden>
                {{ getRoleToShow(user_projects[0].role?.name) }}
              </option>
              <option
                v-show="!canChangeInvitedUserRole"
                value="administrator"
                class="custom-select-option"
              >
                Administrador
              </option>
              <option value="coordinator" class="custom-select-option">
                Coordenador
              </option>
              <option value="integrator" class="custom-select-option">
                Integrador
              </option>
              <option value="guest" class="custom-select-option">
                Convidado
              </option>
            </select>
            <select v-else disabled class="form-select">
              <option>{{ getRoleToShow(user_projects[0].role?.name) }}</option>
            </select>
          </td>
          <td class="icon-wrapper">
            <div
              v-if="
                id != getProjectCreatorId &&
                (canKick || (canKickInvited && invited_by_current_user))
              "
              @click="kickUser(users[index])"
            >
              <i class="fas fa-trash" title="Expulsar"></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </main>

  <section v-if="setModal">
    <Modal
      :setModal="setModal"
      :message_title="message_title"
      :message_body="message_body"
      :scope="scope"
      :item="item"
      @closeModal="closeModal"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '../views/Modal.vue'
import roles from '../services/lib/agentes'
import { setNotification } from '../services/filters/dreamCatcher'
import { hasPermission } from '../services/lib/rolesHelper'
export default {
  components: { Modal },
  props: ['users', 'roles'],
  data() {
    return {
      sortedUsers: false,
      setModal: null,
      userDpDownRef: 'userDpDownRef',
      //MODAL
      item: null,
      message_title: null,
      message_body: null,
      scope: '',
      showNotesIndex: null,
      targetRowRef: null,
      targetIconRef: null,
      selectedRole: null
    }
  },
  created() {
    //console.log('USERS', this.users)
    //console.log('ROLES', this.roles)
  },
  methods: {
    getRole(email) {
      const { email: loggedUser } = this.$store.state.user
      return email === loggedUser ? 'Administrador' : 'Membro'
    },
    getCompanyName(company_name) {
      return company_name ? company_name : 'Sem empresa'
    },
    getContact(contact) {
      return contact ? contact : 'Sem contacto'
    },
    sortUsers(propScoped) {
      if (this.sortedUsers) {
        this.users.sort((a, b) => (a[propScoped] < b[propScoped] ? 1 : -1))
        this.sortedUsers = false
      } else {
        this.users.sort((a, b) => (a[propScoped] > b[propScoped] ? 1 : -1))
        this.sortedUsers = true
      }
    },
    kickUser(user) {
      this.scope = 'danger'
      this.message_title = 'EXPULSAR UTILIZADOR'
      this.message_body = `Tem a certeza que pretende expulsar o utilizador ${user.name}?`
      this.item = user
      this.setModal = 'setConfirmChanges'
    },
    async assignRole(id, ref) {
      try {
        //console.log('VALUE', this.$refs[ref].value)
        const value = this.$refs[ref].value

        const data = {
          user_id: id,
          project_id: this.$store.state.project.id,
          role_id: this.roles.find((role) => role.name === value).id
        }

        const res = await roles.assignRole(data)
        if (res.status === 200) {
          setNotification('Role alterada com sucesso!', 'success')
        }
      } catch (error) {
        //console.log(error)
      }
    },
    logme(user1) {
      //console.log(user1)
    },
    closeModal() {
      this.setModal = null
      this.scope = null
    },
    getRoleToShow(role) {
      if (role === 'administrator') return 'Administrador'
      if (role === 'coordinator') return 'Coordenador'
      if (role === 'integrator') return 'Integrador'
      if (role === 'guest') return 'Convidado'
    }
  },
  computed: {
    ...mapGetters(['user']),
    canKick() {
      return hasPermission(this.user.role, 'kick user')
    },
    canKickInvited() {
      return hasPermission(this.user.role, 'kick invited user')
    },
    canChangeUserRole() {
      return hasPermission(this.user.role, 'change user role')
    },
    canChangeInvitedUserRole() {
      return hasPermission(this.user.role, 'change invited user role')
    },
    getProjectCreatorId() {
      return this.$store.state.project.creator_id
    }
  }
}
</script>

<style scoped>
.subtool-btn {
  width: 5rem;
}

td {
  vertical-align: middle;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: none;
  padding: 1rem;
}

.hidden {
  display: none;
}

.svg-inline--fa.fa-w-12 {
  width: 0.6em;
}

.fa-question-wrapper {
  display: flex;
  gap: 4px;
}

.fa-question-wrapper > div {
  transition: 200ms;
}

.fa-question-wrapper > div:hover {
  transform: rotateZ(20deg) translateY(-2px);
}
</style>
