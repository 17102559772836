<template>
  <div class="info-container">
    <div class="info">
      <h1>
        Bem Vindo,
        <br />
        ao Digi4Construction
      </h1>
      <p>
        O Digi4Construction é uma plataforma digital que integra e
        operacionaliza conteúdos técnicos e processuais para a digitalização da
        gestão do total ciclo de vida de edifícios e infraestruturas,
        funcionando sobre uma base uniformizada de frameworks parametrizáveis
      </p>
    </div>
    <div class="toRegister">
      <p>
        Ainda não tem conta? Registe-se
        <span
          class="link pointer"
          style="text-decoration: underline"
          @click="toRegister"
        >
          aqui
        </span>
        <!-- <router-link :to="{ name: 'Signup' }" class="link">aqui</router-link> -->
      </p>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['loadOut'],
  methods: {
    toRegister() {
      this.$emit('loadOut')
      setTimeout(() => {
        this.$router.push({ name: 'Signup' })
      }, 600)
    }
  }
}
</script>

<style scoped>
* {
  color: var(--text-color);
}
.info-container {
  position: relative;
  width: 70%;
  min-width: 400px;
  background: rgb(73, 76, 82);
  background: linear-gradient(
    118deg,
    rgb(0, 162, 255) -25%,
    #232728 70% /*
    #006699 0%,
    rgba(41, 40, 40, 1) 100%
    */
  );
  border-bottom-left-radius: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-inline: 5rem;
  overflow: auto;
}
.info {
  position: relative;
  width: 80%;
  top: 30%;
  align-self: center;
}
.info > h1 {
  line-height: 2.5rem;
  font-size: 2rem;
  font-weight: 600;
  text-align: left;
  letter-spacing: 2px;
}
.toRegister {
  text-align: right;
}

h1 + p {
  margin-top: 2rem;
  text-align: justify;
  direction: rtl;
}
</style>
