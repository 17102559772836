import apiClient from '../apiClient'
import { catchErr } from '../filters/dreamCatcher'
import store from '../../store/store'

export async function getAgentes(formData) {
  try {
    const endpoint = `jobs_by_template`

    const { data, status } = await apiClient.post(endpoint, formData)

    ////console.log('AGENTES FROM agentes.js: ', data)
    return { data, status }
  } catch (err) {
    //console.log(err)
  }
}

export async function getAllAgents() {
  try {
    const { id } = store.state.project
    const endpoint = `jobs_by_system/${id}`

    const { data, status } = await apiClient.get(endpoint)
    //console.log('AGENTES FROM getAllAgents(): ', data)

    return { data, status }
  } catch (err) {
    //console.log(err)
  }
}

export async function getAgentsByStage() {
  try {
    const { id: project_id } = store.state.project
    const stage_template_id = parseInt(store.state.stageIndex) + 1
    const endpoint = `jobs_by_task/${project_id}/${stage_template_id}`

    const { data, status } = await apiClient.get(endpoint)

    return { data, status }
  } catch (error) {
    //console.log(error)
    return { status: '405' }
  }
}
export async function getUsersByJob(formData) {
  //formData is project_id, job_id, stage_template_id (stage_value + 1)
  try {
    const endpoint = `select_user_to_task`

    const { data, status } = await apiClient.post(endpoint, formData)
    return { data, status }
  } catch (error) {
    //console.log(error)
    return { status: '405' }
  }
}

export async function postAgente(formData) {
  //form data properties: project_id, job_id, stage_template_id (stage_value + 1)
  try {
    const endpoint = 'create_job'
    const { data, status } = await apiClient.post(endpoint, formData)

    return { data, status }
  } catch (err) {
    //console.log(err)
  }
}

export async function postCustomAgent(formData) {
  //form data properties: project_id, title, stage_template_id (stage_value + 1)
  try {
    const endpoint = 'create_custom_job'
    const { data, status } = await apiClient.post(endpoint, formData)

    return { data, status }
  } catch (err) {
    //console.log(`Error on postCustomAgent: ${err}`)
  }
}

export async function updateAgent(formData) {
  //form data: id, user_id
  try {
    const endpoint = 'update_job'
    const { data, status } = await apiClient.post(endpoint, formData)

    //console.log('UPDATE AGENTS RES: ', data)
    return { data, status }
  } catch (err) {
    //console.log(`Error on updateAgent: ${err}`)
  }
}

export async function deleteAgente(job_id, job_pers_id) {
  try {
    const { id: project_id } = store.state.project
    const stage_template_id = parseInt(store.state.stageIndex) + 1

    //console.log(
    //   'FULL REQUEST: ',
    //   `delete_job/${project_id}/${job_id}/${stage_template_id}/${job_pers_id}`
    // )

    const endpoint = `delete_job/${project_id}/${job_id}/${stage_template_id}/${job_pers_id}`
    const { data, status } = await apiClient.delete(endpoint)

    if (status !== 200) return { status }

    return { data, status }
  } catch (err) {
    //console.log(err)
  }
}

export async function showUsers() {
  //will return only the id and users
  try {
    const { id: project_id } = store.state.project
    const endpoint = `select_user/${project_id}`

    const { data, status } = await apiClient.get(endpoint)

    return { data, status }
  } catch (err) {
    //console.log(`Error on showUsers: ${err}`)
  }
}

export default {
  async getStageAgentes() {
    try {
      const { id } = store.state.project
      const currentStage = parseInt(store.state.stageIndex) + 1 //provisorio
      const endpoint = `jobs_list/${id}/${currentStage}`

      const { data, status } = await apiClient.get(endpoint)

      if (status !== 200) {
        return
      }

      //console.log('AGENTES FROM agentes.js: ', data)
      store.dispatch('agentes', data)

      return status
    } catch (err) {
      //console.log(`erro em agentes.js: ${err}`)
    }
  },

  async getUsers() {
    try {
      const endpoint = 'users_by_project'
      const projectId = store.state.project.id

      const { data, status } = await apiClient.get(`${endpoint}/${projectId}`)

      if (status !== 200) {
        return
      }
      //console.log('users_by_project: ', data)
      store.dispatch('users', data)

      return true
    } catch (err) {
      //console.log(err)
      return 'houve um erro: ' + catchErr(err)
    }
  },

  async invite(data) {
    try {
      const endpoint = 'invite'
      const res = await apiClient.post(`${endpoint}`, data)

      return res
    } catch (err) {
      //console.log(`erro em agentes.js: ${err}`)
    }
  },

  async kickUser(data) {
    try {
      const endpoint = 'kickUser'
      const res = await apiClient.post(`${endpoint}`, data)

      return res
    } catch (err) {
      //console.log(`erro em agentes.js: ${err}`)
    }
  },

  async getRoles() {
    try {
      const endpoint = 'getAllRoles'

      const { data, status } = await apiClient.get(`${endpoint}`)

      if (status !== 200) {
        return
      }
      store.dispatch('roles', data)
      return true
    } catch (err) {
      //console.log(err)
      return 'houve um erro: ' + catchErr(err)
    }
  },

  async assignRole(data) {
    try {
      const endpoint = 'assignRole'
      const res = await apiClient.post(`${endpoint}`, data)

      return res
    } catch (err) {
      //console.log(`erro em agentes.js: ${err}`)
    }
  },

  async getUserRole(data) {
    try {
      const endpoint = 'getUserRole'
      const res = await apiClient.post(`${endpoint}`, data)

      return res
    } catch (err) {
      //console.log(`erro em agentes.js: ${err}`)
    }
  }
}
