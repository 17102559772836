<template>
  <!-- <section v-if="showLoading">
    <ShowLoading />
  </section> -->

  <section>
    <main class="content-wrapper" v-if="project">
      <div
        :class="[canViewProjectLifeCycle ? 'gen-details' : 'gen-details-perm']"
      >
        <GeneralDetails :project="project" />
      </div>
      <div class="stage-details" v-if="canViewProjectLifeCycle">
        <StageDetails :project="project" />
      </div>
    </main>
  </section>
</template>

<script>
import GeneralDetails from '../../components/project_details/GeneralDetails.vue'
import StageDetails from '../../components/project_details/StageDetails.vue'
import { hasPermission } from '../../services/lib/rolesHelper'
import { mapGetters } from 'vuex'
export default {
  components: { GeneralDetails, StageDetails },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['project', 'user']),
    canViewProjectLifeCycle() {
      return hasPermission(this.user.role, 'view project lifecycle details')
    }
  }
}
</script>

<style scoped>
.gen-details {
  position: absolute;
  height: 100%;
  width: 45%;
  left: 0;
  top: 0;
}

.gen-details-perm {
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
}

.stage-details {
  position: absolute;
  height: 100%;
  width: 55%;
  right: 0;
  bottom: 0;
  margin-right: 1rem;
  margin-left: 0.25rem;
}
</style>
