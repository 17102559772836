<template>
  <main v-if="!systems.length" class="list">
    <h1>Não foi encontrado nenhum sistema.</h1>
  </main>
  <main v-else class="list">
    <table class="table">
      <thead>
        <tr class="table-primary pointer">
          <th @click="sortSystems('system.code')">
            Classificação
            <div :class="sortedSystems ? 'arrow-up' : 'arrow-down'">
              <i class="fas fa-sort-down"></i>
            </div>
          </th>
          <th @click="sortSystems('system.name')">
            Sistema
            <div :class="sortedSystems ? 'arrow-up' : 'arrow-down'">
              <i class="fas fa-sort-down"></i>
            </div>
          </th>
          <th @click="sortSystems('job.title')">
            Responsável
            <div :class="sortedSystems ? 'arrow-up' : 'arrow-down'">
              <span class="arrow-wrapper">
                <i class="fas fa-sort-down"></i>
              </span>
            </div>
          </th>
          <th @click="sortSystems('lod_id')">
            LOD
            <div :class="sortedSystems ? 'arrow-up' : 'arrow-down'">
              <i class="fas fa-sort-down"></i>
            </div>
          </th>
          <th @click="sortSystems('loi_id')">
            LOI
            <div :class="sortedSystems ? 'arrow-up' : 'arrow-down'">
              <i class="fas fa-sort-down"></i>
            </div>
          </th>
          <th
            style="text-align: center; width: 150px"
            @click="sortSystems('system_state.state')"
          >
            Estado
            <div :class="sortedSystems ? 'arrow-up' : 'arrow-down'">
              <i class="fas fa-sort-down"></i>
            </div>
          </th>
          <th style="text-align: center" @click="sortSystems('system_date')">
            Data de Entrega
            <div :class="sortedSystems ? 'arrow-up' : 'arrow-down'">
              <i class="fas fa-sort-down"></i>
            </div>
          </th>
          <th colspan="3"></th>
        </tr>
      </thead>
      <tbody v-for="system in systems" :key="system.id" class="c-tbody">
        <tr
          class="linha"
          :ref="`l${system.id}`"
          :class="{
            'linha-verificacao':
              system.system_state.state === 'Para Verificação',
            'linha-concluido': system.system_state.state === 'Concluído'
          }"
          @click="logMe(system)"
        >
          <td>{{ system.system.code }}</td>
          <td @click="toggleNotes(system)">
            <span
              v-show="system.notes"
              :ref="`i${system.id}`"
              style="cursor: pointer"
            >
              <i class="fas fa-angle-up"></i>
            </span>
            {{ system.system.name }}
          </td>
          <td :title="getUser(system.user)">
            {{ getJob(system.job) }}
          </td>
          <td>
            <span class="lodloi">{{ system.lod_id }}</span>
          </td>
          <td>
            <span class="lodloi">{{ system.loi_id }}</span>
          </td>
          <td style="text-align: center">
            {{ system.system_state.state }}
          </td>
          <td style="text-align: center">
            <span>{{ getSystemDate(system.date) }}</span>
          </td>
          <td>
            <div
              @click="specifySystem(system)"
              v-show="
                canSpecifySystem && system.system.code.length <= codeLengthLimit
              "
            >
              <i class="fas fa-file-medical-alt" title="Especificar"></i>
            </div>
          </td>
          <td>
            <div v-if="canEditSystem" @click="updateSystem(system)">
              <i class="far fa-edit" title="Editar"></i>
            </div>
          </td>
          <td>
            <div
              v-if="
                canDeleteSystem ||
                (canDeleteCreatedSystem &&
                  getCurrentUserId == system.creator_id)
              "
              @click="deleteSystem(system)"
            >
              <i class="fas fa-trash" title="Apagar"></i>
            </div>
          </td>
        </tr>
        <tr>
          <td
            colspan="10"
            class="notes-container"
            v-show="showNotesIndex === system.id"
            :class="{
              'linha-verificacao':
                system.system_state.state === 'Para Verificação',
              'linha-concluido': system.system_state.state === 'Concluído'
            }"
          >
            <textarea
              class="notes"
              :class="{
                alt:
                  system.system_state.state === 'Para Verificação' ||
                  system.system_state.state === 'Concluído'
              }"
              :value="'Notas:\n' + system.notes"
            ></textarea>
          </td>
        </tr>
      </tbody>
    </table>
  </main>

  <section v-if="setModal">
    <Modal
      :setModal="setModal"
      :scope="scope"
      :message_title="message_title"
      :message_body="message_body"
      :item="item"
      @closeModal="closeModal"
    />
  </section>
</template>

<script>
import Modal from '../views/Modal.vue'
import { mapGetters } from 'vuex'
import { hasPermission } from '../services/lib/rolesHelper'
export default {
  name: 'Dashboard',
  components: { Modal },
  props: ['systems'],
  data() {
    return {
      sortedSystems: false,
      setModal: null,
      message_title: '',
      message_body: '',
      item: null,
      scope: '',
      showNotesIndex: null,
      targetRowRef: null,
      targetIconRef: null,
      codeLengthLimit: 11
    }
  },
  methods: {
    logMe(system) {
      //console.log(system)
    },
    getJob(job) {
      return job?.title ?? 'Por Delegar'
    },
    getUser(user) {
      return user?.name ?? 'Sem Utilizador Associado'
    },
    getSystemDate(system_date) {
      return system_date || '-'
    },
    updateSystem(system) {
      this.item = system
      this.setModal = 'updateSystem'
    },
    specifySystem(system) {
      this.item = system
      this.setModal = 'specifySystem'
    },
    deleteSystem(system) {
      const { code, name } = system.system
      //console.log(system)
      this.scope = 'danger'
      this.message_title = 'APAGAR SISTEMA'
      this.message_body = `Tem a certeza que pretende apagar o sistema "${code} - ${name}" ?`
      this.item = system
      this.setModal = 'setConfirmChanges'
    },

    closeModal() {
      this.setModal = null
      this.scope = null
      this.item = null
    },
    sortSystems(prop) {
      //console.log(prop)
      const [key, nestedKey] = prop.split('.')
      this.systems.sort((a, b) => {
        const valueA = a?.[key]?.[nestedKey] ?? a?.[key]
        const valueB = b?.[key]?.[nestedKey] ?? b?.[key]
        const sortOrder = this.sortedSystems ? -1 : 1

        return sortOrder * (valueA < valueB ? -1 : valueA > valueB ? 1 : 0)
      })
      this.sortedSystems = !this.sortedSystems
    },
    toggleNotes(system) {
      if (!system.notes) return

      const rowRef = `l${system.id}`
      const iconRef = `i${system.id}`
      const rowElement = this.$refs[rowRef]
      const iconElement = this.$refs[iconRef]

      if (this.showNotesIndex === system.id) {
        rowElement.classList.remove('hide-border')
        iconElement.classList.remove('rotate-icon')
        this.showNotesIndex = null
        return
      }

      if (this.targetRowRef) {
        const prevRowElement = this.$refs[this.targetRowRef]
        const prevIconElement = this.$refs[this.targetIconRef]
        prevRowElement.classList.remove('hide-border')
        prevIconElement.classList.remove('rotate-icon')
      }

      rowElement.classList.add('hide-border')
      iconElement.classList.add('rotate-icon')
      this.showNotesIndex = system.id
      this.targetRowRef = rowRef
      this.targetIconRef = iconRef
    }
  },
  computed: {
    ...mapGetters(['stageIndex', 'user']),
    canDeleteSystem() {
      return hasPermission(this.user.role, 'delete system')
    },
    canDeleteCreatedSystem() {
      return hasPermission(this.user.role, 'delete created system')
    },
    canSpecifySystem() {
      return hasPermission(this.user.role, 'specify system')
    },
    getCurrentUserId() {
      return this.user.id
    },
    canEditSystem() {
      return this.user.role == 'guest' ? false : true
    }
  }
}
</script>

<style scoped>
.lodloi {
  border: 1px solid var(--text-color);
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  transition: all 0.25s;
}

.lodloi:hover {
  background-color: var(--text-color);
}

select,
option {
  font-size: 0.8rem !important;
}

.date {
  pointer-events: none;
}

.notes-container {
  position: relative;
  height: max(5.5rem, 60px);
  padding: 0;
  margin: 0;
  box-shadow: var(--main-color) 0px 10px 10px -10px;
  isolation: isolate;
  /* border-bottom: 1px solid #ccc !important; */
  text-align: center;
}

.notes {
  position: relative;
  z-index: 1;
  width: 80%;
  padding-block: 4px;
  padding-inline: 8px;
  height: max(4.5rem, 50px);
  border-image: linear-gradient(
      to right,
      rgba(0, 140, 255, 0.4),
      rgba(0, 49, 139, 0.4)
    )
    1;
  /* background: rgb(233, 251, 255) !important;
  background: linear-gradient(
    90deg,
    rgba(233, 251, 255, 1) 29%,
    rgba(223, 240, 255, 1) 100%
  ) !important; */
  resize: none !important;
  user-select: none;
  pointer-events: none;
}

.hide-border {
  border-bottom: transparent;
}

.rotate-icon {
  display: inline-block;
  transform: rotate(180deg);
}

/* EFFECTS */

.linha:hover + tr {
  background: var(--bg-color);
}

.alt {
  background: rgb(255, 255, 255, 0.5);
}

.arrow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
