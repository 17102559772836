<template>
  <div class="page">
    <div class="container" ref="container">
      <RecoverPassForm @loadOut="loadOut" />
    </div>
  </div>
</template>

<script>
import RecoverPassForm from '../components/forms/auth/RecoverPassForm.vue'
export default {
  components: {
    RecoverPassForm
  },
  methods: {
    loadOut() {
      this.$refs.container.classList.add('loadOut')

      setTimeout(() => {
        this.$router.push({ name: 'Login' })
      }, 600)
    }
  }
}
</script>

<style scoped>
.container {
  position: relative;
  width: clamp(260px, 45vw, 45vw);
  height: clamp(260px, 47vh, 47vh);
  min-height: 400px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  padding: 0;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-image: url('https://images.unsplash.com/photo-1591955506264-3f5a6834570a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: load-in 400ms ease-in-out;
  animation-fill-mode: forwards;
}
.loadOut {
  animation: load-out 400ms ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes load-in {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes load-out {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-200%);
  }
}
</style>