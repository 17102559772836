<template>
  <main class="content-wrapper">
    <div class="title-wrapper">
      <h1 class="content-title">DASHBOARD</h1>
    </div>
    <section class="charts-container">
      <div class="loading-span-3" v-if="!overallData">
        <ShowLoading />
      </div>
      <div class="grid-span-3" v-else>
        <OverallChart :overallData="overallData" />
      </div>

      <div class="loading-span-1" v-if="!tasksSeries">
        <ShowLoading />
      </div>
      <div class="grid-span-1" v-else>
        <TasksChart :tasksSeries="tasksSeries" />
      </div>

      <div class="loading-span-1" v-if="!systemsSeries">
        <ShowLoading />
      </div>
      <div class="grid-span-1" v-else>
        <SystemsChart :systemsSeries="systemsSeries" />
      </div>

      <div class="loading-span-1" v-if="!entregsSeries">
        <ShowLoading />
      </div>
      <div class="grid-span-1" v-else>
        <EntregsChart :entregsSeries="entregsSeries" />
      </div>
    </section>

    <div class="btn-wrapper" @click="download">
      <p class="c-btn">Exportar Relatório</p>
    </div>
  </main>
  <section v-if="setModal">
    <Modal
      :setModal="setModal"
      :message_title="message_title"
      @closeModal="closeModal"
    />
  </section>
</template>

<script>
import OverallChart from '../charts/OverallChart.vue'
import TasksChart from '../charts/TasksChart.vue'
import SystemsChart from '../charts/SystemsChart.vue'
import EntregsChart from '../charts/EntregsChart.vue'
import Modal from '../Modal.vue'
import ShowLoading from '../../components/ShowLoading.vue'
import { setNotification } from '../../services/filters/dreamCatcher'
import {
  getOverallInfo,
  getTasksInfo,
  getSystemsInfo,
  getEntregsInfo
} from '../../services/lib/dashboard'
import { nextTick } from 'vue'
export default {
  components: {
    OverallChart,
    TasksChart,
    SystemsChart,
    EntregsChart,
    Modal,
    ShowLoading
  },
  data() {
    return {
      setModal: null,
      item: null,
      message_title: null,
      message_body: null,
      scope: '',
      //CHARTS
      overallData: null,
      tasksSeries: null,
      systemsSeries: null,
      entregsSeries: null
    }
  },
  methods: {
    closeModal() {
      this.setModal = null
    },
    download() {
      this.message_title = 'DOWNLOAD PDF'
      this.setModal = 'downloadPdf'
    },
    async overallDataCall(project_id, stage_template_id) {
      const formData = {
        project_id,
        stage_template_id
      }

      //console.log('DATA: ', formData)

      try {
        const { data, status } = await getOverallInfo(formData)
        //console.log('OVERALL CHART: ', data)

        if (status !== 200)
          throw new Error('Erro ao carregar planeamento geral')

        this.overallData = data
      } catch (error) {
        //console.log(error)
        setNotification('Erro ao carregar planeamento geral', 'error')
      }
    },
    async tasksInfoCall(project_id, stage_template_id) {
      const formData = {
        project_id,
        stage_template_id
      }

      //console.log('DATA: ', formData)

      try {
        const { data, status } = await getTasksInfo(formData)
        //console.log('TASKS CHART: ', data)

        if (status !== 200)
          throw new Error('Erro ao carregar dados das tarefas')

        this.tasksSeries = data
      } catch (error) {
        //console.log(error)
        setNotification('Erro ao carregar dados das tarefas', 'error')
      }
    },
    async systemsInfoCall(project_id) {
      const formData = { project_id }

      try {
        const { data, status } = await getSystemsInfo(formData)
        //console.log('SYSTEMS CHART: ', data)

        if (status !== 200)
          throw new Error('Erro ao carregar dados dos sistemas')

        this.systemsSeries = data
      } catch (error) {
        //console.log(error)
        setNotification('Erro ao carregar dados dos sistemas', 'error')
      }
    },
    async entregsInfoCall(project_id, stage_template_id) {
      const formData = { project_id, stage_template_id }

      try {
        const { data, status } = await getEntregsInfo(formData)
        //console.log('ENTREGS CHART: ', data)

        if (status !== 200)
          throw new Error('Erro ao carregar dados das entregas')

        this.entregsSeries = data
      } catch (error) {
        //console.log(error)
        setNotification('Erro ao carregar dados das entregas', 'error')
      }
    },
    cleanCharts() {
      this.overallData = null
      this.tasksSeries = null
      this.systemsSeries = null
      this.entregsSeries = null
    }
  },
  //watch this.$store.state.stageIndex
  watch: {
    '$store.state.stageIndex': {
      async handler() {
        //console.log(this.$store.state.stageIndex)
        const { project, stageIndex } = this.$store.state
        if (
          !project ||
          typeof stageIndex === 'null' ||
          typeof stageIndex === 'undefined'
        )
          return

        const { id: project_id } = project
        const stage_template_id = parseInt(stageIndex) + 1

        if (!!this.overallData) this.cleanCharts()

        await nextTick()
        await this.overallDataCall(project_id, stage_template_id)
        await this.tasksInfoCall(project_id, stage_template_id)
        await this.systemsInfoCall(project_id)
        await this.entregsInfoCall(project_id, stage_template_id)

        //console.log('STAGE CHANGED')
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.charts-container {
  position: relative;
  z-index: 2;
  gap: 0.5rem;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  justify-items: center;
  display: grid;
  overflow: auto;
  overflow-x: hidden;
}
.loading-span-3,
.loading-span-1 {
  position: relative;
  min-height: 33vmin;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 0.32rem;
}
.loading-span-3 {
  grid-column: span 3;
  margin-right: 4.5vw;
}
.loading-span-1 {
  grid-column: span 1;
  margin-right: 5rem;
}
.grid-span-3 {
  grid-column: span 3;
  position: relative;
  width: 97%;
  padding: 0.5rem;
  margin-top: 1rem;
}
.grid-span-1 {
  grid-column: span 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 0.5rem;
}

/* .btn {
  position: absolute;
  right: 6rem;
  top: 5%;
  background: var(--main-color);
  color: #e8f1f5;
  padding-block: 0.24rem;
  padding-inline: 0.4rem;
  transition: 200ms ease-in, transform 100ms ease;
}
.btn > p {
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  font-family: 'SulphurPoint-Bold';
  transition: inherit;
}
.btn:hover {
  box-shadow: 0 0 0 2px var(--highlight-color);
  background: var(--bg-color);
}
.btn:hover > p {
  color: var(--highlight-color);
} */

.btn-wrapper {
  position: absolute;
  right: 5.8%;
  top: 4%;
  width: max-content;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  border-radius: 2px;
  padding-inline: 8px;
  padding-block: 4px;
  color: #fff;
  background: var(--main-color);
  transition: 200ms;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.btn-wrapper:hover {
  color: var(--main-color);
  background: #fff;
  box-shadow: 0 0 0 2px var(--main-color);
}

.c-btn {
  padding: 0;
  margin: 0;
}

.btn-wrapper > p {
  font-family: 'Betatron';
  letter-spacing: 1px;
}

:deep(.chart-container) {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  border-radius: 0.32rem;
  background: rgb(248, 251, 252);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 5px;
}

:deep(.chart-header) {
  padding-block: 0.1rem;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background: var(--title-color);
}

:deep(.chart-header > h2) {
  letter-spacing: 1px;
  color: var(--bg-color);
}
</style>
