<template>
  <div class="form-title title-banner"><h2>NOTAS</h2></div>

  <article class="grid-container">
    <textarea
      maxlength="255"
      class="form-control grid-span-3"
      placeholder="Adicionar nota aqui..."
      v-model="notes"
    ></textarea>
    <label class="grid-span-3 remaining-chars">
      {{ remainingChars(notes) }}
    </label>

    <section class="grid-span-3">
      <div class="btn-group">
        <div class="my-btn-primary" @click="stageNotes()">
          <h5 class="prim-btn grid-span-2">Adicionar</h5>
        </div>
        <div class="my-btn-cancel" @click="closeModal">
          <h5 class="cancel-btn grid-span-1">Cancelar</h5>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import { calcChars } from '../../../../services/filters/dreamCatcher'
export default {
  props: ['notes'],
  emits: ['closeModal', 'stageNotes'],
  data() {
    return {
      newNotes: ''
    }
  },
  methods: {
    stageNotes() {
      this.newNotes = this.notes
      this.$emit('stageNotes', this.newNotes)
    },
    closeModal() {
      this.$emit('closeModal')
    },
    remainingChars(text) {
      let result = calcChars(text)
      return result
    }
  }
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  position: relative;
  width: 35rem;
  min-width: 450px !important;
}
.grid-span-3 {
  grid-column: span 3;
  margin-inline: 1rem;
  min-width: 239px;
}
.grid-span-2 {
  grid-column: span 2;
  margin-inline: 1rem;
}
.grid-span-1 {
  grid-column: span 1;
}
textarea {
  margin-top: 1rem;
  border: 1px solid #ddd;
  width: auto;
  height: 10rem;
  resize: none;
}
.btn-group {
  gap: 1rem;
  padding-top: -2rem !important;
}
.remaining-chars {
  text-align: right;
  position: relative;
  padding-right: 0.5rem;
  bottom: 2.5rem;
  color: #888;
  font-size: 0.7rem;
}
</style>