<template>
  <div class="welcome">
    <h1>Bem vindo, {{ user.name }}</h1>
    <h4>
      <i class="far fa-envelope fa-xs"></i>
      {{ user.email }}
    </h4>

    <div class="btn-wrapper" @click="importEmpreendimento">
      <p class="c-btn">Importar Empreendimento</p>
    </div>
  </div>

  <main v-if="loading" class="loading">
    <ShowLoading />
  </main>
  <main v-else class="flexbox-container">
    <section class="newproj pointer" @click="setModal = 'createProj'">
      <div class="projlayer"></div>
      <div class="title">
        <h1>+</h1>
        <h2>Novo Empreendimento</h2>
      </div>
      <div v-if="setModal">
        <Modal :setModal="setModal" @closeModal="closeModal" />
      </div>
    </section>
    <section v-for="(project, index) in projects" :key="project">
      <ListProjects
        :project="project"
        @toggleLoading="toggleLoading"
        @deleteSuccess="deleteSuccess(index)"
      />
    </section>
  </main>
</template>

<script>
import ListProjects from '../components/ListProjects.vue'
import projects from '../services/lib/projects'
import Modal from './Modal.vue'
import ShowLoading from '../components/ShowLoading.vue'
import { setNotification } from '../services/filters/dreamCatcher'
import { mapGetters } from 'vuex'
import auth from '../services/lib/auth'
import router from '../router'
// import DummyBridge from '../components/DummyBridge.vue'
// import DummyProjects from '../components/DummyProjects.vue'

export default {
  name: 'Dashboard',
  components: { ListProjects, Modal, ShowLoading },
  data() {
    return {
      projects: Object,
      setModal: null,
      loading: true
    }
  },
  async created() {
    const res = await projects.getProjects()

    if (res.status != 200) {
      setNotification('A sua sessão expirou. Irá ser redirecionado.', 'error')

      await new Promise((resolve) => setTimeout(resolve, 3000))

      this.$store.dispatch('reset')
      await router.push({ name: 'Login' })
    }

    this.projects = res.data
    this.toggleLoading()
  },
  methods: {
    deleteSuccess(index) {
      let updatedList = JSON.parse(JSON.stringify(this.projects))

      updatedList.splice(index, 1)
      this.projects = updatedList.flat()

      setNotification('Apagado com sucesso!', 'success')

      this.setModal = null
    },
    closeModal() {
      this.setModal = null
    },
    importEmpreendimento() {
      this.message_title = 'Import Empreendimento'
      this.setModal = 'import'
    },
    toggleLoading() {
      this.loading = !this.loading
      // //console.log('loading toggled')
    }
  },
  computed: {
    ...mapGetters(['user'])
  }
}
</script>

<style scoped>
.flexbox-container {
  position: absolute;
  bottom: 5%;
  left: 2.5%;
  width: 95%;
  height: 70%;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.project-img {
  position: absolute;
  width: 100%;
  height: 60%;
  top: 0;
  /* TEMP */
  background-image: var(--proj-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: white 0px -65px 10px -50px inset;
}

.project-view {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.project-info {
  position: absolute;
  width: 100%;
  height: 40%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.main-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline: 1rem;
  flex-wrap: wrap;
}

.sec-info {
  display: flex;
  flex-direction: column;
}

/* NEW PROJ */
.newproj {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: clamp(260px, 18rem, 765px);
  height: clamp(400px, 25rem, 950px);
  margin-inline: 1vw;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 15px 0px;
  border-radius: 0.5rem;
  position: relative;
  z-index: 0;
  margin-bottom: 2rem;
  /* background: rgba(255, 255, 255, 0.75); */
  background: var(--topnav-color);
  border: 2px solid rgba(255, 255, 255, 0.25);
  /* background: rgb(73, 76, 82);
  background: linear-gradient(
    118deg,
    rgba(73, 76, 82, 1) 0%,
    rgba(41, 40, 40, 1) 100%
  ); */
  transition: background-color 0.33s ease-in, opacity 0.33s ease-in,
    box-shadow 1s;
}

.newproj h1,
.newproj h2 {
  color: var(--text-color);
  /* color: var(--topnav-color); */
  opacity: 0.9;
}

.newproj:hover h1,
.newproj:hover h2 {
  opacity: 1;
}

.newproj:hover {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
  border: 3px solid rgba(255, 255, 255, 0.25);
  background-color: var(--main-color);
  opacity: 0.85;
}

h1 {
  font-size: 3vmax;
}

h1,
h2 {
  color: var(--text-color);
}

.projlayer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  transition: 0.5s;
  border-radius: 0.5rem;
}

.title {
  color: rgb(255, 255, 255);
  font-family: SulphurPoint-Light;
}

.welcome {
  position: absolute;
  top: 10%;
  left: 2.5%;
  width: 97.5%;
  border-left: 1vmin solid var(--transp-main-color);
  padding-left: 2vmin;
  background: rgba(255, 255, 255, 0.55);
  user-select: none;
}

.welcome h1 {
  color: var(--title-color);
  font-size: 5vmin;
}

.welcome h4,
.welcome h1 {
  text-align: left;
}
.btn-wrapper {
  width: max-content;
}

.btn-wrapper {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  border-radius: 2px;
  padding-inline: 8px;
  padding-block: 4px;
  color: #fff;
  background: var(--transp-main-color);
  transition: 200ms;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.btn-wrapper:hover {
  color: var(--transp-main-color);
  background: #fff;
  box-shadow: 0 0 0 2px var(--transp-main-color);
}

.c-btn {
  padding: 0;
  margin: 0;
}

.btn-wrapper > p {
  font-family: 'Betatron';
  letter-spacing: 1px;
}

.loading {
  position: absolute;
}
</style>
