import apiClient from '../apiClient'
import store from '../../store/store'
import router from '../../router'
import { catchErr } from '../filters/dreamCatcher'

export async function update_profile_pic(formData) {
  try {
    const endpoint = 'upload_user_avatar'
    const { data, status } = await apiClient.post(endpoint, formData)

    //console.log('RESPONSE FOR THE UPDATE PROFILE PIC: ', data, status)
    return { data, status }
  } catch (err) {
    //console.log(err)
  }
}

export async function delete_user_avatar() {
  try {
    const endpoint = 'delete_user_avatar'
    const { status } = await apiClient.post(endpoint)

    return { status }
  } catch (err) {
    //console.log(err)
  }
}

export async function update_user_details(formData) {
  try {
    const endpoint = 'update_user_details'
    const { data, status } = await apiClient.post(endpoint, formData)

    //console.log(data)
    return { data, status }
  } catch (err) {
    //console.log(err)
  }
}
export async function forgot_password(formData) {
  try {
    const endpoint = 'auth/forgotPassword'

    const { status } = await apiClient.post(endpoint, formData)

    return { status }
  } catch (err) {
    console.log(err)
  }
}

export async function reset_password(formData) {
  try {
    const endpoint = 'auth/resetPassword'

    const { status } = await apiClient.post(endpoint, formData)

    return { status }
  } catch (err) {
    console.log(err)
  }
}

export default {
  async clean() {
    await router.push('/')
    await store.dispatch('reset')
    //console.log('finished cleaning')
  },

  async register(data) {
    try {
      const res = await apiClient.post('/auth/register', data)
      // router.push('/login')
      if (res == null) {
        const failedRes = {
          status: 422
        }
        return failedRes
      } else {
        return res
      }
    } catch (err) {
      //console.log(err)
    }
  },

  async login(formData) {
    try {
      //console.log('LOGIN DATA: ', formData)
      const { data, status } = await apiClient.post('auth/login', formData)

      if (status == 200) {
        await store.dispatch('user', data.user)
        await store.dispatch('token', data.token)
      }

      return { status }
    } catch (err) {
      return 400
    }
  },

  // async login(data) {
  //   try {
  //     await buildAuthorizeEndpointAndRedirect()

  //     const res = await apiClient.post('auth/login', data)

  //     if (res.status === 200) {
  //       await getToken(store.state.verifier)
  //       await store.dispatch('verifier', null)
  //       await store.dispatch('user', res.data.data)
  //     }
  //   } catch (e) {
  //     //console.log(e)
  //     return 400
  //   }
  // },

  async logout() {
    try {
      this.clean()
      await apiClient.post('auth/logout')

      return '200'
    } catch (err) {
      this.clean()
      //console.log('Ocorreu um erro... ' + err)

      return catchErr(err)
    }
  }
}
