<template>
  <div class="title-banner">
    <h2>ADICIONAR TAREFA</h2>
  </div>

  <main class="add-task-grid">
    <div class="grid-span-6 item">
      <label>Nome da Tarefa:</label>
      <input
        type="text"
        placeholder="Nome da tarefa a atribuir..."
        v-model="taskName"
      />
    </div>

    <div class="grid-span-3 item">
      <label>Responsabilidade:</label>

      <select
        v-if="hasFilteredAgentes > 0"
        :disabled="
          getCurrentUserRole == 'integrator' && hasFilteredAgentes == 0
        "
        @change="setAgent"
        ref="agentesDpdownRef"
      >
        <option selected disabled hidden v-if="!agentes">
          {{ dpdwnLoadingString }}
        </option>
        <option v-for="{ id, job, user } in filteredAgentes" :key="id">
          <span>{{ getJobTitle(job) }} {{ getRelatedUser(user) }}</span>
        </option>
      </select>

      <select
        v-else
        :disabled="
          getCurrentUserRole == 'integrator' && hasFilteredAgentes == 0
        "
        @change="setAgent"
        ref="agentesDpdownRef"
      >
        <option selected disabled hidden v-if="!agentes">
          {{ dpdwnLoadingString }}
        </option>
        <option v-for="{ id, job, user } in agentes" :key="id">
          <span>{{ getJobTitle(job) }} {{ getRelatedUser(user) }}</span>
        </option>
      </select>
      <span
        class="red-text"
        v-if="getCurrentUserRole == 'integrator' && hasFilteredAgentes == 0"
      >
        Não tem nenhum agente associado!
      </span>
      <span
        class="red-text"
        v-if="getCurrentUserRole == 'integrator' && hasFilteredAgentes == 0"
      >
        Não pode adicionar uma tarefa.
      </span>
    </div>

    <section class="grid-span-3 item">
      <label style="margin-top: 0">Data de Entrega:</label>
      <input type="date" class="date" v-model="task_system_date" />
    </section>

    <div class="grid-span-6 item">
      <label>Requisitos de Informação:</label>
      <textarea
        type="text"
        placeholder="Ex: Propostas de Projeto"
        maxlength="255"
        v-model="task_requirement"
      />
    </div>

    <div class="grid-span-6 item">
      <label>Notas:</label>
      <textarea
        type="text"
        placeholder="Deixe uma nota..."
        maxlength="255"
        v-model="task_notes"
      />
    </div>

    <section class="grid-span-6">
      <div class="flex-row">
        <div
          :class="[
            (taskName &&
              getCurrentUserRole == 'integrator' &&
              hasFilteredAgentes != 0) ||
            (taskName && getCurrentUserRole != 'integrator')
              ? 'my-btn-primary'
              : 'my-btn-hidden'
          ]"
          @click="handleClick()"
        >
          <h5 class="prim-btn grid-span-5">Adicionar</h5>
        </div>
        <div class="my-btn-cancel" @click="closeModal">
          <h5 class="cancel-btn grid-span-1">Cancelar</h5>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { setNotification } from '../../../../services/filters/dreamCatcher'
import { getAgentsByStage } from '../../../../services/lib/agentes'
import tasks from '../../../../services/lib/tasks'
import { mapGetters } from 'vuex'
import { hasPermission } from '../../../../services/lib/rolesHelper'

export default {
  emits: ['closeModal'],
  data() {
    return {
      //task properties
      taskName: '',
      job_id: null,
      task_requirement: '',
      task_notes: '',
      task_system_date: '',
      user_id: null,
      //REFS
      agentesDpdownRef: 'agentesDpdownRef',
      //dropdown
      dpdwnLoadingString: 'A Processar...',
      agentes: null,
      filteredAgentes: null
    }
  },
  async created() {
    const { data } = await getAgentsByStage()
    this.agentes = data
    //console.log('AGENTES :', this.agentes)

    if (this.user.role == 'integrator') {
      this.filteredAgentes = this.agentes.filter((item) => {
        return item.user && item.user.id == this.user.id
      })
    }

    this.setJob()
  },
  computed: {
    ...mapGetters(['user']),
    getCurrentUserID() {
      return this.user.id
    },
    hasFilteredAgentes() {
      return this.filteredAgentes == null ? 0 : this.filteredAgentes.length
    },
    getCurrentUserRole() {
      return this.user.role
    }
  },

  methods: {
    getJobTitle(job) {
      if (!job) return ''
      return `${job.title}`
    },
    getRelatedUser(user) {
      if (!user) return ''
      return `- ${user.name}`
    },
    getRelatedUserId(user) {
      return user ? user.id : null
    },
    async setAgent() {
      const { value } = this.$refs[this.agentesDpdownRef]
      //console.log('VALUE: ', value)
      if (value === this.dpdwnLoadingString || !value) return

      this.setJob()
    },
    setJob() {
      const { selectedIndex } = this.$refs[this.agentesDpdownRef]
      //console.log(selectedIndex)

      this.job_id = this.agentes[selectedIndex].job.id
      this.user_id = this.agentes[selectedIndex].user?.id || null

      //console.log('JOB ID: ', this.job_id)
      //console.log('USER ID: ', this.user_id)
    },
    async handleClick() {
      const formData = {
        name: this.taskName,
        job_id: this.job_id ? this.job_id : this.agentes[0].id,
        stage_template_id: parseInt(this.$store.state.stageIndex + 1),
        task_notes: this.task_notes,
        task_deliv_date: this.task_system_date,
        task_requirement: this.task_requirement,
        user_id: this.user_id
      }

      //console.log(formData)

      try {
        const { data, status } = await tasks.addTask(formData)

        if (status === 200) {
          this.$store.dispatch('addTask', data[0])

          setNotification('Adicionado com sucesso!', 'success')
          this.closeModal()
        }
      } catch (error) {
        //console.log('error on AddTask')
        setNotification('Ocorreu um erro...', 'error')
        this.closeModal()
      }
    },
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped>
.red-text {
  color: red;
  text-align: center;
  font-size: 14px;
}

.add-task-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  padding-top: 0.5rem;
}

.grid-span-6 {
  grid-column: span 6;
  margin-inline: 1rem;
}

.grid-span-5 {
  grid-column: span 5;
}

.grid-span-4 {
  grid-column: span 4;
  margin-inline: 1rem;
}

.grid-span-3 {
  grid-column: span 3;
  margin-inline: 1rem;
}

.grid-span-2 {
  grid-column: span 2;
  margin-inline: 1rem;
}

.grid-span-1 {
  grid-column: span 1;
  text-align: center;
}

.item {
  display: flex;
  flex-direction: column;
}

.item-row {
  display: flex;
  gap: 0.5rem;
}

.flex-row {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

select,
option,
input,
textarea {
  border: 1px solid lightgrey;
  border-radius: 0.25rem;
  outline: none;
}

select,
option,
input,
textarea {
  padding: 0.5rem;
}

textarea {
  height: 100px;
  resize: none;
}

label {
  margin-bottom: 0.25rem;
}

p {
  padding: 0;
  margin: 0;
  line-height: 100%;
}

select {
  cursor: pointer;
}
</style>
