<template>
  <main class="modal-wrapper">
    <div class="title-banner">
      <h2>Informações sobre as permissões</h2>
    </div>
    <div
      v-for="role in roles"
      :key="role.title"
      @click="toggleActive(role)"
      class="subtitle"
    >
      <div :class="role.isToggle ? 'arrow-down' : 'arrow-up'">
        <i class="fas fa-sort-down"></i>
      </div>
      {{ role.title }}
      <div v-if="role.isToggle" class="info">
        <div v-for="cont in getContent(role)" :key="cont">{{ cont }}</div>
      </div>
    </div>
    <div class="grid-span-3 flex-row">
      <div class="my-btn-cancel" @click="closeModal">
        <h5 class="cancel-btn grid-span-1">Voltar</h5>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  emits: ['closeModal'],
  data() {
    return {
      roles: [
        {
          title: 'Administrador',
          content: [
            '- Apagar Empreendimento',
            '- Visualizar Detalhes Gerais e Informação Adicional',
            '- Visualizar Detalhes do Ciclo de Vida',
            '- Editar Detalhes do Empreendimento',
            '- Transitar Fase atual do Empreendimento',
            '- Adicionar Notas de Fase',
            '- Convidar novo Participante',
            '- Expulsar Participante',
            '- Alterar Perfil de Permissões de um Participante',
            '- Visualizar listagem de Participantes',
            '- Visualizar listagem de Agentes',
            '- Adicionar nova Função',
            '- Eliminar Função',
            '- Alocar Participante a Função',
            '- Substituir Participante alocado a Função',
            '- Visualizar listagem de Tarefas',
            '- Adicionar nova Tarefa',
            '- Editar detalhes de Tarefa',
            '- Adicionar/Editar Notas da Tarefa',
            '- Eliminar Tarefa',
            '- Alocar Responsável a Tarefa',
            '- Editar Estado da Tarefa',
            '- Definir/Editar Data de Entrega da Tarefa',
            '- Visualizar listagem de Sistemas',
            '- Adicionar Sistema',
            '- Eliminar Sistema',
            '- Alocar Responsável a Sistema',
            '- Editar Estado do Sistema',
            '- Definir/Editar Data de Entrega do Sistema',
            '- Definir/Editar LOD e LOI',
            '- Especificar Sistema',
            '- Visualizar listagem de Entregáveis',
            '- Adicionar Entregável',
            '- Eliminar Entregável',
            '- Editar Estado do Entregável',
            '- Editar detalhes do Entregável'
          ],
          isToggle: false
        },
        {
          title: 'Coordenador',
          content: [
            '- Visualizar Detalhes Gerais e Informação Adicional',
            '- Visualizar Detalhes do Ciclo de Vida\n- Adicionar Notas de Fase',
            '- Adicionar Notas de Fase',
            '- Convidar novo Participante',
            '- Expulsar Participante Convidado por ele mesmo',
            '- Alterar Perfil de Permissões de um Participante Convidado por ele mesmo (à excepção do perfil administrador)',
            '- Visualizar listagem de Participantes',
            '- Visualizar listagem de Agentes',
            '- Adicionar nova Função',
            '- Eliminar Função Criada por ele mesmo',
            '- Alocar Participante a Função Criada por ele mesmo',
            '- Substituir Participante alocado a Função Criada por ele mesmo',
            '- Visualizar listagem de Tarefas',
            '- Adicionar nova Tarefa',
            '- Editar detalhes de Tarefa',
            '- Adicionar/Editar Notas da sua Tarefa ou de Tarefa Criada por ele mesmo',
            '- Eliminar Tarefa Criada por ele mesmo',
            '- Alocar Responsável a Tarefa Criada por ele mesmo',
            '- Editar Estado da Tarefa Criada por ele mesmo ou a Tarefas de Participantes Convidados por ele mesmo',
            '- Definir/Editar Data de Entrega da Tarefa Criada por ele mesmo ou a Tarefas de Participantes Convidados por ele mesmo',
            '- Visualizar listagem de Sistemas',
            '- Adicionar Sistema',
            '- Eliminar Sistema Adicionado por ele mesmo',
            '- Alocar Responsável a Sistema Adicionado por ele mesmo',
            '- Editar Estado do Sistema Adicionado por ele mesmo',
            '- Definir/Editar Data de Entrega do Sistema Adicionado por ele mesmo',
            '- Definir/Editar LOD e LOI',
            '- Especificar Sistema',
            '- Visualizar listagem de Entregáveis',
            '- Adicionar Entregável',
            '- Eliminar Entregável Adicionado por ele mesmo',
            '- Editar Estado do Entregável Adicionado por ele mesmo',
            '- Editar detalhes do Entregável Adicionado por ele mesmo'
          ],
          isToggle: false
        },
        {
          title: 'Integrador',
          content: [
            '- Visualizar Detalhes Gerais e Informação Adicional',
            '- Visualizar Detalhes do Ciclo de Vida',
            '- Visualizar listagem de Participantes',
            '- Visualizar listagem de Agentes',
            '- Visualizar listagem de Tarefas',
            '- Adicionar nova Tarefa para ele mesmo',
            '- Editar detalhes de Tarefa Criada por ele mesmo',
            '- Adicionar/Editar Notas da sua Tarefa',
            '- Eliminar Tarefa Criada por ele mesmo',
            '- Editar Estado da sua Tarefa de "Em Execução" para "Para Verificação"',
            '- Visualizar listagem de Sistemas',
            '- Editar Estado do Sistema Adicionado por ele mesmo',
            '- Visualizar listagem de Entregáveis',
            '- Editar Estado do seu Entregável de "Em Execução" para "Para Verificação"'
          ],
          isToggle: false
        },
        {
          title: 'Convidado',
          content: [
            '- Visualizar Detalhes Gerais e Informação Adicional',
            '- Visualizar listagem de Agentes',
            '- Visualizar listagem de Tarefas',
            '- Visualizar listagem de Sistemas',
            '- Visualizar listagem de Entregáveis'
          ],
          isToggle: false
        }
      ]
    }
  },
  methods: {
    getContent(role) {
      return role.content
    },
    closeModal() {
      this.$emit('closeModal')
    },
    toggleActive(role) {
      this.roles.forEach((r) => {
        if (r !== role && r.isToggle) {
          r.isToggle = false
        }
      })
      role.isToggle = !role.isToggle
    }
  }
}
</script>

<style scoped>
.modal-wrapper {
  width: 600px;
  max-height: 90vh;
  overflow: auto;
}
.subtitle {
  margin-left: 1rem;
  margin-top: 0.3rem;
  margin-right: 1rem;
}

.info {
  margin-left: 0.8rem;
  margin-top: 0.3rem;
  font-size: 0.65rem;
}

.grid-span-1 {
  grid-column: span 1;
  text-align: center;
}

.flex-row {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

label {
  margin-bottom: 0.25rem;
}
.my-btn-cancel {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: 200ms;
}
.my-btn-cancel:hover {
  letter-spacing: 1px;
}
</style>
