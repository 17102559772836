<template>
  <main class="content-wrapper">
    <div class="title-wrapper">
      <h1 class="content-title">SISTEMAS</h1>
    </div>
    <section class="subtool">
      <div class="btn-container">
        <SearchBar
          :results="`${systems.length} de ${systemsLength}`"
          @inputValueChange="filterSystems"
        />
        <div v-if="canAddSystem" class="subtool-btn" @click="setModal = 'addSystem'">
          <h5>Adicionar Sistema</h5>
        </div>
      </div>
    </section>

    <div v-if="showLoading">
      <ShowLoading />
    </div>
    <div v-else class="list-container">
      <ListSystems :systems="computedSystems" />
    </div>
  </main>

  <section v-if="setModal">
    <Modal :setModal="setModal" @closeModal="closeModal" />
  </section>
</template>

<script>
import ListSystems from '../../components/ListSystems.vue'
import systems from '../../services/lib/systems'
import ShowLoading from '../../components/ShowLoading.vue'
import SearchBar from '../../components/SearchBar.vue'
import Modal from '../Modal.vue'
import { mapGetters } from 'vuex'
import { hasPermission } from '../../services/lib/rolesHelper'
export default {
  name: 'Dashboard',
  components: { ListSystems, ShowLoading, SearchBar, Modal },
  data() {
    return {
      setModal: null,
      debounce: null,
      systems: [],
      systemsLength: 0,
      searchedString: ''

      // STAGEINDEX E DESNECESSARIO 18/05/2023
    }
  },
  async created() {
    await systems.getSystems()
  },
  computed: {
    ...mapGetters(['showLoading', 'stageIndex', 'user']),
    computedSystems() {
      return this.systems.filter((system) => {
        return (
          system.system.name
            .toLowerCase()
            .match(this.searchedString.toLowerCase()) ||
          system.system.code
            .toLowerCase()
            .match(this.searchedString.toLowerCase()) ||
          system.job?.title
            .toLowerCase()
            .match(this.searchedString.toLowerCase()) ||
          system.system_state.state
            .toLowerCase()
            .match(this.searchedString.toLowerCase())
        )
      })
    },
    canAddSystem() {
      return hasPermission(this.user.role, 'add system')
    }
  },
  methods: {
    closeModal() {
      this.setModal = null
    },
    filterSystems(searched) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchedString = searched
      }, 350)
    }
  },
  watch: {
    '$store.state.systems': async function () {
      if (!this.$store.state.systems) return //need this to avoid state conflict
      this.systems = await this.$store.state.systems
      this.systemsLength = this.systems.length

      this.$store.dispatch('showLoading', false)
    },
    'systems.length': function () {
      if (!this.systems) return //need this to avoid state conflict
      this.systemsLength = this.systems.length
    }
  },
  unmounted() {
    this.$store.dispatch('cleanView', 'systems')
  }
}
</script>

<style scoped></style>
