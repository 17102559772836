import apiClient from '../apiClient'
import { catchErr } from '../filters/dreamCatcher'
import store from '../../store/store'

export async function search_system(message) {
  try {
    const formData = {
      search_item: message,
      project_id: store.state.project.id
    }

    const endpoint = `search_system`
    const { data, status } = await apiClient.post(endpoint, formData)

    //console.log('SEARCH RESULTS: ', data, status)
    return { data, status }
  } catch (err) {
    //console.log(err)
    return 'houve um erro: ' + catchErr(err)
  }
}

export async function add_system_parent(formData) {
  try {
    const endpoint = 'add_system_parent'

    const { data, status } = await apiClient.post(endpoint, formData)
    //console.log('ADD SYSTEM PARENT: ', data, status)

    return { data, status }
  } catch (err) {
    //console.log(err)
  }
}

export async function add_system_child(formData) {
  try {
    const endpoint = 'add_system_child'

    const { data, status } = await apiClient.post(endpoint, formData)
    //console.log('ADD SYSTEM PARENT: ', data, status)

    return { data, status }
  } catch (err) {
    //console.log(err)
  }
}

export async function update_system(formData) {
  try {
    const endpoint = 'update_system'

    const { data, status } = await apiClient.post(endpoint, formData) //data is an array
    //console.log('UPDATED SYSTEM: ', data, status)

    return { data, status }
  } catch (err) {
    //console.log(err)
    return { status: 404 }
  }
}

export async function get_children_systems(formData) {
  try {
    const endpoint = 'select_children'

    const { data, status } = await apiClient.post(endpoint, formData)
    //console.log('CHILDREN SYSTEMS: ', data, status)

    return { data, status }
  } catch (err) {
    //console.log(err)
    return { status: 404 }
  }
}

export async function specify_system(formData) {
  try {
    const endpoint = 'specify_system'

    const { data, status } = await apiClient.post(endpoint, formData)
    //console.log('SPECIFIED SYSTEM/S: ', data, status)

    return { data, status }
  } catch (err) {
    //console.log(err)
    return { status: 404 }
  }
}

export async function delete_system(system_id) {
  try {
    const endpoint = `delete_system/${system_id}`

    const { status } = await apiClient.delete(endpoint)

    return { status }
  } catch (err) {
    //console.log(err)
    return { status: 404 }
  }
}

export default {
  async getSystems() {
    try {
      const { id } = store.state.project
      const endpoint = `systems_list/${id}`

      const { data, status } = await apiClient.get(endpoint)

      //console.log('SYSTEMS: ', data)
      store.dispatch('systems', data)
      return { data, status }
    } catch (err) {
      //console.log('getSystems err on systems.js: ', err)
      return
    }
  }
}
