<template>
  <div class="form-title title-banner">
    <h2>TRANSFERIR RELATÓRIO</h2>
  </div>
  <section v-if="showLoading" class="showLoading">
    <ShowLoading style="position: relative; left: 0; top: 0" />
  </section>
  <section v-else class="creation-form">
    <div v-show="x">
      <h5 class="pointer">
        <span><i class="fas fa-chevron-right"></i></span>
        Tipo de Relatório
      </h5>
      <div class="section">
        <Field name="plataform">
          <select v-model="plataform" class="form-select">
            <option disabled hidden value="Relatorio">
              Escolha o tipo de relatório
            </option>
            <option value="Details">Ficha Técnica do Empreendimento</option>
            <option value="DetailsAgent">Comunicação Prévia</option>
            <option value="All">Compilação Técnica da Obra</option>
            <option value="Stages">
              Compilação Técnica da Obra com escolha de Fases
            </option>
            <option value="Finish">
              Compilação Técnica da Obra por estado
            </option>
          </select>
        </Field>
      </div>
      <hr />
      <div class="btn-group">
        <button class="confirmar" @click="chooseDownload">Transferir</button>
        <span class="cancelbtn" @click="closeModal">Cancelar</span>
      </div>
    </div>
    <div v-show="fases">
      <div class="sec-title">
        <h3>&nbsp;Que fase/fases deseja que estejam no Relatório</h3>
      </div>
      <br />
      <div
        v-for="(values, index) in stages"
        :key="index"
        @click="handleSection(index)"
      >
        <input
          type="checkbox"
          :id="'checkbox' + index"
          :checked="values.isChecked"
        />
        <label :for="'checkbox' + index">
          <h5>&nbsp;{{ values.stage_value }} - {{ values.stage_name }}</h5>
        </label>
      </div>
      <hr />
      <div class="btn-group">
        <button class="confirmar" @click="defineStages">Transferir</button>
        <span class="cancelbtn" @click="closeModal">Cancelar</span>
      </div>
    </div>
  </section>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import ShowLoading from '../../ShowLoadingText.vue'
import {
  show,
  details,
  detailsAgents,
  state
} from '../../../services/lib/pdf.js'
import { mapGetters } from 'vuex'

export default {
  components: { Form, Field, ErrorMessage, ShowLoading },
  emits: ['closeModal'],
  data() {
    return {
      showLoading: false,
      plataform: 'Relatorio',
      x: true,
      fases: false,
      selectedStages: []
    }
  },
  computed: mapGetters(['stages']),
  methods: {
    async handleSubmit() {
      try {
        this.showLoading = !this.showLoading
        switch (res.status) {
          case 200:
            await this.$router.push({ name: 'Dashboard' })
            break
          default:
            //console.log('defaulted')
            this.showLoading = !this.showLoading
            break
        }
      } catch (err) {
        this.showLoading = !this.showLoading
        //console.log(err)
      }
    },
    closeModal() {
      this.$emit('closeModal')
    },
    async chooseDownload() {
      if (this.plataform === 'All') {
        try {
          this.showLoading = !this.showLoading
          const stageCount = this.stages.length
          const stageNumbers = Array.from(
            { length: stageCount },
            (_, index) => index + 1
          )
          const stagesString = stageNumbers.join(',')
          const x = await show(stagesString)
          this.closeModal('closeModal')
          return x
        } catch (error) {
          //console.log(error)
        }
      } else if (this.plataform === 'Details') {
        this.showLoading = !this.showLoading
        const stageCount = this.stages.length
        const stageNumbers = Array.from(
          { length: stageCount },
          (_, index) => index + 1
        )
        const stagesString = stageNumbers.join(',')
        await details(stagesString)
        this.closeModal('closeModal')
      } else if (this.plataform === 'Stages') {
        this.x = false
        this.fases = true
      } else if (this.plataform === 'DetailsAgent') {
        this.showLoading = !this.showLoading
        const stageCount = this.stages.length
        const stageNumbers = Array.from(
          { length: stageCount },
          (_, index) => index + 1
        )
        const stagesString = stageNumbers.join(',')
        await detailsAgents(stagesString)
        this.closeModal('closeModal')
      } else if (this.plataform === 'Finish') {
        this.showLoading = !this.showLoading
        const stageCount = this.stages.length
        const stageNumbers = Array.from(
          { length: stageCount },
          (_, index) => index + 1
        )
        const stagesString = stageNumbers.join(',')
        await state(stagesString)
        this.closeModal('closeModal')
      }
    },
    async defineStages() {
      this.showLoading = !this.showLoading
      const stagesString = this.selectedStages.join(',')
      const x = await show(stagesString)
      this.stages.forEach((stage) => {
        stage.isChecked = false
      })
      this.closeModal('closeModal')
      return x
    },
    handleSection(index) {
      const stage = this.stages[index]
      stage.isChecked = !stage.isChecked

      if (stage.isChecked) {
        this.selectedStages.push(index + 1)
      } else {
        const selectedIndex = this.selectedStages.indexOf(index + 1)
        if (selectedIndex !== -1) {
          this.selectedStages.splice(selectedIndex, 1)
        }
      }
      this.selectedStages.sort((a, b) => a - b)
    }
  }
}
</script>

<style scoped>
.btn-group {
  display: flex;
  justify-content: flex-end;
}

.confirmar,
.cancelbtn {
  border: none;
  background-color: var(--text-color);
  font-size: 0.9vmax;
  padding-block: 0.5rem !important;
  padding: 1em;
  text-align: center;
  border-radius: 0.25rem;
}

.cancelbtn:hover {
  filter: brightness(0.9);
  cursor: pointer;
}
.confirmar:hover {
  filter: brightness(1.2);
  cursor: pointer;
}

.confirmar {
  background-color: var(--main-color);
  color: var(--text-color);
  margin-right: 1rem;
}

.form-title {
  position: inherit;
  background-color: var(--main-color);
  width: 100%;
  height: 100%;
  padding-inline: 1rem;
}

.creation-form {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.showLoading {
  position: relative;
  width: 100%;
  height: 13.33vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sec-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
}

.sec-title > h3 {
  border-left: 5px solid var(--main-color);
}

.form-select {
  cursor: pointer;
}
</style>
