import apiClient from '../../apiClient'

export async function getAditionalInfo() {
  try {
    const rawres = await apiClient.get(`/drops`)

    const res = rawres.data
    let propertyName
    let funcaoEmpreendimento = []
    let tipoIntervencao = []
    let metodoContratual = []
    let unidadeCicloVida = []

    res.forEach((item) => {
      propertyName = Object.getOwnPropertyNames(item)
      switch (propertyName[1]) {
        case 'function_name':
          funcaoEmpreendimento.push(item)
          break
        case 'type_name':
          tipoIntervencao.push(item)
          break
        case 'proc_name':
          metodoContratual.push(item)
          break
        case 'unit_name':
          unidadeCicloVida.push(item)
          break
        default:
          break
      }

      // if (propertyName[0] === 'value_life_cycle') {
      //   lifeCycleInput = item.value_life_cycle
      // }
    })
    return {
      funcaoEmpreendimento,
      tipoIntervencao,
      metodoContratual,
      unidadeCicloVida
    }
  } catch (error) {
    //console.log(error)
  }
}

export const updateProjectDetails = async (data, projectId) => {
  try {
    const { status } = await apiClient.post(
      `/update_project_details/${projectId}`,
      data
    )
    return status
  } catch (err) {
    //console.log(err);
  }
}

export const uploadFile = async (projectId, file) => {
  const res = await apiClient.post(`/upload_project_image/${projectId}`, file, {
    onUploadProgress: (uploadEvent) => {
      //console.log(
      //   'Upload Progress: ' +
      //     Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
      //     '%'
      // )
    }
  })

  //console.log(res)
  return res
}
