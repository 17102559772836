<template>
  <main
    :class="[
      canEditDetails
        ? 'general-details-container'
        : 'general-details-container-cant-edit'
    ]"
  >
    <div class="gen-details-title">
      <h2 class="prim-title">&nbsp;DETALHES DO EMPREENDIMENTO</h2>
      <div
        v-if="canEditDetails"
        :class="{
          'show-btn': formEdited,
          'hide-btn':
            !projectInfo.ref ||
            !projectInfo.descrip ||
            !projectInfo.project_name ||
            !projectInfo.detail.location
        }"
        class="my-btn-primary"
        @click="handleClick"
      >
        <h5>Guardar</h5>
      </div>
    </div>

    <section v-if="showLoading">
      <ShowLoading />
    </section>
    <section
      v-else
      :class="[
        canViewLifecycleDetails
          ? 'general-details-grid'
          : 'general-details-grid-cant-view'
      ]"
    >
      <div class="grid-span-1">
        <div class="flex-item">
          <label>
            Referência
            <span style="color: salmon" title="Campo Obrigatório">*</span>
          </label>
          <input
            type="text"
            ref="referencia"
            class="form-control"
            v-model="projectInfo.ref"
            :class="{ 'form-error': !projectInfo.ref }"
          />
          <label v-if="!projectInfo.ref" class="err">Campo obrigatório</label>
        </div>
      </div>
      <div class="grid-span-3">
        <div class="flex-item">
          <label>
            Título
            <span style="color: salmon" title="Campo Obrigatório">*</span>
          </label>
          <input
            type="text"
            ref="titulo"
            class="form-control"
            v-model="projectInfo.project_name"
            :class="{ 'form-error': !projectInfo.project_name }"
          />
          <label v-if="!projectInfo.project_name" class="err">
            Campo obrigatório
          </label>
        </div>
      </div>
      <div class="grid-span-4">
        <div class="flex-item">
          <label>
            Descrição
            <span style="color: salmon" title="Campo Obrigatório">*</span>
          </label>
          <textarea
            maxlength="255"
            rows="4"
            ref="descricao"
            class="form-control"
            v-model="projectInfo.descrip"
            :class="{ 'form-error': !projectInfo.descrip }"
          ></textarea>
        </div>
        <div style="display: flex; justify-content: space-between">
          <label class="remainingChars">
            Caracteres restantes: {{ remainingChars(projectInfo.descrip) }}
          </label>
          <label v-if="!projectInfo.descrip" class="err">
            Campo obrigatório
          </label>
        </div>
      </div>
      <div class="grid-span-4" v-if="canEditDetails">
        <div class="flex-item">
          <label>Carregar Imagem</label>
          <input
            type="file"
            class="form-control"
            accept="image/*"
            ref="uploader"
            @change="handleFileSelect"
          />
        </div>
      </div>

      <div class="grid-span-4">
        <div class="flex-item">
          <label>Localização</label>
          <div class="flex-row">
            <input
              type="text"
              class="form-control"
              style="padding-right: 5.33rem; width: 100%"
              v-model="projectInfo.detail.location"
              @input="autocomplete"
              @keyup.enter="searchLocation"
              @click="clearLocation"
              @blur="resetLocation"
            />
            <div class="search-container" @click="searchLocation">
              <p>Procurar</p>
            </div>
          </div>
        </div>
        <div class="map">
          <GoogleMaps :key="propSearch" :propSearch="propSearch" />
        </div>
      </div>

      <!-- ADD INFO -->

      <div class="grid-span-4">
        <h3 class="sec-title">&nbsp;INFORMAÇÃO ADICIONAL</h3>
      </div>
      <div class="grid-span-2">
        <div class="flex-item">
          <label>Função do Empreendimento</label>
          <select
            class="form-select"
            ref="funcao"
            @change="selectValue('funcao', $event)"
          >
            <option selected disabled hidden>
              {{ funcaodoempreendimento.function_name }}
            </option>
            <option
              v-for="funcao in funcaoEmpreendimento"
              :key="funcao"
              :id="funcao.id"
            >
              {{ funcao.function_name }}
            </option>
          </select>
        </div>
      </div>
      <div class="grid-span-2">
        <div class="flex-item">
          <label>Tipo de Intervenção</label>
          <select
            class="form-select"
            ref="intervencao"
            @change="selectValue('intervencao', $event)"
          >
            <option selected disabled hidden>
              {{ tipodeintervencao.type_name }}
            </option>
            <option
              v-for="intervencao in tipoIntervencao"
              :key="intervencao"
              :id="intervencao.id"
            >
              {{ intervencao.type_name }}
            </option>
          </select>
        </div>
      </div>

      <div class="grid-span-2">
        <div class="flex-item">
          <label>Método Contratual</label>
          <select
            class="form-select"
            ref="metodo"
            @change="selectValue('metodo', $event)"
          >
            <option selected disabled hidden>
              {{ metodocontratual.proc_name }}
            </option>
            <option
              v-for="metodo in metodoContratual"
              :key="metodo"
              :id="metodo.id"
            >
              {{ metodo.proc_name }}
            </option>
          </select>
        </div>
      </div>
      <div class="grid-span-2">
        <div class="flex-item">
          <label>Ciclo de Vida Expectável</label>
          <div class="lifecycle">
            <input
              type="number"
              class="form-control lifecycle-input"
              ref="ciclo"
              v-model="projectInfo.detail.value_life_cycle"
            />
            <select
              class="form-select lifecycle-dpdown"
              ref="lifecycle"
              @change="selectValue('lifecycle', $event)"
            >
              <option selected disabled hidden>
                {{ unidadeciclovida.unit_name }}
              </option>
              <option
                v-for="unit in unidadeCicloVida"
                :key="unit"
                :id="unit.id"
              >
                {{ unit.unit_name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </section>
  </main>

  <section v-if="setModal">
    <Modal
      :setModal="setModal"
      :message_title="'CONFIRMAR A ALTERAÇÃO DE IMAGEM?'"
      :file="selectedFile"
      @closeModal="closeModal"
      @resetFiles="resetFiles"
    />
  </section>
</template>

<script>
import {
  getAditionalInfo,
  updateProjectDetails
} from '../../services/lib/project-details/generalDetails'

import Modal from '../../views/Modal.vue'
import GoogleMaps from '../GoogleMaps.vue'
import ShowLoading from '../ShowLoading.vue'
import {
  filterById,
  setNotification,
  calcChars
} from '../../services/filters/dreamCatcher'
import { hasPermission } from '../../services/lib/rolesHelper'
import { mapGetters } from 'vuex'
export default {
  components: {
    GoogleMaps,
    ShowLoading,
    Modal
  },
  props: {
    project: Object
  },
  data() {
    return {
      //setup file upload
      selectedFile: null,
      setModal: null,
      //refs
      refNameMap: {
        funcao: 'proj_functions_id',
        intervencao: 'type_of_works_id',
        metodo: 'proc_id',
        lifecycle: 'unit_life_cycle'
      },
      formEdited: false,
      projectInfo: null,
      propSearch: '',
      showLoading: true,
      // PARA POPULAR AS DROPS
      funcaoEmpreendimento: null,
      tipoIntervencao: null,
      metodoContratual: null,
      unidadeCicloVida: null
    }
  },
  async created() {
    this.projectInfo = JSON.parse(JSON.stringify(this.project))
    //console.log('PROJECTINFO STRUCTURE: ', this.projectInfo)
    this.propSearch = this.projectInfo.detail.location
    // //console.log(this.projectInfo)
    //para popular as dropdowns
    const res = await getAditionalInfo()
    //console.log('ADITIONAL INFO: ', res)
    this.funcaoEmpreendimento = JSON.parse(
      JSON.stringify(res.funcaoEmpreendimento)
    )
    this.tipoIntervencao = JSON.parse(JSON.stringify(res.tipoIntervencao))
    this.metodoContratual = JSON.parse(JSON.stringify(res.metodoContratual))
    this.unidadeCicloVida = JSON.parse(JSON.stringify(res.unidadeCicloVida))

    this.showLoading = !this.showLoading
  },
  methods: {
    async handleFileSelect(event) {
      if (event.target.files[0]) {
        this.selectedFile = await event.target.files[0]
        this.setModal = 'setUploadFile'
      }
    },
    async handleClick() {
      // Destructure the project_image and current_stage properties from the Vuex store
      const { project_image, current_stage } = this.$store.state.project

      // Destructure the ref, id, project_name, descrip, and detail properties from the component's data
      const { ref, id, project_name, descrip, detail } = this.projectInfo

      // Destructure the location, proj_functions_id, type_of_works_id, proc_id, unit_life_cycle, and value_life_cycle properties from the detail object
      const {
        location,
        proj_functions_id,
        type_of_works_id,
        proc_id,
        unit_life_cycle,
        value_life_cycle
      } = detail

      // If the project_image in the component's data is different from the project_image in the Vuex store, update the component's data
      if (this.projectInfo.project_image !== project_image) {
        this.projectInfo.project_image = project_image
      }

      // If the current_stage in the component's data is different from the current_stage in the Vuex store, update the component's data
      if (
        this.projectInfo.current_stage.stage_id !== current_stage.stage_id ||
        this.projectInfo.current_stage.stage_value !== current_stage.stage_value
      ) {
        this.projectInfo.current_stage.stage_id = current_stage.stage_id
        this.projectInfo.current_stage.stage_value = current_stage.stage_value
      }

      // Create an object with the data to be sent to the server
      const data = {
        ref,
        id,
        project_name,
        descrip,
        location,
        proj_functions_id,
        type_of_works_id,
        proc_id,
        unit_life_cycle,
        value_life_cycle
      }

      // Call the updateProjectDetails function with the data and the project ID
      const res = await updateProjectDetails(data, id)

      // If the response is not 200, show an error notification and return
      if (res !== 200) {
        setNotification('Ocorreu um erro', 'error')
        return
      }

      // Dispatch the updated project data to the Vuex store
      this.$store.dispatch(
        'project',
        JSON.parse(JSON.stringify(this.projectInfo))
      )

      // Show a success notification and set the formEdited flag to false
      setNotification('Guardado com sucesso!', 'success')
      this.formEdited = false
    },
    remainingChars(text) {
      let result = calcChars(text)
      return result
    },
    searchLocation() {
      if (this.projectInfo.detail.location) {
        this.propSearch = this.projectInfo.detail.location
      }
    },
    clearLocation() {
      if (this.projectInfo.detail.location === 'Localização por definir') {
        this.projectInfo.detail.location = ''
      }
    },
    resetLocation() {
      if (!this.projectInfo.detail.location) {
        this.projectInfo.detail.location = 'Localização por definir'
      }
    },
    selectValue(refName, e) {
      const propName = this.refNameMap[refName]
      if (propName) {
        this.projectInfo.detail[propName] = +e.target.selectedOptions[0].id
      }
    },
    closeModal() {
      this.setModal = null
    },
    resetFiles() {
      this.setModal = null
      this.$refs.uploader.value = null
      // //console.log('ok')
    }
  },
  computed: {
    funcaodoempreendimento() {
      return filterById(
        this.funcaoEmpreendimento,
        this.project.detail.proj_functions_id
      )
    },
    tipodeintervencao() {
      return filterById(
        this.tipoIntervencao,
        this.project.detail.type_of_works_id
      )
    },
    metodocontratual() {
      return filterById(this.metodoContratual, this.project.detail.proc_id)
    },
    unidadeciclovida() {
      return filterById(
        this.unidadeCicloVida,
        this.project.detail.unit_life_cycle
      )
    },
    ...mapGetters(['user']),
    canViewLifecycleDetails() {
      return hasPermission(this.user.role, 'view project lifecycle details')
    },
    canEditDetails() {
      return hasPermission(this.user.role, 'edit project details')
    }
  },
  watch: {
    projectInfo: {
      handler(newData) {
        if (JSON.stringify(newData) !== JSON.stringify(this.project)) {
          this.formEdited = true
        } else {
          this.formEdited = false
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.prim-title {
  margin-top: 1rem;
  padding-top: 0.25rem;
  border-left: 0.25rem solid var(--main-color);
}

.general-details-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.general-details-container-cant-edit {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

/* this is the grid container */
.general-details-grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  position: absolute;
  width: 90%;
  height: 88.75%;
  bottom: 0;
  left: 0;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
}

.general-details-grid-cant-view {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  position: absolute;
  width: 90%;
  height: 88.75%;
  bottom: 0;
  left: 0;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  padding-inline: 16rem;
}

.general-details-grid-cant-edit {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  position: absolute;
  width: 90%;
  height: 88.75%;
  bottom: 0;
  left: 0;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  padding-inline: 16rem;
}

.gen-details-title {
  position: absolute;
  left: 1rem;
  top: 0;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grid-span-4 {
  grid-column: span 4;
}

.grid-span-3 {
  grid-column: span 3;
}

.grid-span-2 {
  grid-column: span 2;
}

.grid-span-1 {
  grid-column: span 1;
}

.flex-item {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.map {
  width: 100%;
}

.sec-title {
  text-align: left;
  border-left: 0.25rem solid var(--main-color);
  margin: 0;
}

select {
  height: 2rem;
  cursor: pointer;
}

input[type='text'],
select {
  min-height: 30px !important;
}

input[type='text'],
select,
option {
  font-size: 0.9rem;
}

.lifecycle {
  display: flex;
  position: relative;
}

.lifecycle-input {
  position: relative;
  text-align: left;
  width: 100%;
  min-height: 30px;
  height: 2rem;
  z-index: 0;
  padding-right: 50%;
}

.lifecycle-input:focus + .lifecycle-dpdown {
  border: 1px solid rgba(35, 141, 228, 0.6) !important;
  border-left: none !important;
}

.lifecycle-dpdown {
  position: absolute;
  right: 0;
  width: 50% !important;
  border-left: none;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  z-index: 1;
}

.map {
  padding-top: 1rem;
}

.flex-row {
  position: relative;
  display: flex;
}

.search-container {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  width: 15%;
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #e9ecef;
  border: 1px solid #ccc;
  transition: 0.2s;
}

.search-container p {
  margin: 0;
  padding: 0;
}

.form-control:focus + .search-container {
  border: 1px solid rgba(0, 110, 255, 0.459);
}

textarea {
  resize: none;
}

/* EFFECTS */

.my-btn-primary {
  justify-self: flex-end;
  align-self: flex-end;
  margin: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.1);
  transition: 0.5s ease;
}

.my-btn-primary h5 {
  margin: 0;
  color: var(--text-color);
}

.show-btn {
  background-color: var(--main-color);
  pointer-events: auto;
  cursor: pointer;
}

.hide-btn {
  background: rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

.search-container:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
@media screen and (max-width: 1100px) {
  .grid-span-2 {
    grid-column: span 4;
  }
}
</style>
