<template>
  <main class="profile-content">
    <div class="row" style="margin-bottom: 1.5rem">
      <div class="title-wrapper">
        <h1 class="content-title">NOTIFICAÇÕES</h1>
      </div>
    </div>

    <div class="notifications-wrapper">
      <div
        v-for="(notification, index) in notifications"
        :key="index"
        class="notifications-item"
      >
        <NotificationsList :notification="notification" />
      </div>
    </div>

    <div
      v-if="moreNotifications"
      @click="loadMoreNotifications()"
      class="add-more"
    >
      <p>Mostrar mais</p>
    </div>
  </main>
</template>

<script>
import NotificationsList from './NotificationList.vue'
import { getNotifications } from '../../services/lib/notifications'

export default {
  components: {
    NotificationsList
  },
  data() {
    return {
      notifications: {},
      moreNotifications: false
    }
  },
  methods: {
    async getNotifications() {
      this.notifications = await getNotifications(0)
      this.moreNotifications = this.notifications.data.existMore
      this.notifications = this.notifications.data.notifications
      this.notifications.forEach((not, index) => {
        not.data = JSON.parse(not.data)
      })
    },

    async loadMoreNotifications() {
      this.notifications = await getNotifications(this.notifications.length)
      this.moreNotifications = this.notifications.data.existMore
      this.notifications = this.notifications.data.notifications
      this.notifications.forEach((not, index) => {
        not.data = JSON.parse(not.data)
      })
    }
  },
  created() {
    this.getNotifications()
  }
}
</script>

<style scoped>
.max-size-image {
  border-radius: 50%;
  max-height: 35px;
}

.profile-content {
  position: absolute;
  inset: 0;
  left: 25vw;
  top: 8vh;
  width: 50vw;
  height: max-content;
  padding-block: 2rem;
  padding-inline: 4rem;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(4px);
  border-radius: 0.5rem;

  display: flex;
  flex-flow: column;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.notifications-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  width: 100%;
}
.notifications-item {
  width: 100%;
}

.add-more {
  border: 1px solid #ddd;
  background: white;
  width: max-content;
  padding-block: 0.5vmin;
  padding-inline: 1vmin;
  margin-top: 1vmin;
  align-self: flex-end;
  border-radius: 4px;
}

.add-more > p {
  margin: 0;
  padding: 0;
  color: #aaa;
}

.add-more:hover {
  cursor: pointer;
  border-color: #888;
}
.add-more:hover > p {
  color: #555;
}

@media screen and (max-width: 1365px) {
  .profile-content {
    width: 80vw;
    left: 10vw;
  }
}
@media screen and (max-width: 719px) {
  .profile-content {
    width: 90vw;
    left: 5vw;
    top: 7vh;
  }
}
@media screen and (max-width: 499px) {
  .profile-content {
    width: 95vw;
    left: 2.5vw;
    top: 7vh;
  }
}
</style>
