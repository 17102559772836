<template>
  <main class="searchbar-wrapper">
    <label for="searchbar" class="results" v-if="results">
      A mostrar: {{ results }}
    </label>
    <input
      type="text"
      placeholder="Procurar"
      class="searchbar"
      v-model="search"
      @input="valueChangeEvent"
    />
  </main>
</template>

<script>
export default {
  props: ['results'],
  data() {
    return {
      search: ''
    }
  },
  methods: {
    valueChangeEvent() {
      this.$emit('inputValueChange', this.search)
    }
  }
  // watch: {
  //   '$store.state.roles': function () {
  //     this.search = ''
  //   }
  // }
}
</script>

<style scoped>
.searchbar-wrapper {
  display: flex;
  align-items: center;
}
.results {
  width: 100%;
  align-self: flex-end;
}
</style>
