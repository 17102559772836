<template>
  <!-- @click="
      fetchProject(
        notification.project_id,
        notification.id,
        notification.data.path
      )
    " -->
  <main class="notification-wrapper">
    <section class="notification-header">
      <!-- <div class="custom-icon-wrapper">
        <i :class="['custom-icon', notification.data.icon]"></i>
      </div> -->

      <div class="content-title-wrapper">
        <p>
          {{ notification.data.title }}
        </p>
      </div>

      <div class="date-wrapper">
        <p>
          {{ dateFormatted(notification.created_at) }}
        </p>
      </div>
    </section>

    <section class="notification-body">
      <div class="msg-wrapper">
        <p>{{ notification.data.message }}</p>
      </div>

      <div class="read-wrapper">
        <p :class="{ 'not-read': !notification.read_at }" @click="handleClick">
          {{ read_state }}
        </p>
      </div>
    </section>
  </main>
</template>

<script>
import moment from 'moment'
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css'
import projectLib from '../../services/lib/projects'
import { getStages } from '../../services/lib/stages'
import agentes from '../../services/lib/agentes'
import { markOneAsRead } from '../../services/lib/notifications'
import { setNotification } from '../../services/filters/dreamCatcher'

export default {
  name: 'NotificationList',
  props: {
    notification: Object
  },
  emits: ['toggleLoading'],
  computed: {
    read_state() {
      return this.notification.read_at ? 'Visto' : 'Ir para'
    }
  },
  methods: {
    dateFormatted(date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },
    async handleClick() {
      if (this.notification.read_at) return

      await this.fetchProject(
        this.notification.project_id,
        this.notification.id,
        this.notification.data.path
      )
    },
    async fetchProject(pid, nid, path) {
      try {
        this.toggleLoading()

        const isUserInProject = await projectLib.isUserInProject(
          pid,
          this.$store.state.user.id
        )

        if (isUserInProject.data == 1) {
          const [projeto] = await projectLib.getProject(pid)
          const stages = await getStages()
          const role = await agentes.getUserRole({
            project_id: projeto.id,
            user_id: this.$store.state.user.id
          })

          this.$store.dispatch('stages', stages)
          this.$store.dispatch('project', projeto)
          this.$store.dispatch('addRoleToCurrentUser', role.data.name)

          await markOneAsRead(nid)

          await this.$nextTick()

          switch (path) {
            case '/sistemas':
              this.$router.push({ name: 'Sistemas' })
              break
            case '/tarefas':
              this.$router.push({ name: 'Tarefas' })

              break
            case '/entregaveis':
              this.$router.push({ name: 'Entregaveis' })

              break
            case '/agentes':
              this.$router.push({ name: 'Agentes' })

              break
            case '/detalhes':
              this.$router.push({ name: 'Detalhes' })

              break
            default:
              this.$router.push({ name: 'Projetos' })
              break
          }
        } else {
          setNotification(
            'Não tem permissões para aceder a este projeto',
            'error'
          )
          await markOneAsRead(nid)
          window.location.reload()
        }
      } catch (error) {}
    },
    toggleLoading() {
      this.$emit('toggleLoading')
    }
  }
}
</script>

<style scoped>
p {
  padding: 0;
  margin: 0;
}
.notification-wrapper {
  background: rgb(250, 250, 250);
  width: 100%;
  display: flex;
  flex-flow: column;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border-radius: 4px;
}
.notification-header {
  display: flex;
  justify-content: space-between;
  padding-block: 0.32rem;
  padding-inline: 1rem;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 12px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.notification-header p {
  font-size: 0.8rem;
  font-size: clamp(12px, 0.8rem, 16px);
  color: #999;
}

.notification-body {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  display: flex;
  flex-flow: column;
  padding-inline: 2rem;
  padding-block: 0.5rem;
}
.msg-wrapper {
  display: flex;
  justify-content: flex-start;
}
.read-wrapper {
  display: flex;
  justify-content: flex-end;
}
.read-wrapper > p {
  color: #aaa;
  background: #eee;
  padding-block: 0.1rem;
  padding-inline: 0.32rem;
  border-radius: 4px;
  letter-spacing: 1px;
  font-weight: 900;
}

.not-read {
  color: green !important;
  background: rgba(0, 255, 0, 0.1) !important;
}
.not-read:hover {
  color: green;
  background: rgba(0, 255, 0, 0.2) !important;
  cursor: pointer;
}
</style>
