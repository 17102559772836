<template>
  <div class="content-wrapper">
    <iframe
      src="https://bimmsight.digi4construction.com/example/"
      allowfullscreen
      title="Iframe Example"
      class="ifcviewer"
    ></iframe>
  </div>
</template>

<script>
//  src="https://threejs.org/examples/webgl_loader_ifc.html"
//src="http://www.digi4construction.pt/1/example/index.html"
//src="https://bimmsight.digi4construction.com/src"
</script>

<style scoped>
.ifcviewer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
</style>
